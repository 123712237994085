import React from 'react';
import { TextField, FormControl, FormHelperText } from '@material-ui/core';
import { fetchVnets } from '../utils/apiCalls';
import Autocomplete from '@mui/material/Autocomplete';
import {ListOfVnet} from '../utils/types';


interface VnetFieldProps {
    id: string;
    required: boolean;
    rawErrors: any;
    vnetLoader: boolean;
    userToken: string | undefined;
    selectedResourceGroup: any;
    onChange: (value: any) => void;
    setVnet: (value: any) => void;
    setVnetLoader: (loading: boolean) => void;
    setVnetList: (list: ListOfVnet[]) => void;
    vnetList: ListOfVnet[];
    subscriptionDetails: any;
}

export const VnetField: React.FC<VnetFieldProps> = ({
    id,
    required,
    rawErrors,
    vnetLoader,
    userToken,
    selectedResourceGroup,
    onChange,
    setVnet,
    setVnetLoader,
    setVnetList,
    vnetList,
    subscriptionDetails,
}) => {
    React.useEffect(() => {
        const fetchData = async () => {
            setVnetLoader(true);
            const vnetResponse = await fetchVnets(selectedResourceGroup.id, userToken || '');
            setVnetList(vnetResponse);
            setVnetLoader(false);
        };

        if (selectedResourceGroup) {
            fetchData();
        }
    }, [selectedResourceGroup, userToken, setVnetLoader, setVnetList]);

    const vnetProps = {
        options: vnetList.sort((a: ListOfVnet, b: ListOfVnet) => a.vnet_name.localeCompare(b.vnet_name)),
        getOptionLabel: (option: ListOfVnet) => option.vnet_name,
        getOptionSelected: (option: ListOfVnet, value: ListOfVnet) => option.vnet_name === value.vnet_name,
    };

    return (
        <FormControl margin="normal" required={required} error={rawErrors?.length > 0}>
            <Autocomplete
                id={id}
                onChange={(_: any, value: any) => {
                    onChange({ subscriptionDetails, selectedResourceGroup, selectedVnet: value });
                    setVnet(value);
                }}
                loading={vnetLoader}
                {...vnetProps}
                clearOnEscape
                renderInput={(params: any) => (
                    <TextField {...params} label="List of Vnets" variant="standard" required={required} />
                )}
            />
            <FormHelperText>Choose the required Vnet</FormHelperText>
        </FormControl>
    );
};