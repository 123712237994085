import { EntitySwitch } from '@backstage/plugin-catalog';
import { Grid } from '@material-ui/core';
import React from 'react';
import { EntityWarningTabContent } from './EntityWarningTabContent';
import { EntitySonarQubeCard } from '@backstage/plugin-sonarqube';
import { isSonarQubeAvailable } from '@backstage/plugin-sonarqube-react';
import {
  EntityGithubPullRequestsOverviewCard,
  EntityGithubPullRequestsContent,
  isGithubPullRequestsAvailable,
} from '@roadiehq/backstage-plugin-github-pull-requests';
import { isSnykAvailable, SnykOverview } from 'backstage-plugin-snyk';

export const CodeHealthTabContent = (
  <>
    <Grid container spacing={3} alignItems="stretch">
      {EntityWarningTabContent}
      <EntitySwitch>
        <EntitySwitch.Case if={isSonarQubeAvailable}>
          <Grid item md={6} xs={12}>
            <EntitySonarQubeCard variant="gridItem" />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
      <Grid item md={6} xs={12}>
        <EntitySwitch>
          <EntitySwitch.Case if={isGithubPullRequestsAvailable}>
            <EntityGithubPullRequestsOverviewCard />
          </EntitySwitch.Case>
        </EntitySwitch>
      </Grid>
      <EntitySwitch>
        <EntitySwitch.Case if={isSnykAvailable}>
          <Grid item md={6}>
            <SnykOverview />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
    </Grid>

    <EntitySwitch>
      <EntitySwitch.Case if={isGithubPullRequestsAvailable}>
        <Grid>
          <EntityGithubPullRequestsContent />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);
