import { scaffolderPlugin } from '@internal/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@internal/plugin-scaffolder-react';
import { GitHubRepositoryField } from './GitHubRepositoryExtension';

export const GitHubRepositoryExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'github-repo-dropdown',
    component: GitHubRepositoryField,
  }),
);
