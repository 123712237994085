import React from 'react';
import {
    Card,
    CardContent,
    Grid,
    TextField,
    FormControlLabel,
    Checkbox,
    IconButton,
    Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

interface ArrayCardProps {
    title: string;
    items: any[];
    onAdd: () => void;
    onRemove: (index: number) => void;
    onChange: (index: number, updatedItem: any) => void;
}

const ArrayCard: React.FC<ArrayCardProps> = ({
    title,
    items,
    onAdd,
    onRemove,
    onChange,
}) => {
    const handleTextFieldChange = (
        index: number,
        key: string,
        newValue: string
    ) => {
        if (onChange) {
            const updatedItem = {
                ...(items[index] || {}), 
                [key]: newValue,
            };
            const updatedItems = [...items]; 
            updatedItems[index] = updatedItem; 
            onChange(index, updatedItem);
        }
    };

    return (
        <Card style={{ marginBottom: '16px' }}>
            <CardContent>
                <h3>{title}</h3>
                {items.map((item: any, index: number) => (
                    <Card key={index} style={{ marginBottom: '8px' }}>
                        <CardContent>
                            <h4>{`${title} ${index + 1}`}</h4>
                            {Object.entries(item || {}).map(([key, value]: [string, any]) => (
                                <Grid container spacing={2} key={key}>
                                    {typeof value === 'boolean' ? (
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={value}
                                                        onChange={(e) =>
                                                            onChange(index, {
                                                                ...(item || {}),
                                                                [key]: e.target.checked,
                                                            })
                                                        }
                                                    />
                                                }
                                                label={key}
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid item>
                                            <TextField
                                                label={key}
                                                value={value}
                                                variant="outlined"
                                                fullWidth
                                                onChange={(e) => {
                                                    handleTextFieldChange(index, key, e.target.value);
                                                }}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            ))}
                        </CardContent>
                        <Grid container justifyContent="flex-end">
                            <IconButton onClick={() => onRemove(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Card>
                ))}
            </CardContent>
            <Grid container justifyContent="flex-end">
                <Button variant="contained" color="primary" onClick={onAdd} style={{margin: "10px"}}>
                    Add {title} <AddIcon />
                </Button>
            </Grid>
        </Card>
    );
};

export default ArrayCard;