import { TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { fetchAzureADUsers, fetchAzureGroupEmail, microsoftAzureToken, validateEmail } from "../api";
import { RESOURCE_OWNER, HELPER_TEXT_RESOURCE_OWNER, SUPPORTED_BY, HELPER_TEXT_SUPPORTED_BY } from '../../../../constants/ecosystemBuilderCreationConstants'



interface ResourceOwnerAndSupportedByProps {
  handleResourceOwnerChange: (_: any) => void;
  handleSupportedByChange: (_: any) => void;
  handleGlobalResourceOwnerError: (_: any) => void;
  handleSupportedByError: (_: any) => void;
  ResourceOwnerError: string;
  SupportedByError: string;
  resourceOwner: string;
  supportedBy: string;
}

const ResourceOwnerAndSupportedBy: React.FC<ResourceOwnerAndSupportedByProps> = ({
  handleResourceOwnerChange,
  handleSupportedByChange,
  handleGlobalResourceOwnerError,
  // ResourceOwnerError,
  handleSupportedByError,
  // SupportedByError,
  resourceOwner,
  supportedBy,

}) => {
  const accessToken = microsoftAzureToken();
  const [_isValid, setIsValid] = useState<boolean>(false);
  const [_isSupportedByValid, setIsSupportedByValid] = useState<boolean>(false);
  const [errorSupportedByText, setErrorSupportedByText] = useState<string>(''); // New state for error text
  const [errorText, setErrorText] = useState<string>(''); // New state for error text


  useEffect(() => {
    setErrorText('');
    setIsValid(false);
    const validateTeamUsers = async () => {
      let checkDomain = resourceOwner;
      if (checkDomain.trim() === '') {
        setIsValid(false);
        setErrorText('Email cannot be empty.');
        return;
      }
      const regex = /^[Yya-zA-Z0-9._%+-]+@(mnscorp\.net)$/;
      const isValidEmail = validateEmail(checkDomain, regex);

      if (!isValidEmail) {
        setIsValid(false);
        handleGlobalResourceOwnerError('doNotExists');
        // ResourceOwnerError='doNotExists';
        setErrorText('Invalid Email. Please enter a valid one. and match Regex');
        return;
      }
      else {
        setIsValid(true);
        handleGlobalResourceOwnerError('exists');
        // ResourceOwnerError='exists';
        setErrorText('');
      }

      const isValidResourceOwner = await checkResourceOwnerName(checkDomain, accessToken);
      if (isValidResourceOwner) {
        setIsValid(true);
        handleGlobalResourceOwnerError('exists');
        // ResourceOwnerError='exists';
        setErrorText('');
      } else {
        setIsValid(false);
        handleGlobalResourceOwnerError('doNotExists');
        // ResourceOwnerError='doNotExists';
        setErrorText('Invalid Resource Owner Email. Please enter a valid one.');
      }
    };

    validateTeamUsers();
  }, [resourceOwner, accessToken]);

  useEffect(() => {
    setIsSupportedByValid(false);
    setErrorSupportedByText('');
    const validateSupportedBy = async () => {
      let checkDomain = supportedBy;
      if (checkDomain.trim() === '') {
        setIsSupportedByValid(false);
        setErrorSupportedByText('Group Email cannot be empty.');
        return;
      }
      const regex = /^[a-zA-Z0-9._%+-]+@(mnscorp\.net|marks-and-spencer\.com|mnscorp.onmicrosoft\.com|mands\.com)$/;
      const isValidEmail = validateEmail(checkDomain, regex);

      if (!isValidEmail) {
        setIsSupportedByValid(false);
        handleSupportedByError('doNotExists');
        // SupportedByError='doNotExists';
        setErrorSupportedByText('Invalid Email. Please enter a valid one. and match Regex');
        return;
      }
      else {
        setIsSupportedByValid(true);
        handleSupportedByError('exists');
        // SupportedByError='exists';
        setErrorSupportedByText('');
      }

      const isValidSupportedBy = supportedBy ? await checkSupportedBy(checkDomain, accessToken) : false;
      if (isValidSupportedBy) {
        setIsSupportedByValid(true);
        handleSupportedByError('exists');
        // SupportedByError='exists';
        setErrorSupportedByText('');
      } else {
        setIsSupportedByValid(false);
        handleSupportedByError('doNotExists');
        // SupportedByError='doNotExists';
        setErrorSupportedByText('Invalid Group Email. Please enter a valid one.');
      }
    };

    validateSupportedBy();

  }, [supportedBy, accessToken]);

  const checkResourceOwnerName = async (teamName: string, token: any) => {
    try {
      const checkAdJson = await fetchAzureADUsers(teamName, token);
      const data = await checkAdJson;
      return data; // Return true if the Name exi and Email exists
    } catch (error) {
      console.error('Error checking Team Member email:', error);
      return false; // Return false if an error occurs (consider handling errors appropriately)
    }
  };


  const checkSupportedBy = async (supportByValue: string, token: any) => {
    try {
      const checkAdJson = await fetchAzureGroupEmail(supportByValue, token);
      const data = await checkAdJson;
      return data;
    } catch (error) {
      console.error('Error checking Team Member email:', error);
      return false;
    }
  };

  return (
    <div style={{ marginBottom: '16px' }}>
      <div style={{ marginBottom: '16px' }}>
        <TextField
          id="resourceOwner"
          label={RESOURCE_OWNER}
          variant="outlined"
          value={resourceOwner}
          onChange={handleResourceOwnerChange}
          fullWidth
          margin='dense'
          helperText={HELPER_TEXT_RESOURCE_OWNER}
        />
        {resourceOwner && errorText && <Typography variant="caption" color="error">{errorText}</Typography>}
      </div>
      <div style={{ marginBottom: '16px' }}>
        <TextField
          id="supportedBy"
          label={SUPPORTED_BY}
          variant="outlined"
          value={supportedBy}
          onChange={handleSupportedByChange}
          fullWidth
          margin='dense'
          helperText={HELPER_TEXT_SUPPORTED_BY}
        />
        {supportedBy && errorSupportedByText && <Typography variant="caption" color="error">{errorSupportedByText}</Typography>}
      </div>
    </div>
  );

}

export default ResourceOwnerAndSupportedBy;