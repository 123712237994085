import React from 'react';
import { TextField, FormControl, FormHelperText } from '@material-ui/core';
import { fetchResourceGroupList } from '../utils/apiCalls';
import Autocomplete from '@mui/material/Autocomplete';
import { ResourceGroupOptions } from '../utils/types';

interface ResourceGroupFieldProps {
    id: string;
    required: boolean;
    rawErrors: any;
    resourceGroupLoader: boolean;
    userToken: string | undefined;
    subscriptionDetails: any;
    onChange: (value: any) => void;
    setResourceGroup: (value: any) => void;
    setResourceGroupLoader: (loading: boolean) => void;
    setRgList: (list: ResourceGroupOptions[]) => void;
    rgList: ResourceGroupOptions[];
}

export const ResourceGroupField: React.FC<ResourceGroupFieldProps> = ({
    id,
    required,
    rawErrors,
    resourceGroupLoader,
    userToken,
    subscriptionDetails,
    onChange,
    setResourceGroup,
    setResourceGroupLoader,
    setRgList,
    rgList,
}) => {
    React.useEffect(() => {
        const fetchData = async () => {
            setResourceGroupLoader(true);
            const rgResponse = await fetchResourceGroupList(subscriptionDetails.id, userToken || '');
            setRgList(rgResponse);
            setResourceGroupLoader(false);
        };

        if (subscriptionDetails) {
            fetchData();
        }
    }, [subscriptionDetails, userToken, setResourceGroupLoader, setRgList]);

    const resourceGroupProps = {
        options: rgList.sort((a: ResourceGroupOptions, b: ResourceGroupOptions) => a.resourceGroupName.localeCompare(b.resourceGroupName)),
        getOptionLabel: (option: ResourceGroupOptions) => option.resourceGroupName,
        getOptionSelected: (option: ResourceGroupOptions, value: ResourceGroupOptions) => option.resourceGroupName === value.resourceGroupName,
    };

    return (
        <FormControl margin="normal" required={required} error={rawErrors?.length > 0}>
            <Autocomplete
                id={id}
                onChange={(_: any, value: any) => {
                    onChange({ subscriptionDetails, resourceGroupDetails: value });
                    setResourceGroup(value);
                }}
                loading={resourceGroupLoader}
                {...resourceGroupProps}
                clearOnEscape
                renderInput={(params: any) => (
                    <TextField {...params} label="Resource Group" variant="standard" required={required} />
                )}
            />
            <FormHelperText>Choose the required Resource Group</FormHelperText>
        </FormControl>
    );
};