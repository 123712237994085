export const colourDictionary = {
  blue: {
    200: '#eaf0f3',
    300: '#e2e3e3',
    400: '#4e5f6b',
    500: '#4273b7',
    600: '#627786',
    700: '#1e5a7a',
    900: '#31338a',
    950: '#02C3BD',
    1000: '#00283D',
  },
  red: {
    50: '#faf1f2',
    100: '#fcf0ec',
    200: '#fff3f4',
    250: '#ff9999',
    300: '#ea122a',
    400: '#a6192e',
  },
  orange: {
    100: '#f9f0ea',
    150: '#f0eeed',
    200: '#efeeec',
    500: '#e86c25',
    950: '#F49F0A',
  },
  yellow: {
    100: '#f9c606',
    200: '#efdf00',
    300: '#b5cc51',
    400: '#a98b52',
  },
  green: {
    100: '#f4faed',
    150: '#dbdfdb',
    200: '#e0e4e7',
    300: '#dfece2',
    400: '#bdd755',
    500: '#627786',
    600: '#43d68a',
    700: '#2db236',
    800: '#005640',
    900: '#003f35',
    950: '#5dff9c',
  },
  purple: {
    100: '#f2eef6',
  },
  /*  grey: {
      50: "#fbfbfb",
      100: "#f1f1f1",
      200: "#f5f5f5",
      250: "#ede9e7",
      300: "#e6e6e6",
      400: "#cccccc",
      600: "#999999",
      700: "#757575",
      900: "#333333",
    },*/
  grey: {
    50: '#f7f8fa',
    100: '#f1f1f1',
    150: '#DFF0F5',
    200: '#f5f5f5',
    250: '#ede9e7',
    300: '#e6e6e6',
    400: '#cfd8dc',
    600: '#999999',
    700: '#4f5b62',
    900: '#00283D',
    950: '#04364A',
    1000: '#0d191f',
    1050: '#000a12',
  },
  black: '#000000',
  white: '#ffffff',
  mnsTech: {
    purple: '#31338a',
    prussianBlue: '#00283D',
    lightBlue: '#DFF0F5',
    acidGreen: '#5dff9c',
    orange: '#F49F0A',
  },
};
