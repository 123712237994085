import React from 'react';
import { makeFieldSchemaFromZod } from '@internal/plugin-scaffolder';
import { z } from 'zod';
import { AzureSubscriptionField } from './src/components/AzureSubscriptionField';
import { ResourceGroupField } from './src/components/ResourceGroupField';
import { VnetField } from './src/components/VnetField';
import { ListOfSubnet, ListOfVnet, ResourceGroupOptions } from './src/utils/types';
import { SubnetField } from './src/components/SubnetField';
 
type AzureResourcePicker = { subscription: string; resourceGroup: string; vnet: string; subnet: string; };
type AzureResourcePickerKeys = keyof AzureResourcePicker;
 
export const AzureCustomExtension = ({ onChange, schema, rawErrors, required, uiSchema, idSchema }: any) => {
  const [rgList, setRgList] = React.useState<ResourceGroupOptions[]>([]);
  const [vnetList, setVnetList] = React.useState<ListOfVnet[]>([]);
  const [subnetList, setSubnetList] = React.useState<ListOfSubnet[]>([]);
  const [userToken, setUserToken] = React.useState<string | undefined>();
  const [resourceGroupLoader, setResourceGroupLoader] = React.useState(true);
  const [vnetLoader, setVnetLoader] = React.useState(true);
  const [subnetLoader, setSubnetLoader] = React.useState(true);
  const [resourceGroup, setResourceGroup] = React.useState<any>();
  const [vnetValue, setVnet] = React.useState<any>();
  const [_subnetValue, setSubnet] = React.useState<any>();
  const [subscriptionDetails, setSubscriptionDetails] = React.useState<string | undefined>();
 
  let selectorFields: AzureResourcePickerKeys[] = uiSchema['ui:options']?.selector
    ? (uiSchema['ui:options'].selector as AzureResourcePickerKeys[])
    : ['vnet', 'subnet'];
 
  if (!selectorFields.includes('vnet') && !selectorFields.includes('subnet')) {
    selectorFields = ['subscription', 'resourceGroup', 'vnet'];
  } else if (selectorFields.includes('vnet') && !selectorFields.includes('subnet')) {
    selectorFields = ['subscription', 'resourceGroup', 'vnet'];
  } else if (!selectorFields.includes('vnet') && selectorFields.includes('subnet')) {
    selectorFields = ['subscription', 'resourceGroup', 'vnet', 'subnet'];
  }
 
  return (
    <>
      {(!selectorFields || (selectorFields && selectorFields.includes('subscription'))) && (
        <AzureSubscriptionField
          id={idSchema?.$id}
          required={required}
          rawErrors={rawErrors}
          schema={schema}
          onChange={onChange}
          setSubscriptionDetails={setSubscriptionDetails}
          setUserToken={setUserToken}
          userToken={userToken}
        />
      )}
      {selectorFields.includes('resourceGroup') && (
        <ResourceGroupField
          id={idSchema?.$id}
          required={required}
          rawErrors={rawErrors}
          resourceGroupLoader={resourceGroupLoader}
          userToken={userToken}
          subscriptionDetails={subscriptionDetails}
          onChange={onChange}
          setResourceGroup={setResourceGroup}
          setResourceGroupLoader={setResourceGroupLoader}
          setRgList={setRgList}
          rgList={rgList}
        />
      )}
      {selectorFields.includes('vnet') && (
        <VnetField
          id={idSchema?.$id}
          required={required}
          rawErrors={rawErrors}
          vnetLoader={vnetLoader}
          userToken={userToken}
          selectedResourceGroup={resourceGroup}
          onChange={onChange}
          setVnet={setVnet}
          setVnetLoader={setVnetLoader}
          setVnetList={setVnetList}
          vnetList={vnetList}
          subscriptionDetails={subscriptionDetails}
        />
      )}
      {selectorFields.includes('subnet') && (
        <SubnetField
          id={idSchema?.$id}
          required={required}
          rawErrors={rawErrors}
          subnetLoader={subnetLoader}
          userToken={userToken}
          selectedVnet={vnetValue}
          onChange={onChange}
          setSubnet={setSubnet}
          setSubnetLoader={setSubnetLoader}
          setSubnetList={setSubnetList}
          subnetList={subnetList}
          subscriptionDetails={subscriptionDetails}
          selectedResourceGroup={resourceGroup}
        />
      )}
    </>
  );
};

export const AzureCustomExtensionWithOptionsSchema = makeFieldSchemaFromZod(
    z.object({}),
    z.object({
      selector: z
        .array(z.enum(['subnet', 'vnet']))
        .optional()
        .describe('Allow user Select the Field in the form. Defaults to []'),
    }),
  ).schema;