import { scaffolderPlugin } from '@internal/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@internal/plugin-scaffolder-react';
import {
  UpsertTopicConfig,
} from './UpsertTopicConfigExtension';
 
export const UpsertTopicConfigFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'cc-upsert-topic-config',
    component: UpsertTopicConfig
  }),
);
