import { createBaseThemeOptions, createUnifiedTheme } from '@backstage/theme';

import { colourDictionary } from './colors';
import { mnsPageTheme } from './mnsPageTheme';

export const customDarkTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      type: 'dark',
      background: {
        default: colourDictionary.grey[900],
        paper: colourDictionary.grey[950],
      },
      status: {
        ok: colourDictionary.green[400],
        warning: colourDictionary.orange[500],
        error: colourDictionary.red[300],
        running: colourDictionary.blue[700],
        pending: colourDictionary.yellow[200],
        aborted: colourDictionary.grey[700],
      },
      bursts: {
        fontColor: colourDictionary.grey[50],
        slackChannelText: colourDictionary.grey[300],
        backgroundColor: {
          default: colourDictionary.red[400],
        },
        gradient: {
          linear:
            'linear-gradient(-137deg, colourDictionary.green[600] 0%, colourDictionary.green[800] 100%)',
        },
      },
      primary: {
        main: colourDictionary.orange[950],
        light: '#4C9AFF',
        dark: '#172B4D',
      },
      secondary: {
        main: colourDictionary.green[400],
      },
      banner: {
        info: colourDictionary.blue[700],
        error: colourDictionary.red[300],
        text: colourDictionary.white,
        link: colourDictionary.orange[950],
      },
      border: colourDictionary.white,
      textContrast: colourDictionary.black,
      textVerySubtle: colourDictionary.grey[50],
      textSubtle: colourDictionary.grey[600],
      highlight: colourDictionary.orange[100],
      errorBackground: colourDictionary.red[50],
      warningBackground: colourDictionary.yellow[100],
      infoBackground: colourDictionary.blue[200],
      errorText: colourDictionary.grey[400],
      infoText: colourDictionary.blue[700],
      warningText: colourDictionary.black,
      linkHover: colourDictionary.orange[950],
      link: colourDictionary.orange[950],
      gold: colourDictionary.orange[500],
      navigation: {
        // Sidebar colors
        background: colourDictionary.grey[1050],
        indicator: colourDictionary.white,
        color: colourDictionary.white,
        selectedColor: colourDictionary.white,
        navItem: {
          hoverBackground: colourDictionary.grey[700],
        },
      },
      pinSidebarButton: {
        icon: colourDictionary.grey[900],
        background: colourDictionary.grey[400],
      },
      tabbar: {
        indicator: colourDictionary.grey[900],
      },
      search: {
        background: '#04364A',
        border: colourDictionary.grey[700],
        color: colourDictionary.grey[700],
        '&:hover': {
          background: colourDictionary.grey[700],
          outlineColor: colourDictionary.grey[700],
        },
        '&:focus': {
          background: colourDictionary.grey[700],
        },
      },
    },
  }),
  fontFamily: 'Roboto, sans-serif',
  pageTheme: mnsPageTheme,
  defaultPageTheme: 'home',
  components: {
    BackstageSidebarItem: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
        selected: {
          '&$root': {
            borderLeft: 'solid 3px #F49F0A',
            color: colourDictionary.orange[950],
          },
        },
        highlightable: {
          '&:hover': {
            color: colourDictionary.orange[950],
          },
        },
        highlighted: {
          color: colourDictionary.orange[950],
        },
      },
    },
    BackstageContent: {
      styleOverrides: {
        root: {
          background: '#37474f !important',
          color: '#fff',
        },
      },
    },
    BackstageInfoCard: {
      styleOverrides: {
        header: {
          color: colourDictionary.white,
        },
      },
    },
    BackstageContentHeader: {
      styleOverrides: {
        container: {},
        leftItemsBox: {},
        rightItemsBox: {},
        title: {
          background: '#37474f !important',
          color: '#fff',
        },
      },
    },
    BackstageOpenedDropdown: {
      styleOverrides: {
        icon: {
          '& path': {
            fill: '#FFFFFF',
          },
        },
      },
    },
    BackstageTable: {
      styleOverrides: {
        root: {
          '&> :first-child': {
            borderBottom: '1px solid #D5D5D5',
            boxShadow: 'none',
          },
          '& th': {
            borderTop: 'none',
            textTransform: 'none !important',
            color: '#fff',
            background: '#000a12',
          },
          '& th:nth-child(1)': {
            borderTop: 'none',
            textTransform: 'none !important',
            color: '#fff',
            background: '#000a12',
          },
          '& tr:first-child': {
            borderTop: 'none',
            textTransform: 'none !important',
            color: '#fff',
          },
        },
      },
    },
    BackstageTableHeader: {
      styleOverrides: {
        header: {
          color: '#fff !important',
          background: '#000a12',
        },
      },
    },
    BackstageTableToolbar: {
      styleOverrides: {
        root: {
          background: '#37474f !important',
          color: '#fff',
        },
        title: {},
        searchField: {
          background: '#37474f !important',
          color: '#fff',
        },
      },
    },
    BackstageItemCardGrid: {
      styleOverrides: {
        root: {
          background: '#37474f !important',
          color: '#fff',
        },
      },
    },
    BackstageItemCardHeader: {
      styleOverrides: {
        root: {
          background: '#000a12',
          color: colourDictionary.white,
        },
      },
    },
    // CatalogReactUserListPicker: {
    //   styleOverrides: {
    //     title: {s
    //       textTransform: 'none',
    //     },
    //   },
    // },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        standardError: ({ theme }) => ({
          color: '#FFFFFF',
          backgroundColor: theme.palette.error.dark,
          '& $icon': {
            color: '#FFFFFF',
          },
        }),
        standardInfo: ({ theme }) => ({
          color: '#FFFFFF',
          backgroundColor: theme.palette.primary.dark,
          '& $icon': {
            color: '#FFFFFF',
          },
        }),
        standardSuccess: ({ theme }) => ({
          color: '#FFFFFF',
          backgroundColor: theme.palette.success.dark,
          '& $icon': {
            color: '#FFFFFF',
          },
        }),
        standardWarning: ({ theme }) => ({
          color: theme.palette.grey[700],
          backgroundColor: theme.palette.secondary.light,
          '& $icon': {
            color: theme.palette.grey[700],
          },
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          color: colourDictionary.white
        },
        root: {
          '&[aria-expanded=true]': {
            backgroundColor: '#26385A',
            color: '#FFFFFF',
          },
          '&[aria-expanded=true] path': {
            fill: '#FFFFFF',
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(9,30,69,0.54)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '6px 30px',
          borderRadius: 3,
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 3,
          backgroundColor: "#00000000",
          // backgroundColor: theme.palette.grey[50],
          color: theme.palette.primary.main,
          margin: 4,
        }),
      },
    },
    // TODO: (awanlin) - we get a type error for `root`, need to investigate
    MuiSelect: {
      styleOverrides: {
        select: {
          '&[aria-expanded]': {
            backgroundColor: '#26385A',
            color: '#FFFFF',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 10,
        },
        switchBase: {
          padding: 12,
        },
        thumb: {
          backgroundColor: '#FFFFFF',
          height: 14,
          width: 14,
        },
        track: {
          borderRadius: 9,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          transition: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        button: {
          textTransform: 'none',
        },
        // root: {
        //   color: colourDictionary.white,

        // },
        // body1 : {
        //   color: colourDictionary.white,
        // },
        // body2: {
        //   color: colourDictionary.white
        // }
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: colourDictionary.white
        }
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root:{
          color: colourDictionary.white,
        }

      }
    }
    },
});
