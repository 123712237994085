import {
  alertApiRef,
  appThemeApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import React, { useEffect, useMemo } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { getTimeBasedGreeting } from './timeUtils';
import {
  ThemeProvider,
  Tooltip,
  Typography,
  createTheme,
} from '@material-ui/core';
import '@fontsource/charmonman';

const theme = createTheme({
  typography: {
    fontFamily: 'Charmonman, sans-serif',
  },
});

export const WelcomeTitle = () => {
  const identityApi = useApi(identityApiRef);
  const alertApi = useApi(alertApiRef);
  const greeting = useMemo(() => getTimeBasedGreeting(), []);
  const appThemeApi = useApi(appThemeApiRef);

  const { value: profile, error } = useAsync(() =>
    identityApi.getProfileInfo(),
  );
  const displayName = profile?.displayName;

  useEffect(() => {
    if (error) {
      alertApi.post({
        message: `Failed to load user identity: ${error}`,
        severity: 'error',
      });
    }
  }, [error, alertApi]);

  let nameStr = '';

  if (displayName) {
    nameStr = `, ${
      displayName.includes(',')
        ? displayName.split(', ')[1]
        : displayName.split(' ')[0]
    }`;
  }
  const userName = `${greeting.greeting}${nameStr}  !`;
  return (
    <ThemeProvider theme={theme}>
      <Tooltip title={greeting.language}>
        <Typography
          style={{
            fontFamily: 'Charmonman, sans-serif',
            color:
              appThemeApi.getActiveThemeId() === 'dark' ? '#F49F0A' : '#00283D',
          }}
          component="span"
          variant="inherit"
        >
          {userName}
        </Typography>
      </Tooltip>
      {/* <span>{`Welcome${nameStr}!`}</span> */}
    </ThemeProvider>
  );
};
