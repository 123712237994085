import React from 'react';
import type { FieldValidation } from '@rjsf/utils';
import {
  InputLabel,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
  capitalize,
  FormControl,
} from '@material-ui/core';
import {
  useApi,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';

/**  */
type serviceAccountType = {
  account: string;
  environment: string;
};

export const ServiceAccountsList = ({ onChange, rawErrors, required, uiSchema }: any) => {
  /** state  */
  const [env, setEnv] = React.useState('');
  const [preEnv, setPreEnv] = React.useState<string | undefined>();
  const [backendResponse, setBackendResponse] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [accountList, setAccountList] = React.useState<serviceAccountType[]>(
    [],
  );
  const [displayAlert, setDisplayAlert] = React.useState(false);
  const [apiCallLoader, setApiCallLoader] = React.useState(false);

  /** Backstage Hook Calls  */
  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);

  const { environments = { "dev": "Non-Production", "prod": "Production" } } = uiSchema['ui:options'];
  const { type } = uiSchema['ui:options'];
  const { mra = false } = uiSchema['ui:options'];


  /** Fetch the Topic Details from backend */
  async function fetchByPostConfluentCloudsMongoDBData(environment: string) {
    try {
      const baseUrl = await discoveryApi.getBaseUrl('confluentcloud');
      fetchApi
        .fetch(`${baseUrl}/getUserServiceAccountsList`, {
          headers: {
            "environment": environment,
            "type": type,
            "mra": mra
          }
        }).then(response => response.json())
        .then(apiResult => { setBackendResponse(apiResult) });
    } catch (error) {
      throw error;
    }
  }

  /** store the backend response and enable the service account field loader */
  if (
    backendResponse !== undefined &&
    JSON.parse(JSON.stringify(backendResponse))?.documents.length > 0
  ) {
    const serviceAccountList: serviceAccountType[] = [];
    JSON.parse(JSON.stringify(backendResponse))?.documents?.map((each: any) => {
      serviceAccountList.push({
        account: each.account,
        environment: env,
      });
    });
    if (accountList.length == 0) setAccountList(serviceAccountList);
    if (loading) setLoading(false);
    if (apiCallLoader) setApiCallLoader(false);
  } else if (
    backendResponse !== undefined &&
    JSON.parse(JSON.stringify(backendResponse))?.documents.length == 0 &&
    (preEnv === undefined || preEnv !== env)
  ) {
    setDisplayAlert(true);
    setPreEnv(env);
    setApiCallLoader(false);
    setLoading(false);
  }

  /** on change handlers for environment select based field   */
  const handleChange = (event: any) => {
    setEnv(event.target.value as string);
    setBackendResponse(undefined);
    setAccountList([]);
    setDisplayAlert(false);
    setApiCallLoader(true);
    fetchByPostConfluentCloudsMongoDBData(event.target.value as string);
  };

  /** on change handlers for topic Auto complete dropdown  */
  const onSelect = (_: any, value: serviceAccountType | null) => {
    onChange(value || null);
  };

  return (
    <>
      <FormControl
        margin="normal"
        style={{ flexDirection: 'row' }}
        error={rawErrors?.length > 0}
      >
        {displayAlert ? (
          <>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              You don't have the Service Account in{' '}
              <strong>{capitalize(env)}</strong> environment —{' '}
              <strong>
                Contact{' '}
                <a
                  href="https://outlook.office.com/?path=/mail/action/compose&to=grp-eventplatform@mnscorp.onmicrosoft.com&subject=BUG:%3Cplease%20fill%20the%20email%20subject%20without%20removing%20tag%3E&body=%3CAdd%20Your%20Request%20here%3E"
                  target="_blank"
                >
                  Confluent Kafka Team
                </a>{' '}
                !{' '}
              </strong>{' '}
              or Select another environment
            </Alert>
          </>
        ) : (
          <></>
        )}
      </FormControl>
      <FormControl
        margin="normal"
        style={{ flexDirection: 'row' }}
        error={rawErrors?.length > 0}
      >
        <InputLabel id="demo-simple-select-label">Environment</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={env}
          label="Environment"
          onChange={handleChange}
          style={{ width: '100%' }}
        >
          {Object.keys(environments).map((key) => (
            <MenuItem key={key} value={key}>{environments[key]}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        margin="normal"
        style={{ flexDirection: 'row' }}
        required={required}
        error={rawErrors?.length > 0}
      >
        <Autocomplete
          key={env}
          disablePortal
          id="combo-box-demo"
          onChange={onSelect}
          loading={loading}
          options={accountList}
          getOptionLabel={option => option.account.toString()}
          style={{ width: '100%' }}
          renderInput={(params: any) => (
            <TextField {...params} label="Service Accounts" />
          )}
        />
        {apiCallLoader ? <CircularProgress /> : <></>}
      </FormControl>
    </>
  );
};

// Converted line 147 from     value: object, to     value: string, due to yarn tsc error
export const ServiceAccountValidation = (
  value: string,
  validation: FieldValidation,
) => {
  if (JSON.parse(JSON.stringify(value))?.account.length === 0) {
    validation.addError('Service Account Value is missing');
  }
  if (JSON.parse(JSON.stringify(value))?.environment.length === 0) {
    validation.addError('Environment Value is missing');
  }
};
