import {
  scaffolderPlugin,
  // createScaffolderFieldExtension,
} from '@internal/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@internal/plugin-scaffolder-react';
import { IoCodeValidation, IocodeValidator } from './IocodeValidationExtension';

export const IocodeValidationExtensions = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'Iocode-Validator',
    component: IocodeValidator,
    validation: IoCodeValidation,
  }),
);
