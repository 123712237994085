import React from 'react';
import { EntityLayout } from '@backstage/plugin-catalog';
import { TechDocsTabContent } from '../TabContent/TechDocTabContent';
import { OverviewTabContent } from '../TabContent/OverViewTabContent';

export const defaultEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {OverviewTabContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {TechDocsTabContent}
    </EntityLayout.Route>
  </EntityLayout>
);
