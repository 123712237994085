import { scaffolderPlugin } from '@internal/plugin-scaffolder';

import { createScaffolderFieldExtension } from '@internal/plugin-scaffolder-react';
import {
    AzureCustomExtensionWithOptionsSchema,
    AzureCustomExtension,
} from './AzureExtension';

export const AzureCustomFieldExtension = scaffolderPlugin.provide(
    createScaffolderFieldExtension({
        name: 'AzureFieldExtension',
        component: AzureCustomExtension,
        schema: AzureCustomExtensionWithOptionsSchema,
    }),
);
