import {
    scaffolderPlugin,
} from '@internal/plugin-scaffolder';
import {createScaffolderFieldExtension} from '@internal/plugin-scaffolder-react';
import { CreateEcosystemBuilderCN, validateEcosystemBuilderCN } from './EcosystemBuilderCNCreation';


export const CreateEcosystemBuilderCNForm = scaffolderPlugin.provide(
createScaffolderFieldExtension({
    name: 'createEcosystemBuilderCN',
    component: CreateEcosystemBuilderCN,
    validation: validateEcosystemBuilderCN,
}),
);