/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
import React, { useState } from 'react';

import {
  FormControl,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core';

import fetch from 'node-fetch';
import { Autocomplete } from '@material-ui/lab';
import { useApi, discoveryApiRef, fetchApiRef } from '@backstage/core-plugin-api';
import * as Diff from 'diff';
import ArrayCard from './ArrayCard';
import CircularProgressWithLabel from './circularProgressWithLabel';

type keyToTitleMapType = {
  application_name: string;
  application_name_abbreviation: string;
  description: string;
  application_status: string;
  product: string;
  product_group: string;
  product_portfolio: string;
  platform: string;
  hosting_type: string;
  supported_by: string;
  developed_by: string;
  confluence_page: string;
  business_criticality: string;
  data_classification: string;
  request_owner: string;
  github_team_maintainer: string;
  tech_stack: string;
  sandboxsubscription_create: string;
  prod_nprod_subscription_create: string;
  resource_group_create: string;
  prod_rg_env: string[];
  nonprod_rg_env: string[];
  self_hosted_runners_enable: string;
  team_name: string;
  app_id_create: string;
  ad_group_create: string;
  github_repository_create: string;
  override_github_repo_name: string;
  existing_github_repo_name: string;
  github_team_rbac_create: string;
  yaccount_create: string;
  yaccount_enable_mailbox: string;
  existing_yaccount: string;
  yaccount_secret_key_name: string;
  io_code: string;
  sonarcloud_create: string;
  snyk_appsec_create: string;
  prisma_appsec_create: string;
  launchdarkly_create: string;
  fortify_appsec_create: string;
  newrelic_create: string;
  pagerduty_create: string;
};

export const UpdateEcosystemBuilder = ({ onChange }: any) => {
  const [jsonData, setJsonData] = useState<any>({});
  const [fileNotFound, setFileNotFound] = useState<boolean>(false);
  const [folders, setFolders] = useState<string[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<string>('');
  const [files, setFiles] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState<string>('');
  const [_folderNotFound, setFolderNotFound] = useState<boolean>(false);
  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);
  const [_showFileContent, setShowFileContent] = useState<boolean>(false);
  const [checkResourceGroupCreation, setCheckResourceGroupCreation] =
    useState<boolean>(false);
  const [showSandboxSubscriptionAlert, setShowSandboxSubscriptionAlert] =
    useState<boolean>(false);

  const [fileContent, setFileContent] = useState<JSX.Element[]>([]);
  const [showFileChanges, setShowFileChanges] = useState<boolean>(false);
  const [fileFetched, setFileFetched] = useState<boolean>(false);
  const [changesMade, setChangesMade] = useState<boolean>(false);
  const [originalJsonData, setOriginalJsonData] = useState<any>({});

  const [checkApplicationId, setCheckApplicationId] = useState<boolean>(false);
  const [_checkAppIdCreate, setCheckAppIdCreate] = useState<boolean>(false);

  const [_teamMembers, setTeamMembers] = useState(jsonData.team_members || []);
  const [_existingAdGroups, setExistingAdGroups] = useState(
    jsonData.existing_ad_group_list || [],
  );

  const [_enabledKeys, setEnabledKeys] = useState<Record<string, boolean>>({});
  const [disabledKeys, setDisabledKeys] = useState<Record<string, boolean>>({});
  const [loadingApplicationId, setLoadingApplicationId] =
    useState<boolean>(false);
  const [_malData, setMalData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [pollingInterval, setPollingInterval] = useState<any>(null);
  const [hideFetchButton, setHideFetchButton] = useState<boolean>(false);

  const setOriginalData = (data: any) => {
    setOriginalJsonData(data);
  };

  React.useEffect(() => {
    setSelectedFile('');
    setFiles([]);
    setFileNotFound(false);
    setFileFetched(false);
    setShowFileContent(false);
    setShowFileChanges(false);
    setChangesMade(false);
    setOriginalJsonData({});
    setJsonData({});
    setTeamMembers([]);
    setExistingAdGroups([]);
  }, [selectedFolder]);

  React.useEffect(() => {
    setFileNotFound(false);
    setFileFetched(false);
    setShowFileContent(false);
    setShowFileChanges(false);
    setChangesMade(false);
    setOriginalJsonData({});
    setJsonData({});
    setTeamMembers([]);
    setExistingAdGroups([]);
    setLoading(false);
    setPollingInterval(null);
    setMalData(null);
    onChange({});
  }, [selectedFile]);

  const keyToTitleMap: keyToTitleMapType = {
    application_name: 'Application Name',
    application_name_abbreviation: 'App Friendly Name',
    description: 'Description about your app',
    application_status: 'Application Status',
    product: 'Product Name',
    product_group: 'Product Group',
    product_portfolio: 'Portfolio',
    platform: 'Business Platform',
    hosting_type: 'Hosting Type',
    supported_by: 'Supported By',
    developed_by: 'Developed By',
    confluence_page: 'Link to Documentation',
    business_criticality: 'Recovery Time Objective',
    data_classification: 'Data Classification',
    request_owner: 'Requested Owner',
    github_team_maintainer: 'Github Team Maintainer',
    tech_stack: 'Tech Stack',
    sandboxsubscription_create: 'Create a sandbox subscription',
    prod_nprod_subscription_create: 'Create Staging and Prod Subscriptions',

    resource_group_create: 'Create Resource Groups',

    prod_rg_env: ['Choose Prod environment'],
    nonprod_rg_env: ['Choose Non Prod environment'],
    self_hosted_runners_enable: 'Create Selfhosted Runner for Github Repo',
    team_name: 'Team name',
    app_id_create: 'Create Application ID',
    ad_group_create: 'Create New AD Group',
    github_repository_create: 'Create a New Github Repository',
    override_github_repo_name: 'Provide a Custom GitHub Repository name',
    existing_github_repo_name: 'Provide Existing Github Repository name',
    github_team_rbac_create: 'Enable GitHub Team RBAC',
    yaccount_create: 'Do you want to create a new Service Account (Y Account)?',
    yaccount_enable_mailbox:
      'Enable mail box for Service Account which is required to login to Github (Assigns an Office 365 F3 License which costs ~ £20 per year)',
    existing_yaccount: 'Existing Y account ID',
    yaccount_secret_key_name: 'Y account Secret Key name',
    io_code: 'IO-CODE',
    sonarcloud_create: 'Enable SonarCloud for code quality scanning',
    snyk_appsec_create: 'Enable Snyk for security',
    prisma_appsec_create: 'Enable Prisma for security',
    launchdarkly_create: 'Enable Launchdarkly for Feature Flag',
    fortify_appsec_create: 'Enable Fortify for security',
    newrelic_create: 'Enable NewRelic for monitoring',
    pagerduty_create: 'Enable PagerDuty for alerting',
  };


  async function fetchAppId(alias: string | undefined) {
    try {
      const baseUrl = await discoveryApi.getBaseUrl('msgraph');
      const query = `filter=fields%2FAlias+eq+%27${alias}%27`;
      const response = await fetch(
        `${baseUrl}/v2/sharepoint/MAL/filteredMal?${query}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
        },
      );

      if (!response.ok) {
        throw new Error(
          `Failed to fetch contents, ${response.status} ${response.statusText}`,
        );
      }
      const data = await response.json(); // this is returning a promise

      const applicationId = data.items[0].applicationID;
      return applicationId;
    } catch (error: any) {
      console.error(`Error: ${error.message}`);
    }
  }

  const readFolderContents = async () => {
    try {
      const baseUrl = await discoveryApi.getBaseUrl('github');
      const apiUrl = `${baseUrl}/folder/content`
      const response = await fetchApi.fetch(apiUrl, {
        headers: {
          'credentials': 'include',
        },
      });
      if (!response.ok) {
        setFolderNotFound(true);
        return;
      }
      const resp = await response.json();
      const arrayOfData: [] = resp.data;
      const folderNames = arrayOfData.map((eachObj: any) =>
        // eachObj.name.replace(/-/g, ' ')
        eachObj.name
      );
      setFolders(folderNames);
    } catch (error: any) {
      console.error(`Error: ${error.message}`);
    }
  };

  React.useEffect(() => {
    readFolderContents();
  }, []);

  const readFiles = async (folderName: string) => {
    try {
      const baseUrl = await discoveryApi.getBaseUrl('github');
      const apiUrl = `${baseUrl}/read/files/${folderName}`;
      const response = await fetchApi.fetch(apiUrl, {
        headers: {
          'credentials': 'include',
        },
      });
      if (!response.ok) {
        setFileNotFound(true);
        return;
      }
      const resp = await response.json();
      const arraryOfData: [] = resp.data;
      const fileNames = arraryOfData.map((eachObj: any) => eachObj.name).filter((item: string) => item !== '.gitkeep')
      setFiles(fileNames);
    } catch (error: any) {
      console.error(`Error: ${error.message}`);
    }
  };

  const renderApplicationIDField = async (alias: string) => {
    setLoadingApplicationId(true);

    try {
      const applicationID = await fetchAppId(alias);
      setCheckApplicationId(true);
      setJsonData((prevData: any) => ({
        ...prevData,
        application_id: applicationID,
      }));
      return applicationID;
    } catch (error: any) {
      console.error(`Error fetching application ID: ${error.message}`);
    } finally {
      setLoadingApplicationId(false);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);

      const startTime = performance.now();
      let elapsedTime = 0;

      // Function to update the elapsed time and trigger a re-render
      const updateElapsedTime = () => {
        const currentTime = performance.now();
        elapsedTime = (currentTime - startTime) / 1000;
        setPollingInterval(elapsedTime); // Pass elapsed time to setLoading
      };

      // Update elapsed time every 100 milliseconds
      const timerId = setInterval(updateElapsedTime, 100);

      const baseUrl = await discoveryApi.getBaseUrl('github');
      const apiUrl = `${baseUrl}/fetch/data/${selectedFolder}/${selectedFile}`
      const response = await fetchApi.fetch(apiUrl, {
        headers: {
          'credentials': 'include',
        },
      });

      if (!response.ok) {
        setFileNotFound(true);
        setLoading(false);

        return;
      }

      const objData = await response.json();
      const data = objData.data;

      if (data.content) {
        const fileContentForDataFetched = Buffer.from(data.content, 'base64').toString(
          'ascii',
        );
        const parsedJson = JSON.parse(fileContentForDataFetched);

        const isSandboxSubscriptionWorkloadPresent =
          'sandbox_subscription_workload' in parsedJson;
        const isResourceGroupCreatePresent = parsedJson.resource_group_create;

        if (isSandboxSubscriptionWorkloadPresent) {
          setShowSandboxSubscriptionAlert(true);
          setLoading(false);

          return;
        }

        if (isResourceGroupCreatePresent) {
          setCheckResourceGroupCreation(true);
          setLoading(false);
          return;
        }

        const disabledKeysObj: Record<string, boolean> = {};
        Object.entries(parsedJson).forEach(([key, value]) => {
          if (typeof value === 'boolean' && value) {
            disabledKeysObj[key] = value;
          }
        });
        setDisabledKeys(disabledKeysObj);

        const enabledKeysObj: Record<string, boolean> = {};
        Object.entries(parsedJson).forEach(([key, value]) => {
          if (typeof value === 'boolean' && !value) {
            enabledKeysObj[key] = value;
          }
        });
        setEnabledKeys(enabledKeysObj);

        const isApplicationIdPresent = 'application_id' in parsedJson;

        if (!isApplicationIdPresent) {
          setLoadingApplicationId(true);

          // Fetch application ID
          const applicationID = await renderApplicationIDField(
            parsedJson.application_name_abbreviation,
          );
          setLoadingApplicationId(false);

          parsedJson.application_id = applicationID;
          setJsonData(parsedJson);
          setCheckAppIdCreate(true);
          parsedJson.app_id_create = false;
        } else {
          setJsonData(parsedJson);
        }
        parsedJson.filename = selectedFile;

        if (!originalJsonData) {
          setOriginalData(parsedJson);
        }

        setOriginalData(parsedJson);
        setJsonData(parsedJson);
        onChange(parsedJson);
        setShowFileContent(true);
        setFileFetched(true);
        setChangesMade(false);
        setTeamMembers(parsedJson.team_members || []);
        setExistingAdGroups(parsedJson.existing_ad_group_list || []);
      } else {
        setFileNotFound(true);
      }

      await new Promise(resolve => setTimeout(resolve, 3000));

      const endTime = performance.now();
      elapsedTime = (endTime - startTime) / 1000;
      setPollingInterval(elapsedTime);

      clearInterval(timerId); // Stop the timer

      setLoading(false);
      setHideFetchButton(true);
    } catch (error: any) {
      console.error(`Error: ${error.message}`);
      setLoading(false);
      setLoadingApplicationId(false);
    }
  };

  const viewFileChanges = () => {
    const differences = Diff.diffJson(originalJsonData, jsonData);
    const changes = differences.map((part, index) => {
      const style = {
        color: part.added ? 'green' : part.removed ? 'red' : 'inherit',
        fontWeight: part.added || part.removed ? 'bold' : 'normal',
      };

      return (
        <span key={index} style={style}>
          {part.value}
        </span>
      );
    });

    setFileContent(changes);
    setShowFileChanges(true);
    setChangesMade(true);
  };


  const handleCheckboxChange = (key: string) => {
    setJsonData((prevData: any) => {
      const newValue = !prevData[key];
      if (
        key === 'yaccount_create' &&
        (prevData.existing_yaccount || prevData.yaccount_secret_key_name)
      ) {
        if (!prevData[key]) {
          const { existing_yaccount, yaccount_secret_key_name, ...rest } =
            prevData;
          onChange({ ...rest, [key]: !prevData[key] });
          setChangesMade(true);
          return { ...rest, [key]: !prevData[key] };
        }
      } else if (
        key === 'yaccount_create' &&
        !newValue &&
        prevData.yaccount_enable_mailbox
      ) {
        onChange({
          ...prevData,
          yaccount_enable_mailbox: false,
          [key]: newValue,
        });
        setChangesMade(true);
        return { ...prevData, yaccount_enable_mailbox: false, [key]: newValue };
      }

      if (
        key === 'github_team_rbac_create' &&
        !newValue &&
        prevData.github_team_maintainer
      ) {
        const { github_team_maintainer, ...rest } = prevData;
        onChange({ ...rest, [key]: newValue });
        setChangesMade(true);
        return { ...rest, [key]: newValue };
      }
      onChange({ ...prevData, [key]: newValue });
      setChangesMade(true);
      return { ...prevData, [key]: newValue };
    });
  };

  const handleTextFieldChange = (key: string, newValue: string) => {
    setJsonData((prevData: any) => {
      onChange({ ...prevData, [key]: newValue });
      setChangesMade(true);
      return { ...prevData, [key]: newValue };
    });
  };

  const defaultExistingAdGroup =
    jsonData.existing_ad_group_list?.length > 0
      ? { ...jsonData.existing_ad_group_list[0] }
      : {
        read: false,
        write: false,
        admin: false,
        adgroup_name: '',
      };

  const defaultTeamMember =
    jsonData.team_members?.length > 0
      ? { ...jsonData.team_members[0] }
      : {
        read: false,
        write: false,
        admin: false,
        email: '',
      };

  const handleArrayChange = (key: string, index: number, updatedItem: any) => {
    setJsonData((prevData: any) => {
      const updatedArray = prevData[key].map((item: any, i: number) =>
        i === index ? updatedItem : item,
      );
      const updatedData = { ...prevData, [key]: updatedArray };
      onChange(updatedData);
      setChangesMade(true);
      return updatedData;
    });
  };

  const handleAddArrayItem = (key: string) => {
    let newItem: any = {};
    if (key === 'team_members') {
      setTeamMembers((prevTeamMembers: any) => [
        ...prevTeamMembers,
        { ...defaultTeamMember },
      ]);
      newItem = { read: false, write: false, admin: false, email: '' };
    } else if (key === 'existing_ad_group_list') {
      setExistingAdGroups((prevExistingAdGroups: any) => [
        ...prevExistingAdGroups,
        { ...defaultExistingAdGroup },
      ]);
      newItem = { read: false, write: false, admin: false, adgroup_name: '' };
    }

    setJsonData((prevData: any) => {
      onChange({ ...prevData, [key]: [...prevData[key], newItem] });
      setChangesMade(true);
      return { ...prevData, [key]: [...prevData[key], newItem] };
    });
  };

  const handleRemoveArrayItem = (key: string, index: number) => {
    setJsonData((prevData: any) => {
      const updatedArray = prevData[key].filter(
        (_: any, i: number) => i !== index,
      );
      const updatedData = { ...prevData, [key]: updatedArray };
      onChange(updatedData);
      setChangesMade(true);
      return updatedData;
    });
  };

  const viewFileButton = (
    <Grid item>
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowFileChanges(true)}
        >
          View File
        </Button>
      </FormControl>
    </Grid>
  );

  const viewFileChangesButton = (
    <Grid item>
      <FormControl>
        <Button variant="contained" color="primary" onClick={viewFileChanges}>
          View File Changes
        </Button>
      </FormControl>
    </Grid>
  );

  const validateData = (key: any, value: any) => {

    if (key === 'request_owner' && value) {
      const regex = '^[Yya-zA-Z0-9._%+-]+@(mnscorp\.net)$';
      const check = value.match(regex);
      return typeof value === 'string' && value.length > 0 && check;
    }
    if (key === 'existing_yaccount') {
      if (value) {
        const regex = '^[Yy].*@mnscorp.net$';
        const check = value.match(regex);
        return typeof value === 'string' && value.length > 0 && check;
      } else {
        return true;
      }
    }

    if (key === 'yaccount_secret_key_name') {
      if (value) {
        const regex = '^(?!ghp).*';
        const check = value.match(regex);
        return typeof value === 'string' && value.length > 0 && check;
      } else {
        return true;
      }
    }
  };

  return (
    <div>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                options={folders}
                value={selectedFolder}
                getOptionLabel={option => option.replace(/-/g, ' ')}
                onChange={(_, newValue) => {
                  setSelectedFolder(newValue || '');
                  setFileNotFound(false);
                  readFiles(newValue || '');
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Select Portfolio"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                options={files}
                value={selectedFile}
                onChange={(_, newValue) => {
                  setSelectedFile(newValue || '');
                  setFileNotFound(false);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Select Your File"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </Grid>

          {Object.entries(jsonData).map(([key, value]: [string, any]) => {
            const title = (keyToTitleMap as any)[key || ''] || key;
            if (Array.isArray(value)) {
              return (
                <Grid
                  item
                  key={key}
                  xs={12}
                // sm={6}
                >
                  {key === 'team_members' ? (
                    <ArrayCard
                      title={title}
                      items={value}
                      onAdd={() => handleAddArrayItem(key)}
                      onRemove={(index: number) =>
                        handleRemoveArrayItem(key, index)
                      }
                      onChange={(index: number, updatedItem: any) =>
                        handleArrayChange(key, index, updatedItem)
                      }
                    />
                  ) : null}
                </Grid>
              );
            } else if (typeof value === 'object' && value !== null) {
              // Handle the case where the value is an object, prevent displaying it as "object, object"
              return null;
            }

            {
              key === 'application_id' && checkApplicationId && (
                <FormControl fullWidth>
                  {loadingApplicationId ? (
                    <CircularProgress size={20} />
                  ) : (
                    <TextField
                      label={title}
                      value={jsonData.application_id}
                      variant="outlined"
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  )}
                </FormControl>
              );
            }

            if (
              key === 'resource_group_create' ||
              key === 'self_hosted_runners_enable' ||
              key === 'launchdarkly_create' ||
              key === 'nonprod_rg_env' ||
              key === 'prod_rg_env' ||
              key === 'description' ||
              key === 'product_group' ||
              key === 'platform' ||
              key === 'hosting_type' ||
              key === 'developed_by' ||
              key === 'business_criticality' ||
              key === 'data_classification' ||
              key === 'tech_stack' ||
              key === 'application_status' ||
              key === 'app_id_create' ||
              key === 'ad_group_create' ||
              key === 'github_repository_create' ||
              key === 'team_name' ||
              key === 'github_team_maintainer' ||
              key === 'github_team_rbac_create' ||
              key === 'yaccount_create' ||
              key === 'yaccount_enable_mailbox' ||
              key === 'existing_yaccount' ||
              key === 'yaccount_secret_key_name' ||
              key === 'filename' ||
              key === 'request_owner'
            ) {
              return null;
            } else {
              return (
                <Grid
                  item
                  key={key}
                  xs={12}
                // sm={6}
                >
                  <FormControl fullWidth>
                    {typeof value === 'boolean' ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={value}
                            onChange={() => handleCheckboxChange(key)}
                            disabled={key in disabledKeys}
                          />
                        }
                        label={title}
                      />
                    ) : (
                      <TextField
                        label={title}
                        value={value}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          readOnly: value,
                          onChange: e =>
                            handleTextFieldChange(key, e.target.value),
                        }}
                      />
                    )}
                    {key === 'github_repository_create' && (
                      <>
                        {jsonData[key] &&
                          !jsonData.override_github_repo_name ? (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={value}
                                onChange={() => handleCheckboxChange(key)}
                                disabled={
                                  key in disabledKeys ||
                                  'override_github_repo_name' in jsonData
                                }
                              />
                            }
                            label={title}
                          />
                        ) : null}
                        {!jsonData[key] &&
                          'existing_github_repo_name' in jsonData ? (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={value}
                                onChange={() => handleCheckboxChange(key)}
                                disabled={key in disabledKeys}
                              />
                            }
                            label={title}
                          />
                        ) : null}
                      </>
                    )}
                  </FormControl>
                </Grid>
              );
            }
          })}

          {fileFetched && (
            <>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Request Owner"
                    variant="outlined"
                    value={jsonData.request_owner}
                    InputProps={{
                      readOnly: 'request_owner' in disabledKeys,
                      onChange: e =>
                        handleTextFieldChange(
                          'request_owner',
                          e.target.value,
                        ),
                    }}
                    error={
                      !validateData(
                        'request_owner',
                        jsonData.request_owner,
                      )
                    }
                    helperText={
                      !validateData(
                        'request_owner',
                        jsonData.request_owner,
                      ) &&
                      '[INFO] Provide the email id of Sr. Engineer/Architect to manage the resource admin activities. The mail should be in the format user@mnscorp.net.'
                    }
                  />
                </FormControl>
              </Grid>
            </>
          )}

          {fileFetched && (
            <>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={jsonData.github_team_rbac_create}
                        onChange={() =>
                          handleCheckboxChange('github_team_rbac_create')
                        }
                        disabled={'github_team_rbac_create' in disabledKeys}
                      />
                    }
                    label="Enable Github RBAC"
                  />
                </FormControl>
              </Grid>
              {jsonData.github_team_rbac_create && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      label="Github Team Maintainer"
                      value={jsonData.github_team_maintainer}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: 'github_team_rbac_create' in disabledKeys,
                        onChange: e =>
                          handleTextFieldChange(
                            'github_team_maintainer',
                            e.target.value,
                          ),
                      }}
                    />
                  </FormControl>
                </Grid>
              )}
            </>
          )}

          {fileFetched && (
            <>
              <Grid item xs={12}
              // sm={6}
              >
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={jsonData.yaccount_create}
                        onChange={() => handleCheckboxChange('yaccount_create')}
                        disabled={'yaccount_create' in disabledKeys}
                      />
                    }
                    label="Enable Service Account"
                  />
                </FormControl>
              </Grid>


              {fileFetched && !jsonData.yaccount_create && (
                <>
                  <Grid item xs={12}
                  // sm={6}
                  >
                    <FormControl fullWidth>
                      <TextField
                        label="Existing Service Account ID"
                        variant='outlined'
                        value={jsonData.existing_yaccount || ''}
                        InputProps={{
                          readOnly: 'existing_yaccount' in disabledKeys,
                          onChange: (e) => handleTextFieldChange('existing_yaccount', e.target.value),
                        }}
                        error={!validateData('existing_yaccount', jsonData.existing_yaccount)}
                        helperText={!validateData('existing_yaccount', jsonData.existing_yaccount) && "[INFO] Existing Y account ID Provide existing Y account ID in mnscorp.net format"}
                      // onChange={(e) => handleTextFieldChange('existing_yaccount', e.target.value)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}
                  // sm={6}
                  >
                    <FormControl fullWidth>
                      <TextField
                        label="Service Account Secret Key Name"
                        variant='outlined'
                        value={jsonData.yaccount_secret_key_name || ''}
                        InputProps={{
                          readOnly: 'yaccount_secret_key_name' in disabledKeys,
                          onChange: (e) => handleTextFieldChange('yaccount_secret_key_name', e.target.value),
                        }}
                        error={!validateData('yaccount_secret_key_name', jsonData.yaccount_secret_key_name)}
                        helperText={!validateData('yaccount_secret_key_name', jsonData.yaccount_secret_key_name) && "[INFO] Secret Name of Existing Yaccount password stored in the github organization as secret. if you do not have organization secret created for your Y account password , please connect with cloud-devsecops team to get the secret created before raising this request."}
                      // onChange={(e) => handleTextFieldChange('yaccount_secret_key_name', e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
            </>
          )}


          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <FormControl>
                {!hideFetchButton && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={fetchData}
                    disabled={loading}
                  >
                    Fetch Data
                  </Button>)
                }
              </FormControl>
            </Grid>
            {loading && (
              <Grid item>
                <CircularProgressWithLabel value={pollingInterval} />
              </Grid>
            )}
            {fileFetched && !changesMade && viewFileButton}
            {fileFetched && changesMade && viewFileChangesButton}
          </Grid>
          {fileNotFound && (
            <Grid item xs={12}>
              <h3 style={{ color: 'red' }}>
                File not found at the specified path: {selectedFolder}/
                {selectedFile}
              </h3>
            </Grid>
          )}
        </Grid>
        <Dialog
          open={showFileChanges}
          onClose={() => setShowFileChanges(false)}
        >
          <DialogTitle>File Name: {selectedFile}</DialogTitle>
          <DialogContent>
            {changesMade ? (
              <>
                <h2>File Changes:</h2>
                <pre>{fileContent}</pre>
              </>
            ) : (
              <>
                <h2>File Content:</h2>
                <pre>{JSON.stringify(originalJsonData, null, 2)}</pre>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowFileChanges(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </form>
      <Dialog
        open={showSandboxSubscriptionAlert || checkResourceGroupCreation}
        onClose={() => setShowSandboxSubscriptionAlert(false)}
      >
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <p>
            {showSandboxSubscriptionAlert
              ? 'Sandbox subscription workload is not supported in this form.'
              : null}
            {checkResourceGroupCreation
              ? // 'Resource group creation is not supported in this form.'
              'This form is specifically for cloud native applications and does not support the creation of resource groups, which falls under ECC v2.'
              : null}
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowSandboxSubscriptionAlert(false);
              setCheckResourceGroupCreation(false);
            }}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
