import {
  scaffolderPlugin,
  // createScaffolderFieldExtension,
} from '@internal/plugin-scaffolder';

import { createScaffolderFieldExtension } from '@internal/plugin-scaffolder-react';
import {
  ApplicationIDExtensionWithOptionsSchema,
  ApplicationPicker,
  MALValidation,
} from './ApplicationIDExtension';

export const ApplicationIDFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'Application',
    component: ApplicationPicker,
    validation: MALValidation,
    schema: ApplicationIDExtensionWithOptionsSchema,
  }),
);
