import React from 'react';
import DarkIcon from '@material-ui/icons/Brightness2';
import LightIcon from '@material-ui/icons/WbSunny';
import { AppTheme } from '@backstage/core-plugin-api';
import { UnifiedThemeProvider } from '@backstage/theme';
import { customDarkTheme } from './customDarkTheme';
import { customLightTheme } from './customLightTheme';

export const appThemes = new Array<AppTheme>();

appThemes.push({
  id: 'light',
  title: 'D&T Light Theme',
  variant: 'light',
  icon: <LightIcon />,
  Provider: ({ children }) => (
    <UnifiedThemeProvider theme={customLightTheme} children={children} />
  ),
});

appThemes.push({
  id: 'dark',
  title: 'D&T Dark Theme',
  variant: 'dark',
  icon: <DarkIcon />,
  Provider: ({ children }) => (
    <UnifiedThemeProvider theme={customDarkTheme} children={children} />
  ),
});
