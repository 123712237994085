import React, { useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import type { FieldValidation } from '@rjsf/utils';
import { TextField } from '@material-ui/core';
import { microsoftAuthApiRef, useApi } from '@backstage/core-plugin-api';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useAsync } from 'react-use';

let uiSchemaOptions: any;
export const AzureActiveDirectory = ({
  onChange,
  required,
  rawErrors,
  uiSchema,
}: any) => {

  const apiRef = useApi(microsoftAuthApiRef);
  const { loading, value: token } = useAsync(() => apiRef.getAccessToken());
  const [isFetchDataLoaded, setIsFetchDataLoaded] = useState(false);
  const [dropDownList, setDropDownList] = useState([]);
  const [userInputTxt, setUserInputTxt] = useState('IT');
  const [selectedValue, setSelectedValue] = useState(null);

  uiSchemaOptions = uiSchema['ui:options'];
  const excludeOptions = uiSchemaOptions?.exclude || '';

  useEffect(() => {
    if (!loading && !isFetchDataLoaded && userInputTxt && userInputTxt !== '') {
      let msGraphUrl: string;
      setIsFetchDataLoaded(true);
      if (excludeOptions !== '' && excludeOptions !== null && excludeOptions !== undefined) {
        const formFilter = `&$filter=not(groupTypes/any(c:c+eq+'${excludeOptions}'))`;
        msGraphUrl = `https://graph.microsoft.com/v1.0/groups?$search=%22displayName%3a${userInputTxt}%22&$select=id,displayName${formFilter}`;
      } else {
        msGraphUrl = `https://graph.microsoft.com/v1.0/groups?$search=%22displayName%3a${userInputTxt}%22&$select=id,displayName`;
      }
      fetch(msGraphUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          ConsistencyLevel: 'eventual',
        },
        redirect: 'follow',
      })
        .then((response) => response.json())
        .then((result) => setDropDownList(result.value))

    }
  }, [loading, isFetchDataLoaded, userInputTxt, excludeOptions, token]);

  const handleAutocompleteChange = (_event: any, newValue: any) => {
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const handleInputChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setUserInputTxt(e.target.value);
    setIsFetchDataLoaded(false);
  };

  if (uiSchemaOptions?.validate && userInputTxt === 'IT' && selectedValue === null) {
    onChange({
      id: '0000-0000-0000-0000-0000',
      displayName: 'user-not-selected-ad-group',
    });
  }

  return (
    <FormControl margin="normal" style={{ flexDirection: 'row' }} required={required} error={rawErrors?.length > 0}>
      <Autocomplete
        onChange={handleAutocompleteChange}
        options={dropDownList}
        getOptionLabel={(option: any) => option.displayName}
        style={{ width: '100%' }}
        value={selectedValue}
        renderInput={(params) => (
          <TextField onChange={handleInputChange} {...params} label="Azure Active Directory List" />
        )}
      />
    </FormControl>
  );
};

export const AzureActiveDirectoryValidation = (value: any, validation: FieldValidation) => {
  if (uiSchemaOptions?.validate && (!value?.displayName || value?.displayName === '' || value?.id === '0000-0000-0000-0000-0000')) {
    validation.addError('Azure ActiveDirectory is required. Please select an AD group name.');
  }
};
