import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import { microsoftAzureToken, fetchAzureADUsers, validateEmail } from '../api';
import { TEAM_MEMBER_INFO, HELPER_TEXT_TEAM_MEMBER_INFO } from '../../../../constants/ecosystemBuilderCreationConstants'

interface TeamMember {
    email: string;
    read: boolean;
    write: boolean;
    admin: boolean;
}

interface TeamMembersFieldsProps {
    teamMembers: TeamMember[];
    handleAddTeamMember: (teamMember: TeamMember) => void;
    handleEditTeamMember: (index: number, updatedTeamMember: TeamMember) => void;
    handleRemoveTeamMember: (index: number) => void;
}

export const TeamMembersFields: React.FC<TeamMembersFieldsProps> = ({
    teamMembers,
    handleAddTeamMember,
    handleEditTeamMember,
    handleRemoveTeamMember,
}) => {
    const [teamMemberName, setTeamMemberName] = useState<string>('');
    const [teamMemberRead, setTeamMemberRead] = useState<boolean>(false);
    const [teamMemberWrite, setTeamMemberWrite] = useState<boolean>(false);
    const [teamMemberAdmin, setTeamMemberAdmin] = useState<boolean>(false);
    const [editIndex, setEditIndex] = useState<number | null>(null);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [changesMade, setChangesMade] = useState<boolean>(false);
    const [addChangesMade, setAddChangesMade] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>('');
    const accessToken = microsoftAzureToken();

    useEffect(() => {
        if (editIndex !== null) {
            const selectedTeamMember = teamMembers[editIndex];
            const changes =
                selectedTeamMember.email !== teamMemberName ||
                selectedTeamMember.read !== teamMemberRead ||
                selectedTeamMember.write !== teamMemberWrite ||
                selectedTeamMember.admin !== teamMemberAdmin;

            setChangesMade(changes);
        }
    }, [teamMembers, editIndex, teamMemberName, teamMemberRead, teamMemberWrite, teamMemberAdmin]);

    useEffect(() => {
        const changes = teamMemberName.trim() !== '' || teamMemberRead || teamMemberWrite || teamMemberAdmin;
        setAddChangesMade(changes);
    }, [teamMemberName, teamMemberRead, teamMemberWrite, teamMemberAdmin]);


    useEffect(() => {
        const validateTeamUsers = async () => {
          if (teamMemberName.trim() === '') {
            setIsValid(false);
            setErrorText('Email cannot be empty.');
            return;
          }
          const regex = /^[Yya-zA-Z0-9._%+-]+@(mnscorp\.net)$/;
          const isValidEmail = validateEmail(teamMemberName, regex);

          if(!isValidEmail) {
            setIsValid(false);
            setErrorText('Invalid Email. Please enter a valid one. and match Regex');
            return;
          }
          else {
            setIsValid(true);
            setErrorText('');
          }
     
          const isValidTeamName = await checkTeamName(teamMemberName, accessToken);
     
          if (isValidTeamName) {
            setIsValid(true);
            setErrorText('');
          } else {
            setIsValid(false);
            setErrorText('Invalid Email Id. Please enter a valid one.');
          }
        };
     
        validateTeamUsers();
      }, [teamMemberName, accessToken]);

    const clearTeamMemberFields = () => {
        setTeamMemberName('');
        setTeamMemberRead(false);
        setTeamMemberWrite(false);
        setTeamMemberAdmin(false);
        setEditIndex(null);
        setChangesMade(false);
        setAddChangesMade(false);
    };

    const handleAddOrUpdateTeamMember = () => {
        if (teamMemberName.trim() === '') {
            // Handle validation or show an alert
            return;
        }

        if (editIndex !== null) {
            // Edit existing team member
            handleEditTeamMember(editIndex, {
                email: teamMemberName,
                read: teamMemberRead,
                write: teamMemberWrite,
                admin: teamMemberAdmin,
            });
        } else {
            // Add new team member
            handleAddTeamMember({
                email: teamMemberName,
                read: teamMemberRead,
                write: teamMemberWrite,
                admin: teamMemberAdmin,
            });
        }

        clearTeamMemberFields();
    };

    const checkTeamName = async (teamName: string, token: any) => {
        try {
            const checkAdJson = await fetchAzureADUsers(teamName, token);
            const data = await checkAdJson;
            return data; // Return true if the Name exi and Email exists
        } catch (error) {
            console.error('Error checking Team Member email:', error);
            return false; // Return false if an error occurs (consider handling errors appropriately)
        }
    };

    const handleEditClick = (index: number) => {
        const selectedTeamMember = teamMembers[index];
        setTeamMemberName(selectedTeamMember.email);
        setTeamMemberRead(selectedTeamMember.read);
        setTeamMemberWrite(selectedTeamMember.write);
        setTeamMemberAdmin(selectedTeamMember.admin);
        setEditIndex(index);
        setOpenDialog(true);
    };

    const handleRemoveClick = (index: number) => {
        handleRemoveTeamMember(index);
        clearTeamMemberFields();
    };

    return (
        <div>
            <h3>{TEAM_MEMBER_INFO}</h3>
            <p>{HELPER_TEXT_TEAM_MEMBER_INFO}</p>
            <div>
                {teamMembers.map((member, index) => (
                    <div key={index}>
                        {`${member.email} - Read: ${member.read}, Write: ${member.write}, Admin: ${member.admin}`}
                        <IconButton color='primary' onClick={() => handleEditClick(index)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton color='primary' onClick={() => handleRemoveClick(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                ))}
            </div>

            <Button color='primary' variant='contained' onClick={() => setOpenDialog(true)}>
                Add Team Member
            </Button>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Typography variant='h6'>
                            {editIndex !== null ? 'Edit Team Member' : 'Add Team Member'}
                        </Typography>
                        <IconButton color='primary' onClick={() => setOpenDialog(false)}>
                            <CancelIcon />
                        </IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Team's Email Address"
                                value={teamMemberName}
                                variant='outlined'
                                margin='normal'
                                fullWidth
                                onChange={(e) => setTeamMemberName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={<Checkbox checked={teamMemberRead} onChange={() => setTeamMemberRead(!teamMemberRead)} />}
                                label="Read Access"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={<Checkbox checked={teamMemberWrite} onChange={() => setTeamMemberWrite(!teamMemberWrite)} />}
                                label="Write Access"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={<Checkbox checked={teamMemberAdmin} onChange={() => setTeamMemberAdmin(!teamMemberAdmin)} />}
                                label="Admin Access"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <IconButton color='primary' onClick={handleAddOrUpdateTeamMember} disabled={!changesMade && !addChangesMade || !isValid}>
                        <AddIcon />
                    </IconButton>
                    {errorText && <Typography variant="caption" color="error">{errorText}</Typography>}
                </DialogActions>
            </Dialog>
        </div>
    );
};