import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, TextField, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { fetchAzureADUsers, microsoftAzureToken, validateEmail } from "../api";
import CloseIcon from '@material-ui/icons/Close';
import { ENABLE_MAILBOX, EXISTING_YACCOUNT_ID, HELPER_TEXT_EXISTING_YACCOUNT_ID, YACCOUNT_SECRET_KEYNAME, HELPER_TEXT_YACCOUNT_SECRET_KEYNAME } from '../../../../constants/ecosystemBuilderCreationConstants'
import AddIcon from '@material-ui/icons/Add';


interface ServiceAccountProps {
    handleYaccountId: (_: any) => void;
    handleYaccountSecretKeyName: (_: any) => void;
    yaccountId: string;
    yaccountSecretKey: string;
    enableServiceAccount: boolean;
    handleEnableServiceAccount: (_: any) => void;
    handleEnableMailBox: (_: any) => void;
    enableMailBox: boolean;
    setEnableServiceAccount: (_: any) => void;
}

const ServiceAccount: React.FC<ServiceAccountProps> = ({
    handleEnableMailBox,
    enableMailBox,
    handleYaccountId,
    yaccountId,
    handleYaccountSecretKeyName,
    yaccountSecretKey,
    enableServiceAccount,
    setEnableServiceAccount,

}) => {
    const accessToken = microsoftAzureToken();
    const [isValid, setIsValid] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>('');
    const [combinedError, setCombinedError] = useState<string>('');
    const [errorYaccountKeyName, setErrorYaccountKeyName] = useState<string>('');
    const [isYaccountKeyNameValid, setYaccountKeyNameValid] = useState<boolean>(false); // New state for error text
    const [showBoxPopup, setShowBoxPopup] = useState<boolean>(false);


    useEffect(() => {
        setErrorText('');
        setIsValid(false);
        const validateTeamUsers = async () => {
            let checkDomain = yaccountId;
            if (checkDomain.trim() === '') {
                setIsValid(false);
                setErrorText('Yaccount Id cannot be empty.');
                return;
            }
            const regex = /^[Yy0-9._%+-]+@(mnscorp\.net)$/;
            const isValidEmail = validateEmail(checkDomain, regex);

            if (!isValidEmail) {
                setIsValid(false);
                setErrorText('Invalid Yaccount Id. Please enter a valid one. and match Regex');
                return;
            }
            else {
                setIsValid(true);
                setErrorText('');
            }

            const isValidResourceOwner = await checkYaccount(checkDomain, accessToken);
            if (isValidResourceOwner) {
                setIsValid(true);
                setErrorText('');
            } else {
                setIsValid(false);
                setErrorText('Invalid Yaccount Id. Please enter a valid one.');
            }
        };

        validateTeamUsers();
    }, [yaccountId, accessToken]);


    useEffect(() => {
        setErrorYaccountKeyName('');
        setYaccountKeyNameValid(false);
        const checkSecretKey = validateYAccountSecretKey(yaccountSecretKey);

        if (!checkSecretKey) {
            setYaccountKeyNameValid(false);
            setErrorYaccountKeyName('Invalid Yaccount Secret Key. Please enter a valid one.');
        }
        else {
            setYaccountKeyNameValid(true);
            setErrorYaccountKeyName('');
        }


    }, [yaccountSecretKey]);


    useEffect(() => {
        if (enableServiceAccount === false) {
            setShowBoxPopup(true);
        } else {
            setShowBoxPopup(false);
        }
    }, [enableServiceAccount]);



    const checkYaccount = async (teamName: string, token: any) => {
        try {
            const checkAdJson = await fetchAzureADUsers(teamName, token);
            const data = await checkAdJson;
            return data; // Return true if the Name exi and Email exists
        } catch (error) {
            console.error('Error checking Team Member email:', error);
            return false; // Return false if an error occurs (consider handling errors appropriately)
        }
    };

    const validateYAccountSecretKey = (secretKey: string) => {
        // Add your Yaccount Secret Key validation logic here
        // Example: Check if it doesn't contain "ghp"
        return !secretKey.toLowerCase().includes('ghp');
    };

    // const handleMailBoxPopupSubmit = () => {
    //     setShowBoxPopup(false);
    // };

    const handleMailBoxPopupSubmit = () => {
        if (yaccountId.trim() !== '' && yaccountSecretKey.trim() !== '' && isValid && isYaccountKeyNameValid  ) {
            setShowBoxPopup(false);
            setCombinedError('Enter both the fields')
        }
      };

    const handleYaccountOnClose= () => {
        if (yaccountId.trim() == '' && yaccountSecretKey.trim() === '') {
            setShowBoxPopup(false);
          }
    };

    return (
        <>

            {enableServiceAccount && (
                <FormControlLabel
                    control={
                        <Checkbox
                            id="enableMailBox"
                            value={enableMailBox}
                            checked={enableMailBox}
                            onChange={handleEnableMailBox}
                        />
                    }
                    label={ENABLE_MAILBOX}
                />
            )}
            {!enableServiceAccount && (
                <Dialog open={showBoxPopup} 
                // onClose={() => setShowBoxPopup(false)}
                onClose={handleYaccountOnClose}
                >
                    <DialogTitle>Yaccount Information</DialogTitle>
                    <DialogContent>
                        <TextField
                            label={EXISTING_YACCOUNT_ID}
                            value={yaccountId}
                            onChange={handleYaccountId}
                            fullWidth
                            margin="normal"
                            helperText={HELPER_TEXT_EXISTING_YACCOUNT_ID}
                        />
                        {yaccountId && errorText && <Typography variant="caption" color="error">{errorText}</Typography>}
                        <TextField
                            label={YACCOUNT_SECRET_KEYNAME}
                            value={yaccountSecretKey}
                            onChange={handleYaccountSecretKeyName}
                            fullWidth
                            margin="normal"
                            helperText={HELPER_TEXT_YACCOUNT_SECRET_KEYNAME}
                        />
                        {yaccountSecretKey && errorYaccountKeyName && <Typography variant="caption" color="error">{errorYaccountKeyName}</Typography>}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            if (yaccountId.length === 0 && yaccountSecretKey.length === 0) {
                                setEnableServiceAccount(false);
                                setShowBoxPopup(false);
                            }
                        }} color="primary" style={{ position: 'absolute', right: 8, top: 8 }}>
                            <CloseIcon />
                        </Button>
                        <IconButton color='primary' onClick={handleMailBoxPopupSubmit} disabled={!isValid && !isYaccountKeyNameValid}>
                            <AddIcon />
                        </IconButton>
                        {errorText && <Typography variant="caption" color="error">{combinedError}</Typography>} 
                        {/* <Button onClick={handleMailBoxPopupSubmit} color="primary">
                            Submit
                        </Button> */}
                    </DialogActions>
                </Dialog>
            )}
            <div>
                {
                    !enableServiceAccount && (yaccountId || yaccountSecretKey) && (
                        <>
                            <Tooltip title={`Click Here to Modify Yaccount ID`}>
                                <Chip
                                    label={`Yaccount ID: ${yaccountId} | Yaccount Secret Key: ${yaccountSecretKey}`}
                                    clickable
                                    onClick={() => setShowBoxPopup(true)} // Show popup on chip click
                                />
                            </Tooltip>
                        </>

                    )}
            </div>
        </>
    );

}

export default ServiceAccount;