import { scaffolderPlugin } from '@internal/plugin-scaffolder';

import { createScaffolderFieldExtension } from '@internal/plugin-scaffolder-react';
import { ADvalidation, AzureADgroupExtension } from './AzureAdgroupValidate';

export const AzureADgroupFieldValidateExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'AzureADgroup',
    component: AzureADgroupExtension,
    validation: ADvalidation,
  }),
);
