import { scaffolderPlugin } from '@internal/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@internal/plugin-scaffolder-react';
import {
  ServiceAccountsList,
  ServiceAccountValidation,
} from './ServiceAccountsListExtension';

export const ConfluentCloudServiceAccountsListFieldExtension =
  scaffolderPlugin.provide(
    createScaffolderFieldExtension({
      name: 'cc-list-serviceaccounts',
      component: ServiceAccountsList,
      validation: ServiceAccountValidation,
    }),
  );
