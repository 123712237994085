import React from 'react';
import type { FieldValidation } from '@rjsf/utils';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';
import { useAsync } from 'react-use';
import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { makeFieldSchemaFromZod } from '@internal/plugin-scaffolder';
import { z } from 'zod';

let uiSchemaOptions: any;

type ProductPickerOptions = {
  id: string;
  uniqueId: string;
  contentTypeId: string;
  productName: string;
  productGroup: string;
  productPortfolio: string;
  businessPlatform: string;
  productDescription: string;
  ownerEmail: string;
};

type ProductPickerOptionsKeys = keyof ProductPickerOptions;

function compareStrings(a: string, b: string) {
  a = a.toLowerCase();
  b = b.toLowerCase();
  return a < b ? -1 : a > b ? 1 : 0;
}

function capitalizeField(a: string) {
  a = a.replace(/([A-Z])/g, ' $1').trim();
  return a.charAt(0).toUpperCase() + a.slice(1);
}

export const ProductPicker = ({
  onChange,
  schema,
  rawErrors,
  formData,
  required,
  uiSchema,
  idSchema,
}: any) => {
  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);
  const { value, loading, error } = useAsync(async (): Promise<any> => {
    const baseUrl = await discoveryApi.getBaseUrl('sharepoint');
    return await fetchApi
      .fetch(`${baseUrl}/v1/products`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
      .then(res => res.json());
  });

  if (error)
    return (
      <Alert severity="error">
        Error could not fetch Product Structure List.
      </Alert>
    );

  const productList: ProductPickerOptions[] = [];

  try {
    value?.ListData?.Row?.map((data: any) =>
      productList.push({
        id: data.ID,
        uniqueId: data.UniqueId,
        contentTypeId: data.ContentTypeId,
        productName: data.ProductID,
        productGroup: data.field_2,
        productPortfolio: data.field_3,
        businessPlatform: data.field_4,
        productDescription: data.field_5,
        ownerEmail: data.field_6,
      }),
    );
  } catch (err: any) {
    console.error(err);
    <Alert severity="error">
      Error Could not fetch the Product structure list.
    </Alert>;
  }
  // const productPortfolioSortList: ProductPickerOptions[] = [...productList];
  const productNameSortList: ProductPickerOptions[] = [...productList];

  // const productPortfolioProps = {
  //   options: productPortfolioSortList.sort((a: ProductPickerOptions,b: ProductPickerOptions)=>{
  //     return compareStrings(a.productPortfolio, b.productPortfolio);
  //   }),
  //   groupBy: (option: ProductPickerOptions) => /[A-Za-z]/.test(option.productPortfolio.charAt(0).toUpperCase()) ? option.productPortfolio.charAt(0).toUpperCase() : "#",
  //   getOptionLabel: (option: ProductPickerOptions) => option.productPortfolio,
  //   getOptionSelected: (option: ProductPickerOptions, value: ProductPickerOptions) => option.productPortfolio === value.productPortfolio,
  // };

  const productNameProps = {
    options: productNameSortList.sort(
      (a: ProductPickerOptions, b: ProductPickerOptions) => {
        return compareStrings(a.productName, b.productName);
      },
    ),
    groupBy: (option: ProductPickerOptions) =>
      /[A-Za-z]/.test(option.productName.charAt(0).toUpperCase())
        ? option.productName.charAt(0).toUpperCase()
        : '#',
    getOptionLabel: (option: ProductPickerOptions) => option.productName,
    getOptionSelected: (
      option: ProductPickerOptions,
      value: ProductPickerOptions,
    ) => option.productName === value.productName,
  };

  const data = formData
    ? formData.hasOwnProperty('productName')
      ? formData
      : null
    : null;

  const [selected, setSelected] = React.useState<ProductPickerOptions | null>(
    data,
  );

  const onSelect = (_: any, value: ProductPickerOptions | null) => {
    onChange(value || null);
    if (mandateFields) {
      setVisibleMandateFields(
        mandateFields.filter(
          (field: ProductPickerOptionsKeys) => value && !value[field],
        ),
      );
    }
    setSelected(value);
  };

  const onChangeTextHandle = (
    field: ProductPickerOptionsKeys,
    value: string,
  ) => {
    if (selected) {
      let overrideSelected = selected;
      overrideSelected[field] = value;
      setSelected(overrideSelected);
      onChange(overrideSelected);
    }
  };

  // todo: throw error if fields not within keys
  let overrideFields: ProductPickerOptionsKeys[] = uiSchema['ui:options']
    ?.override
    ? (uiSchema['ui:options'].override as ProductPickerOptionsKeys[])
    : [];
  let showFields: ProductPickerOptionsKeys[] = uiSchema['ui:options']?.show
    ? (uiSchema['ui:options'].show as ProductPickerOptionsKeys[])
    : [];
  let mandateFields: ProductPickerOptionsKeys[] = uiSchema['ui:options']
    ?.mandate
    ? (uiSchema['ui:options'].mandate as ProductPickerOptionsKeys[])
    : [];
  let selectorFields: ProductPickerOptionsKeys[] = uiSchema['ui:options']
    ?.selector
    ? (uiSchema['ui:options'].selector as ProductPickerOptionsKeys[])
    : [];
  if (
    !selectorFields.includes('productPortfolio') &&
    !selectorFields.includes('productName')
  ) {
    selectorFields = ['productPortfolio', 'productName'];
  }

  uiSchemaOptions = uiSchema['ui:options'];

  if (showFields && mandateFields) {
    const showMandateFields: ProductPickerOptionsKeys[] = showFields.filter(
      (field: ProductPickerOptionsKeys) => mandateFields.includes(field),
    );
    if (overrideFields) {
      overrideFields = overrideFields.concat(showMandateFields);
    } else {
      overrideFields = [...showMandateFields];
    }
  }

  if (overrideFields && showFields) {
    showFields = showFields.filter(
      (field: ProductPickerOptionsKeys) => !overrideFields.includes(field),
    );
  }

  if (overrideFields && mandateFields) {
    mandateFields = mandateFields.filter(
      (field: ProductPickerOptionsKeys) => !overrideFields.includes(field),
    );
  }

  const [visibleMandateFields, setVisibleMandateFields] = React.useState<
    ProductPickerOptionsKeys[]
  >(
    mandateFields
      ? mandateFields.filter(
          (field: ProductPickerOptionsKeys) => data && !data[field],
        )
      : [],
  );

  let widthAppName;
  if (selectorFields && selectorFields.includes('productName')) {
    widthAppName = '100%';
  }

  // todo: fix required
  return (
    <>
      <FormControl
        margin="normal"
        style={{ flexDirection: 'row' }}
        required={required}
        error={rawErrors?.length > 0}
      >
        {selectorFields && selectorFields.includes('productName') && (
          <Autocomplete
            id={idSchema?.$id}
            onChange={onSelect}
            loading={loading}
            {...productNameProps}
            clearOnEscape
            value={selected ? selected : null}
            style={{ width: widthAppName }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label={schema.title ? schema.title : 'Product Name'}
                variant="standard"
                helperText={schema.description ? schema.description : ''}
              />
            )}
          />
        )}

        {schema.description && (
          <FormHelperText>{schema.description}</FormHelperText>
        )}
      </FormControl>

      {overrideFields &&
        overrideFields.length > 0 &&
        overrideFields.map(field => (
          <FormControl margin="normal" required key={field}>
            <TextField
              margin="normal"
              label={capitalizeField(field)}
              value={selected ? selected[field] : ''}
              required
              onChange={({ target: { value } }) =>
                onChangeTextHandle(field, value)
              }
            />
          </FormControl>
        ))}

      {visibleMandateFields &&
        visibleMandateFields.length > 0 &&
        visibleMandateFields.map(field => (
          <FormControl
            margin="normal"
            required
            key={field}
            error={rawErrors?.length > 0}
          >
            <TextField
              margin="normal"
              label={capitalizeField(field)}
              required
              onChange={({ target: { value } }) =>
                onChangeTextHandle(field, value)
              }
            />
          </FormControl>
        ))}

      {showFields &&
        showFields.length > 0 &&
        showFields.map(field => (
          <FormControl margin="normal" key={field}>
            <TextField
              margin="normal"
              label={capitalizeField(field)}
              disabled
              InputProps={{
                readOnly: true,
              }}
              value={selected ? selected[field] : ''}
            />
          </FormControl>
        ))}
    </>
  );
};

export const productStructureValidation = (
  value: string,
  validation: FieldValidation,
) => {
  if (
    uiSchemaOptions?.validate &&
    JSON.parse(JSON.stringify(value))?.productName == null
  ) {
    validation.addError(
      `Product Name is required, Please select a product name.`,
    );
  }
};

export const ProductStructureExtensionWithOptionsSchema =
  makeFieldSchemaFromZod(
    z.object({}),
    z.object({
      override: z
        .array(
          z.enum([
            'id',
            'uniqueId ',
            'contentTypeId',
            'productName ',
            'productGroup',
            'productPortfolio',
            'businessPlatform',
            'ownerEmail',
          ]),
        )
        .optional()
        .describe(
          'Allow user override Selected Field in the form. Defaults to []',
        ),
      show: z
        .array(
          z.enum([
            'id',
            'uniqueId ',
            'contentTypeId',
            'productName ',
            'productGroup',
            'productPortfolio',
            'businessPlatform',
            'ownerEmail',
          ]),
        )
        .optional()
        .describe('Allow user show Selected Field in the form. Defaults to []'),
      mandate: z
        .array(
          z.enum([
            'id',
            'uniqueId ',
            'contentTypeId',
            'productName ',
            'productGroup',
            'productPortfolio',
            'businessPlatform',
            'ownerEmail',
          ]),
        )
        .optional()
        .describe(
          'Allow user mandate Selected Field in the form. Defaults to []',
        ),
      validate: z
        .boolean()
        .optional()
        .describe(
          'Whether to validate product structure is passed as a parameter in the form. Defaults to false',
        ),
    }),
  ).schema;
