import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { UnifiedThemeOptions } from "@backstage/theme";

const useStyles = makeStyles<UnifiedThemeOptions>((theme) => ({
  logo: {
    position: "relative",
    width: "300px",
    display: "block",
    margin: "-22px auto",
  },
  searchBar: {
    position: "relative",
  },
  search: {
    background: theme.palette.type === "dark" ? "#04364A" : "#FFFFFF",
    border: theme.palette.type === "dark" ? "#4f5b62" : "#FFFFFF",
    color: theme.palette.type === "dark" ? "#FFFFFF" : "#4f5b62",
    display: "block",
    height: "60px",
    width: "1000px",
    borderRadius: "30px",
    padding: "0 10px",
    margin: "15px auto 30px auto",
    outline: "3px solid #F49F0A",
    "&:hover": {
      background: theme.palette.type === "dark" ? "#4f5b62" : "#FFFFFF",
      outlineColor: theme.palette.type === "dark" ? "#4f5b62" : "#4273b7",
    },
    "&:focus": {
      background: theme.palette.type === "dark" ? "#4f5b62" : "#FFFFFF",
    },
  },
}));

export const Search = () => {
  const classes = useStyles();
  const [search, setSearch] = useState("");

  return (
    <section id='home-page-search'>
      <img
        src='/cloud9.png'
        className={classes.logo}
        alt='Cloud9 Muziris Logo'
      />
      <form
        noValidate
        autoComplete='on'
        onChange={(e: any) => setSearch(e.target.value)}
        onSubmit={(e) => {
          e.preventDefault();
          window.location.replace(
            `${window.location.origin}/search?query=${search}`
          );
        }}
        className={classes.searchBar}
      >
        <input
          className={classes.search}
          type='text'
          id='tour-search'
          placeholder='Search Muziris'
        />
      </form>
    </section>
  );
};
