import {
    scaffolderPlugin,
} from '@internal/plugin-scaffolder';
import {createScaffolderFieldExtension} from '@internal/plugin-scaffolder-react';
import { UpdateEcosystemBuilder } from './EcosystemBuilderUpdate';

export const UpdateEcosystemBuilderExtension = scaffolderPlugin.provide(
createScaffolderFieldExtension({
    name: 'EcosystemBuilderUpdate',
    component: UpdateEcosystemBuilder,
}),
);
