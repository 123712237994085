import {
  ANNOTATION_EDIT_URL,
  RELATION_MEMBER_OF,
  UserEntity,
} from '@backstage/catalog-model';
import {
  Avatar,
  InfoCard,
  InfoCardVariants,
  Link,
} from '@backstage/core-components';
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  EntityRefLinks,
  getEntityRelations,
  useEntity,
} from '@backstage/plugin-catalog-react';

import Alert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import GroupIcon from '@material-ui/icons/Group';

import PersonIcon from '@material-ui/icons/Person';
import React from 'react';
import WorkIcon from '@material-ui/icons/Work';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import SupervisorAccountSharpIcon from '@material-ui/icons/SupervisorAccountSharp';
import BusinessSharpIcon from '@material-ui/icons/BusinessSharp';
import RecentActorsRoundedIcon from '@material-ui/icons/RecentActorsRounded';
import GitHubIcon from '@material-ui/icons/GitHub';
import { LinksGroup } from './Meta';

const CardTitle = (props: { title?: string }) =>
  props.title ? (
    <Box display="flex" alignItems="center">
      <PersonIcon fontSize="inherit" />
      <Box ml={1}>{props.title}</Box>
    </Box>
  ) : null;


export const ProfileCardComponent = (props: {
  variant?: InfoCardVariants;
  showLinks?: boolean;
}) => {
  const { entity: user } = useEntity<UserEntity>();

  if (!user) {
    return <Alert severity="error">User not found</Alert>;
  }


  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: '12px',
      backgroundColor: '#000',
      color: '#fff',
      padding: theme.spacing(0.5),
      marginTop: '0px',
      textAlign: 'center',
      borderRadius: '4px',
    },
  }))(Tooltip);

  const entityMetadataEditUrl =
    user.metadata.annotations?.[ANNOTATION_EDIT_URL];


  const {
    metadata: { name: metaName, description, links, annotations },
    spec: { profile },
  } = user;
  const displayName = profile?.displayName ?? metaName;
  const emailHref = profile?.email ? `mailto:${profile.email}` : undefined;
  const company = annotations?.['mnscorp.net/company'];
  const employeeId = annotations?.['mnscorp.net/employee-id'];
  const github = annotations?.['mnscorp.net/github-username'];
  const memberOfRelations = getEntityRelations(user, RELATION_MEMBER_OF, {
    kind: 'Group',
  });
  const role = annotations?.['mnscorp.net/role'];
  const manager = annotations?.['mnscorp.net/manager'];
  const jobFunction = annotations?.['mnscorp.net/job-function'];


  return (
    <InfoCard
      title={<CardTitle title={displayName} />}
      subheader={description}
      variant={props.variant}
      action={
        <>
          {entityMetadataEditUrl && (
            <IconButton
              aria-label="Edit"
              title="Edit Metadata"
              component={Link}
              to={entityMetadataEditUrl}
            >
              <EditIcon />
            </IconButton>
          )}
        </>
      }
    >
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12} sm={2} xl={1}>
          <Avatar displayName={displayName} picture={profile?.picture} />
        </Grid>

        <Grid item md={10} xl={11}>
          <List>
            {profile?.email && (
              <ListItem>
                <ListItemIcon>
                  <CustomTooltip title="Email">
                    <EmailIcon />
                  </CustomTooltip>
                </ListItemIcon>
                <ListItemText>
                  <Link to={emailHref ?? ''}>{profile.email}</Link>
                </ListItemText>
              </ListItem>
            )}

            {jobFunction && (<ListItem>
              <ListItemIcon>
                <CustomTooltip title="Area">
                  <WorkIcon />
                </CustomTooltip>
              </ListItemIcon>
              <ListItemText>
                <ListItemText primary={jobFunction ? jobFunction : (
                  <Typography variant="body2" color="textSecondary" style={{ fontStyle: 'italic' }}>
                    Unavailable
                  </Typography>
                )} />
              </ListItemText>
            </ListItem>)}

            {role && (<ListItem>
              <ListItemIcon>
                <CustomTooltip title="Role">
                  <AssignmentIndIcon />
                </CustomTooltip>
              </ListItemIcon>
              <ListItemText>
                <ListItemText primary={role ? role : (
                  <Typography variant="body2" color="textSecondary" style={{ fontStyle: 'italic' }}>
                    Unavailable
                  </Typography>
                )} />
              </ListItemText>
            </ListItem>)}

            {manager && (<ListItem>
              <ListItemIcon>
                <CustomTooltip title="Manager">
                  <SupervisorAccountSharpIcon />
                </CustomTooltip>
              </ListItemIcon>
              <ListItemText>
                <ListItemText primary={manager ? manager : (
                  <Typography variant="body2" color="textSecondary" style={{ fontStyle: 'italic' }}>
                    Unavailable
                  </Typography>
                )} />
              </ListItemText>
            </ListItem>)}

            {company && (<ListItem>
              <ListItemIcon>
                <CustomTooltip title="Company">
                  <BusinessSharpIcon />
                </CustomTooltip>
              </ListItemIcon>
              <ListItemText>
                <ListItemText primary={
                  company ? company : (
                    <Typography variant="body2" color="textSecondary" style={{ fontStyle: 'italic' }}>
                      Unavailable
                    </Typography>
                  )} />
              </ListItemText>
            </ListItem>)}

            {employeeId && (<ListItem>
              <ListItemIcon>
                <CustomTooltip title="Employee Id">
                  <RecentActorsRoundedIcon />
                </CustomTooltip>
              </ListItemIcon>
              <ListItemText>
                <ListItemText primary={employeeId ? employeeId : (
                  <Typography variant="body2" color="textSecondary" style={{ fontStyle: 'italic' }}>
                    Unavailable
                  </Typography>
                )} />
              </ListItemText>
            </ListItem>)}

            {github && (<ListItem>
              <ListItemIcon>
                <CustomTooltip title="GitHub">
                  <GitHubIcon />
                </CustomTooltip>
              </ListItemIcon>
              <ListItemText>
                {github ? (
                  <Link to={`https://github.com/${github}`} target="_blank" >
                    {github}
                  </Link>
                ) : (
                  <Typography variant="body2" color="textSecondary" style={{ fontStyle: 'italic' }}>
                    Unavailable
                  </Typography>
                )}
              </ListItemText>
            </ListItem>)}

            <ListItem>
              <ListItemIcon>
                <CustomTooltip title="Member of">
                  <GroupIcon />
                </CustomTooltip>
              </ListItemIcon>
              <ListItemText>
                <EntityRefLinks
                  entityRefs={memberOfRelations}
                  defaultKind="Group"
                />
              </ListItemText>
            </ListItem>

            {props?.showLinks && <LinksGroup links={links} />}
          </List>
        </Grid>
      </Grid>
    </InfoCard >
  );
}
