import {
  createComponentExtension,
  createPlugin,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const catalogImportAlphaPlugin = createPlugin({
  id: 'catalog-import-alpha',
  featureFlags: [{ name: 'catalog-import-alpha' }],
  routes: {
    root: rootRouteRef,
  },
});

export const CreateComponent = catalogImportAlphaPlugin.provide(
  createComponentExtension({
    name: 'CreateComponent',
    component: {
      lazy: () =>
        import('./components/ImportAlphaComponent').then(
          m => m.CatalogImportAlpha,
        ),
    },
  }),
);
