import { scaffolderPlugin } from '@internal/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@internal/plugin-scaffolder-react';
import {
  ProductStructureExtensionWithOptionsSchema,
  ProductPicker,
  productStructureValidation,
} from './ProductStructureExtension';

export const ProductStructureListExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'Product',
    component: ProductPicker,
    validation: productStructureValidation,
    schema: ProductStructureExtensionWithOptionsSchema,
  }),
);
