import React from 'react';
import { WelcomeTitle } from '../WelcomeTitle';
import { IconButton, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { appThemeApiRef, useApi } from '@backstage/core-plugin-api';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import NightsStayIcon from '@material-ui/icons/NightsStay';
import NotificationsIcon from '@material-ui/icons/Notifications';

export const Header = ({ theme }: any) => {
  const appThemeApi = useApi(appThemeApiRef);
  const selectedTheme: string = theme ? theme : null;

  const useStyles = makeStyles({
    root: {
      background: 'none',
      padding: '15px 30px',
      marginBottom: '40px',
    },
    title: {
      color: selectedTheme === 'dark' ? '#F49F0A' : '#00283D',
      fontSize: '24px',
      fontWeight: 400,
      margin: 0,
    },
    theme: {
      border: '0',
      background: 'none',
      cursor: 'pointer',
      color: selectedTheme === 'dark' ? '#ffffff' : '#000000',
      position: 'absolute',
      right: '30px',
      top: '15px',
      '&:hover': {
        transform: 'scale(1.25)',
        transitionDelay: '.1s',
      },
    },
    settingsIcon: {
      border: '0',
      background: 'none',
      cursor: 'pointer',
      color: selectedTheme === 'dark' ? '#F49F0A' : '#00283D',
      position: 'absolute',
      right: '20px',
      top: '15px',
      '&:hover': {
        transform: 'scale(1.25)',
        transitionDelay: '.1s',
      },
    },
    visuallyHidden: {
      left: '-100vw',
      position: 'absolute',
    },
    settings: {
      position: 'absolute',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
      background: 'rgba(0, 0, 0, .95)',
      display: 'block',
      zIndex: 2000,
      padding: '30px',
      '& *': {
        color: '#ffffff',
      },
      '& button': {
        background: 'none',
        border: 0,
        cursor: 'pointer',
        paddingLeft: 0,
        borderRadius: '50',
      },
      '& button:hover, & button:focus': {
        textDecoration: 'underline',
        borderRadius: '50',
      },
    },
    notification: {
      border: '0',
      background: 'none',
      cursor: 'pointer',
      color: selectedTheme === 'dark' ? '#F49F0A' : '#00283D',
      position: 'absolute',
      right: '75px',
      top: '15px',
    },
    close: {
      border: '0',
      background: 'none',
      cursor: 'pointer',
      color: '#ffffff',
      position: 'absolute',
      right: '30px',
      top: '30px',
      '&:hover': {
        transform: 'scale(1.25)',
        transitionDelay: '.1s',
      },
      '& svg': {
        fontSize: '60px',
      },
    },
    selected: {
      textDecoration: 'underline',
    },
    button: {},
  });

  const classes = useStyles();

  const handleSelectTheme = () => {
    if (appThemeApi.getActiveThemeId() === 'dark') {
      appThemeApi.setActiveThemeId('light');
    } else {
      appThemeApi.setActiveThemeId('dark');
    }
  };

  return (
    <>
      <div className={classes.root}>
        <Typography
          className={classes.title}
          align="left"
          variant="h1"
          component="h2"
        >
          <WelcomeTitle />
        </Typography>
        <IconButton
          className={classes.notification}
          aria-label="theme"
          style={{ borderRadius: '50' }}
        >
          {appThemeApi.getActiveThemeId() === 'dark' ? (
            <NotificationsIcon style={{ color: 'whitesmoke' }} />
          ) : (
            <NotificationsIcon />
          )}
        </IconButton>

        <Tooltip
          title={
            'Switch between Dark and Light mode (currently ' +
            appThemeApi.getActiveThemeId() +
            '  mode)'
          }
        >
          <IconButton
            className={classes.settingsIcon}
            aria-label="theme"
            onClick={() => handleSelectTheme()}
            style={{ borderRadius: '50' }}
          >
            {appThemeApi.getActiveThemeId() === 'dark' ? (
              <NightsStayIcon style={{ color: 'whitesmoke' }} />
            ) : (
              <WbSunnyIcon style={{ color: '#F49F0A' }} />
            )}
          </IconButton>
        </Tooltip>
      </div>
    </>
  );
};
