import { scaffolderPlugin } from '@internal/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@internal/plugin-scaffolder-react';
import {
  TimeStampExtensionWithOptionsSchema,
  TimeStampExtension,
} from './TimeStampExtension';

export const TimeStampFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'timestamp',
    component: TimeStampExtension,
    schema: TimeStampExtensionWithOptionsSchema,
  }),
);
