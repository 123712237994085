import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import {
  InputLabel,
  Select,
  MenuItem,
  TextField,
  capitalize,
  CircularProgress,
} from '@material-ui/core';
import {
  useApi,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';

type topicConfigType = {
  environment: string;
  topicName: string;
  cluster_id: string;
  partitions: string;
  retention_time: string;
  retention_size: string;
  message_max_size: string;
  cleanup_policy: string;
};

type topicConfigTypeKeys = keyof topicConfigType;

export const UpsertTopicConfig = ({
  onChange,
  rawErrors,
  required,
  uiSchema,
}: any) => {
  /** Fields Conditions */
  /** Override :: read User conditon on the data and display the fields  */
  const multiTopics: boolean = uiSchema['ui:options']?.multiTopics
    ? (uiSchema['ui:options'].multiTopics)
    : false;
    
  /** Override :: read User conditon on the data and display the fields  */
  const overrideFields: topicConfigTypeKeys[] = uiSchema['ui:options']?.override
    ? (uiSchema['ui:options'].override as topicConfigTypeKeys[])
    : [];

  /** Disabled :: read User conditon on the data and display the fields  */
  const showFields: topicConfigTypeKeys[] = uiSchema['ui:options']?.show
    ? (uiSchema['ui:options'].show as topicConfigTypeKeys[])
    : [];

  /** state  */
  const [env, setEnv] = React.useState('');
  const [preEnv, setPreEnv] = React.useState<string | undefined>();
  const [backendResponse, setBackendResponse] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [topicList, setTopicList] = React.useState<topicConfigType[]>([]);
  const [selected, setSelected] = React.useState<topicConfigType | null>();
  const [, setMultiSelected] = React.useState<string[] | null>();
  const [displayAlert, setDisplayAlert] = React.useState(false);
  const [apiCallLoader, setApiCallLoader] = React.useState(false);

  /** Backstage Hook Calls  */
  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);

  /** Fetch the Topic Details from backend */
  async function fetchByPostConfluentCloudsMongoDBData(environment: string) {
    try {
      const baseUrl = await discoveryApi.getBaseUrl('confluentcloud');
      fetchApi
        .fetch(`${baseUrl}/topic/config`, {
          headers: {
            environment: environment,
          },
        })
        .then(response => response.json())
        .then(apiResult => setBackendResponse(apiResult));
    } catch (error) {
      throw error;
    }
  }

  /** store the backend response and enable the topic field loader */
  if (
    backendResponse !== undefined &&
    JSON.parse(JSON.stringify(backendResponse))?.documents.length > 0
  ) {
    const tempTopicList: topicConfigType[] = [];
    JSON.parse(JSON.stringify(backendResponse))?.documents?.map((each: any) => {
      tempTopicList.push({
        environment: env,
        topicName: each.topicname,
        cluster_id: each.cluster_id,
        partitions: each.config.partitions,
        retention_time: each.config.retention_time,
        retention_size: each.config.retention_size,
        message_max_size: each.config.message_max_size,
        cleanup_policy: each.config.cleanup_policy,
      });
    });
    if (topicList.length === 0) setTopicList(tempTopicList);
    if (loading) setLoading(false);
    if (apiCallLoader) setApiCallLoader(false);
  } else if (
    backendResponse !== undefined &&
    JSON.parse(JSON.stringify(backendResponse))?.documents.length == 0 &&
    (preEnv === undefined || preEnv !== env)
  ) {
    setDisplayAlert(true);
    setPreEnv(env);
    setApiCallLoader(false);
    setLoading(false);
  }

  /** on change handlers for environment select based field   */
  const handleChange = (event: any) => {
    setEnv(event.target.value as string);
    setBackendResponse(undefined);
    setTopicList([]);
    setSelected(undefined);
    setMultiSelected(undefined);
    setApiCallLoader(true);
    setDisplayAlert(false);
    fetchByPostConfluentCloudsMongoDBData(event.target.value as string);
  };

  /** on change handlers for topic Auto complete dropdown  */
  const onSelect = (_: any, value: topicConfigType | null) => {
    onChange(value || null);
    setSelected(value);
  };

  /** on change handlers for topic Auto complete dropdown  */
  const onMultipleSelect = (_: any, value: topicConfigType[] | null) => {
    const topicNameArray: string[] = []
    value?.forEach(each => topicNameArray.push(each.topicName));
    onChange(topicNameArray || null);
    setMultiSelected(topicNameArray);
  };

  const onChangeTextHandle = (field: topicConfigTypeKeys, value: string) => {
    if (selected) {
      const overrideSelected = selected;
      overrideSelected[field] = value;
      setSelected(overrideSelected);
      onChange(overrideSelected);
    }
  };

  return (
    <>
      <FormControl
        margin="normal"
        style={{ flexDirection: 'row' }}
        required={required}
        error={rawErrors?.length > 0}
      >
        {displayAlert ? (
          <>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              You don't have any topics in <strong>
                {capitalize(env)}
              </strong>{' '}
              this environment —{' '}
              <strong>
                Contact{' '}
                <a
                  href="https://outlook.office.com/?path=/mail/action/compose&to=grp-eventplatform@mnscorp.onmicrosoft.com&subject=BUG:%3Cplease%20fill%20the%20email%20subject%20without%20removing%20tag%3E&body=%3CAdd%20Your%20Request%20here%3E"
                  target="_blank"
                >
                  Confluent Kafka Team
                </a>{' '}
                !{' '}
              </strong>{' '}
              or Select another environment
            </Alert>
          </>
        ) : (
          <></>
        )}
      </FormControl>
      <FormControl
        margin="normal"
        style={{ flexDirection: 'row' }}
        required={required}
        error={rawErrors?.length > 0}
      >
        <InputLabel id="demo-simple-select-label">Environment</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={env}
          label="Environment"
          onChange={handleChange}
          style={{ width: '100%' }}
        >
          <MenuItem value="dev">Non-Production</MenuItem>
          <MenuItem value="prod">Production</MenuItem>
        </Select>
      </FormControl>


      {multiTopics ? (<FormControl
        margin="normal"
        style={{ flexDirection: 'row' }}
        required={required}
        error={rawErrors?.length > 0}
      >
        <Autocomplete
          multiple
          key={env}
          disablePortal
          id="combo-box-demo"
          onChange={onMultipleSelect}
          loading={loading}
          options={topicList}
          getOptionLabel={option => option.topicName.toString()}
          style={{ width: '100%' }}
          renderInput={(params: any) => (
            <TextField {...params} label="Topic Name" />
          )}
        />
        {apiCallLoader ? <CircularProgress /> : <></>}
      </FormControl>) : (<FormControl
        margin="normal"
        style={{ flexDirection: 'row' }}
        required={required}
        error={rawErrors?.length > 0}
      >
        <Autocomplete
          key={env}
          disablePortal
          id="combo-box-demo"
          onChange={onSelect}
          loading={loading}
          options={topicList}
          getOptionLabel={option => option.topicName.toString()}
          style={{ width: '100%' }}
          renderInput={(params: any) => (
            <TextField {...params} label="Topic Name" />
          )}
        />
        {apiCallLoader ? <CircularProgress /> : <></>}
      </FormControl>)}





      {overrideFields &&
        overrideFields.length > 0 &&
        overrideFields.map(field => (
          <FormControl margin="normal" required key={field}>
            <TextField
              margin="normal"
              label={capitalizeField(field)}
              value={selected ? selected[field] : ''}
              required
              onChange={({ target: { value } }) =>
                onChangeTextHandle(field, value)
              }
            />
          </FormControl>
        ))}

      {showFields &&
        showFields.length > 0 &&
        showFields.map(field => (
          <FormControl margin="normal" key={field}>
            <TextField
              margin="normal"
              label={capitalizeField(field)}
              disabled
              InputProps={{
                readOnly: true,
              }}
              value={selected ? selected[field] : ''}
            />
          </FormControl>
        ))}
    </>
  );
};

function capitalizeField(a: string) {
  // eslint-disable-next-line no-param-reassign
  a = a.replace(/([A-Z])/g, ' $1').trim();
  return a.charAt(0).toUpperCase() + a.slice(1);
}

