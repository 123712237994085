/* eslint-disable no-console */
const logger = {
  log: (message: any) => {
    console.log(message);
  },
  error: (error: any) => {
    console.error(error);
  },
  warn: (message: any) => {
    console.warn(message);
  },
  info: (message: any) => {
    console.info(message);
  },
};

export default logger;
