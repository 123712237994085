import React from 'react';
import { EntitySwitch, isComponentType } from '@backstage/plugin-catalog';

import { WebsiteEntityPage } from './WebsiteEntityPage';
import { ServiceEntityPage } from './ServiceEntityPage';
import { defaultEntityPage } from './DefaultEntityPage';

export const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {ServiceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      {WebsiteEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
