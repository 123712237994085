import * as React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

export const Footer = () => {
  return (
    <Box sx={{  py: 4 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={6} sm={3}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <img width={'50%'} src="/D_T_Icon.png" alt="logo" />
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            <div >
              <table>
                <tbody>
                  <tr>
                    <th>Docs</th>
                  </tr>
                  <tr>
                    <td>
                      &nbsp;
                      <a href="/docs/default/Component/Muziris/">
                        What is Muziris?
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      &nbsp;
                      <a href="/docs/default/Component/Muziris/features/software-catalog/">
                        Getting Started
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      &nbsp;<a href="/catalog-import">Software Catalogue</a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      &nbsp;
                      <a href="/create?filters%5Bkind%5D=template&filters%5Buser%5D=all">
                        Use a Template
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Grid>
          <Grid item xs={6} sm={3}>
            <div >
              <table>
                <tbody>
                  <tr>
                    <th>Community</th>
                  </tr>
                  <tr>
                    <td>
                      &nbsp;
                      <a href="https://teams.microsoft.com/l/channel/19%3a563b65feacc5495cbaee08d97cfd226c%40thread.tacv2/Muziris%2520Support?groupId=530724a1-7729-4242-a76c-2d6e6d77b136&amp;tenantId=bd5c6713-7399-4b31-be79-78f2d078e543">
                        Teams Channel
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      &nbsp;
                      <a href="https://github.com/DigitalInnovation/technology-muziris/discussions">
                        Github Discussions
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      &nbsp;
                      <a href="/docs/default/Component/Muziris/getting-started/getting-started/">
                        Designing for Muziris
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Grid>
          <Grid item xs={6} sm={3}>
            <div >
              <table>
                <tbody>
                  <tr>
                    <th>More</th>
                  </tr>
                  <tr>
                    <td>
                      &nbsp;
                      <a href="/docs/default/Component/Muziris/architecture/cloud-native/">
                        Cloud Native
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      &nbsp;
                      <a href="https://github.com/DigitalInnovation/Muziris/stargazers">
                        Github Stars
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Grid>
        </Grid>
        <Box sx={{ mt: 5 }}>
          <Typography variant="body2" mt="0.5em" align="center" noWrap>
            Baked with ❤️ at Marks and Spencer by Integration Frameworks
          </Typography>
          <Typography variant="body2" mt="0.5em" align="center" noWrap>
            Muziris is part of M&S Cloud 9 Initiative, powered by Backstage.io
          </Typography>
          <Typography variant="body2" mt="0.5em" align="center" noWrap>
            Muziris Version 2.0.0 , Backstage Version 1.10.1
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};
