/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import { TextField, FormControl, FormHelperText } from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';
import { useApi, microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { fetchAzureSubscriptions } from '../utils/apiCalls';
import Autocomplete  from '@mui/material/Autocomplete';

type AzureSubscriptionOptions = { id: string; name: string; };

interface AzureSubscriptionFieldProps {
    id: string;
    required: boolean;
    rawErrors: any;
    schema: any;
    onChange: (value: any) => void;
    setSubscriptionDetails: (value: any) => void;
    setUserToken: (token: string) => void;
    userToken: string | undefined;
}

export const AzureSubscriptionField: React.FC<AzureSubscriptionFieldProps> = ({
    id,
    required,
    rawErrors,
    schema,
    onChange,
    setSubscriptionDetails,
    setUserToken,
    userToken,
}) => {
    const microsoft = useApi(microsoftAuthApiRef);
    const { value, loading, error } = useAsync(async () => fetchAzureSubscriptions(microsoft, setUserToken), [userToken]);

    if (error) {
        console.error(error);
        return <Alert severity="error">Error could not fetch Azure subscriptions.</Alert>;
    }

    const azureSubscriptions: AzureSubscriptionOptions[] = value?.value?.map((data: any) => ({
        id: data.subscriptionId,
        name: data.displayName,
    })) || [];

    const AzureSubscriptionProps = {
        options: azureSubscriptions.sort((a: AzureSubscriptionOptions, b: AzureSubscriptionOptions) => a.name.localeCompare(b.name)),
        getOptionLabel: (option: AzureSubscriptionOptions) => option.name,
        getOptionSelected: (option: AzureSubscriptionOptions, value: AzureSubscriptionOptions) => option.name === value.name,
    };

    return (
        <FormControl margin="normal" required={required} error={rawErrors?.length > 0}>
            <Autocomplete
                id={id}
                onChange={(_, value) => {
                    onChange(value);
                    setSubscriptionDetails(value);
                }}
                loading={loading}
                {...AzureSubscriptionProps}
                clearOnEscape
                renderInput={(params: any) => (
                    <TextField {...params} label={schema.title || 'Azure Subscription'} variant="standard" required={required} />
                )}
            />
            {schema.description && <FormHelperText>{schema.description}</FormHelperText>}
        </FormControl>
    );
};