import React from 'react';
import type { FieldValidation } from '@rjsf/utils';
import FormControl from '@material-ui/core/FormControl';
import { CircularProgress, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';

import { AlertTitle } from '@material-ui/lab';

let globalIocodeError: Boolean=true;

export const IocodeValidator = ({
  onChange,
  schema,
  rawErrors,
  required,
  idSchema,
}: any) => {
  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);
  const [iocodeLoader, setIocodeLoader] = React.useState(false);
  const [iocodeError, setIocodeError] = React.useState(false);

  const onUserInput = async (value: any | null) => {
    globalIocodeError = false;
    setIocodeLoader(true);
    await validateIocode(value as string);
  };

  async function validateIocode(input_iocode: string) {
    try {
      const baseUrl = await discoveryApi.getBaseUrl('sharepoint');
      let iocodeList = await fetchApi
        .fetch(`${baseUrl}/v1/iocodevalidator?iocode=${input_iocode}`, {
          headers: {
            Accept: 'application/json',
          },
        })
        .then(response => response.json());
      iocodeList = iocodeList?.filter(
        (item: { field_1: string }) =>
          item.field_1.toUpperCase() == input_iocode.toUpperCase(),
      );

      if (iocodeList.length > 0) {
        setIocodeError(false);
        globalIocodeError = false;
      } else {
        setIocodeError(true);
        globalIocodeError = true;
      }
      setIocodeLoader(false);
    } catch (error) {
      throw error;
    }
  }
  return (
    <>
      <FormControl
        margin="normal"
        style={{ flexDirection: 'row' }}
        required={required}
        error={rawErrors?.length > 0}
      >
        <TextField
          id={idSchema?.$id}
          onChange={({ target: { value } }) => onChange({ iocode: value })}
          onBlur={({ target: { value } }) => onUserInput(value)}
          style={{ width: '100%' }}
          label={schema.title}
          variant="standard"
          helperText={schema.description}
        />
        {iocodeLoader ? <CircularProgress /> : <></>}
      </FormControl>
      {iocodeError ? (
        <>
          <Alert severity="error">
            <AlertTitle>Invalid Iocode</AlertTitle>
          </Alert>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export const IoCodeValidation = (value: string, validation: FieldValidation) => {

  if ((JSON.parse(JSON.stringify(value)).iocode === '' || JSON.parse(JSON.stringify(value)).iocode === undefined || JSON.parse(JSON.stringify(value)).iocode === null) && globalIocodeError === true) {
    validation.addError(`Please fill in the iocode`);
  }
}
