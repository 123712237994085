import { scaffolderPlugin } from '@internal/plugin-scaffolder';

import { createScaffolderFieldExtension } from '@internal/plugin-scaffolder-react';
import {
  RGvalidation,
  ResourceGroupCreationExtension,
} from './ResourceGroupValidation';

export const AzureResourceGroupFieldValidateExtension =
  scaffolderPlugin.provide(
    createScaffolderFieldExtension({
      name: 'ResourceGroupValidation',
      component: ResourceGroupCreationExtension,
      validation: RGvalidation,
    }),
  );
