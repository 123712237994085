import React from 'react';
const LogoFull = () => {
  return (
    <>
      <div className="sidebar-title">
        <img src="/D_T_Icon.png" className="logo-full" alt="cloud9" />
        <h2>MUZIRIS</h2>
      </div>
    </>
  );
};
export default LogoFull;
