/* eslint-disable no-console */
/* eslint-disable no-else-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FieldValidation } from '@rjsf/utils/lib/types';

import {
  FormControl,
  FormControlLabel,
  Checkbox,
  TextField,
  Radio,
  FormLabel,
  RadioGroup,
  MenuItem,
  // FormHelperText,
  CircularProgress,
  // Typography,
  Tooltip,
} from '@material-ui/core';
import { fetchMal, fetchPdt, validateEmail } from './api';
import CreateRgForm from './CommonFields/createRgFrom';
import GithubRepositoryForm from './CommonFields/GithubField';
// import { Link } from 'react-router-dom';
import AppsecToolForm from './CommonFields/AppsecToolForm';
import { TeamMembersFields } from './AdGroupAndTeamMembers/TeamMembersFields';
import { ExistingAdGroupsFields } from './AdGroupAndTeamMembers/AdGroupListFields';
import GithubRbacTeamMaintainerForm from './CommonFields/GithubRbac';
import { discoveryApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { Alert, Autocomplete } from '@material-ui/lab';
import AliasField from './CommonFields/AliasField';
import ResourceOwnerAndSupportedBy from './CommonFields/ResourceOwnerAndSupportedBy';
import CommonFields from './CommonFields/CommonFields';
import ServiceAccount from './CommonFields/ServiceAccount';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import * as constants from '../../../constants/ecosystemBuilderCreationConstants';

let GithubRepositoryError: string;
let ResourceOwnerError: string;
let SupportedByError: string;
let AliasNameError: string;
let GitRBAC: string;



interface ApplicationData {
  applicationID: string;
  applicationName: string;
  Description: string;
  product: string;
  applicationStatus: string;
  businessCriticality: string;
  dataClassification: string;
  businessPlatform: string;
  productPortfolio: string;
  productGroup: string;
}

interface ProductData {
  productName: string;
  teamName: string;
  productGroup: string;
  productPortfolio: string;
  businessPlatform: string;
}

interface OnboardValues {
  onboardingType: string;
}

interface FormValues {
  application_name: string;
  application_name_abbreviation: string;
  description: string;
  application_status: string;
  product: string;
  product_group: string;
  product_portfolio: string;
  platform: string;
  hosting_type: string;
  supported_by: string;
  developed_by: string;
  business_criticality: string;
  data_classification: string;
  request_owner: string;
  tech_stack: string;
  sandboxsubscription_create: boolean;
  prod_nprod_subscription_create: boolean;
  resource_group_create: boolean;
  self_hosted_runners_enable: boolean;
  app_id_create: boolean;
  ad_group_create: boolean;
  github_repository_create: boolean;
  github_team_rbac_create: boolean;
  yaccount_create: boolean;
  yaccount_enable_mailbox: boolean;
  io_code: string;
  sonarcloud_create: boolean;
  snyk_appsec_create: boolean;
  prisma_appsec_create: boolean;
  // launchdarkly_create: boolean;
  // fortify_appsec_create: boolean;
  newrelic_create: boolean;
  pagerduty_create: boolean;
}

export const CreateEcosystemBuilderV2 = ({ onChange }: any) => {
  const [onboardValues, setOnboardValues] = useState<OnboardValues>({
    onboardingType: '',
  });

  const [formData, setFormData] = useState<FormValues>({
    application_name: '',
    application_name_abbreviation: '',
    io_code: '',
    description: '',
    application_status: 'To be Introduced',
    product: '',
    product_group: '',
    product_portfolio: '',
    platform: '',
    hosting_type: 'PAAS',
    developed_by: 'M&S',
    business_criticality: 'Day 1',
    data_classification: 'Internal',
    tech_stack: 'JS/TS',
    sandboxsubscription_create: false,
    prod_nprod_subscription_create: false,
    resource_group_create: false,
    self_hosted_runners_enable: false,
    app_id_create: false,
    github_repository_create: true,
    request_owner: '',
    supported_by: '',
    ad_group_create: false,
    github_team_rbac_create: true,
    yaccount_create: true,
    yaccount_enable_mailbox: false,
    sonarcloud_create: true,
    snyk_appsec_create: true,
    prisma_appsec_create: true,
    // launchdarkly_create: false,
    // fortify_appsec_create: false,
    newrelic_create: true,
    pagerduty_create: true,
  });

  const [loading, setLoading] = useState(false);
  const [pdtLoading, setPdtLoading] = useState(false);
  const [pdtData, setPdtData] = useState<ProductData[]>([]);
  const [selectedPdt, setSelectedPdt] = useState<ProductData | null>(null);
  const [_pdtOptions, setPdtOptions] = useState<ProductData[]>([]);
  const [showFillPdtDetailsAlert, setShowFillPdtDetailsAlert] = useState(false);
  const [applicationData, setApplicationData] = useState<ApplicationData[]>([]);
  const [selectedApplication, setSelectedApplication] =
    useState<ApplicationData | null>(null);
  const [_options, setOptions] = useState<ApplicationData[]>([]);
  const [showFillDetailsAlert, setShowFillDetailsAlert] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openGithubDialog, setOpenGithubDialog] = useState(false);
  const [selectedProdEnvironments, setSelectedProdEnvironments] = useState<
    string[]
  >([]);
  const [selectedNonProdEnvironments, setSelectedNonProdEnvironments] =
    useState<string[]>([]);
  const [rgCheckboxChecked, setRgCheckboxChecked] = useState(false);
  const [githubRepositoryCreateChecked, setGithubRepositoryCreateChecked] =
    useState(true);
  const [overrideGithubRepoName, setOverrideGithubRepoName] = useState('');
  const [existingGithubRepoName, setExistingGithubRepoName] = useState('');
  const [selectedOption, setSelectedOption] = useState('JS/TS');
  const [selfHostedRunnersEnableChecked, setSelfHostedRunnersEnableChecked] =
    useState(false);
  const [teamName, setTeamName] = useState('');
  const [appSecToolsChecked, setAppSecToolsChecked] = useState(false);
  const [appSecDialogOpen, setAppSecDialogOpen] = useState(false);
  const [selectedAppSecTools, setSelectedAppSecTools] = useState<string[]>([]);
  const [adGroupChecked, setAdGroupChecked] = useState(false);
  const [teamMembers, setTeamMembers] = useState<
    Array<{ email: string; read: boolean; write: boolean; admin: boolean }>
  >([]);
  const [adGroupList, setAdGroupList] = useState<
    Array<{
      adgroup_name: string;
      read: boolean;
      write: boolean;
      admin: boolean;
    }>
  >([]);

  const [githubRbacChecked, setGithubRbacChecked] = useState(true);
  const [githubUsername, setGithubUsername] = useState('');
  const [githubValidationMessage, setGithubValidationMessage] = useState('');
  const [rbacError, setRbacError] = useState(false);

  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);
  const [aliasName, setAliasName] = useState('');
  const [resourceOwner, setResourceOwner] = useState('');
  const [supportedBy, setSupportedBy] = useState('');
  const [applicationNameValue, setApplicationNameValue] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');
  const [ioCodeValue, setIoCodeValue] = useState('');
  const [businessCriticalityValue, setBusinessCriticalityValue] =
    useState('Day 1');
  const [dataClassificationValue, setDataClassificationValue] =
    useState('Internal');
  const [linkToDocumentationValue, setLinkToDocumentationValue] = useState('');
  const [yaccountId, setYaccountId] = useState('');
  const [yaccountSecretKey, setYaccountSecretKey] = useState('');
  const [enableMailBox, setEnableMailBox] = useState(false);
  const [enableServiceAccount, setEnableServiceAccount] = useState(true);

  const [businessCriticalityFromMal, setBusinessCriticalityFromMal] = useState(
    selectedApplication?.businessCriticality,
  );
  const [dataClassificationFromMal, setDataClassificationFromMal] = useState(
    selectedApplication?.dataClassification,
  );

  useEffect(() => {
    // Fetch application data for the dropdown
    setLoading(true);
    setPdtLoading(true);
    setShowFillDetailsAlert(false);
    setShowFillPdtDetailsAlert(false);
    const fetchMalFromApi = async () => {
      setLoading(true);
      try {
        const data1 = await fetchMal(discoveryApi, fetchApi);
        setApplicationData(data1);
        setShowFillDetailsAlert(true);
        setOptions(data1);
      } catch (error) {
        console.error('Error fetching application data:', error);
      } finally {
        setLoading(false);
      }
    };
    const fetchPdtData = async () => {
      setPdtLoading(true);

      try {
        const data = await fetchPdt(discoveryApi, fetchApi);
        setPdtData(data);
        setShowFillPdtDetailsAlert(true);
        setPdtOptions(data);
      } catch (error) {
        console.error('Error fetching product data:', error);
      } finally {
        setPdtLoading(false);
      }
    };
    fetchMalFromApi();
    fetchPdtData();
  }, [onboardValues.onboardingType]); // Run once when the component mounts

  // Handle application selection
  const handleApplicationSelect = (selectedValue: ApplicationData | null) => {
    setSelectedApplication(selectedValue);
    setBusinessCriticalityFromMal(selectedValue?.businessCriticality || '');
    setDataClassificationFromMal(selectedValue?.dataClassification || '');
    setFormData((prevData: any) => {
      onChange({
        ...prevData,
        application_name: selectedValue?.applicationName || '',
        application_id: selectedValue?.applicationID || '',
        description: selectedValue?.Description || '',
        product: selectedValue?.product || '',
        product_group: selectedValue?.productGroup || '',
        product_portfolio: selectedValue?.productPortfolio || '',
        platform: selectedValue?.businessPlatform || '',
        business_criticality: selectedValue?.businessCriticality || '',
        data_classification: selectedValue?.dataClassification || '',
        application_status: selectedValue?.applicationStatus || '',

        // Add other fields to update based on the selected application
      });
      return {
        ...prevData,
        application_name: selectedValue?.applicationName || '',
        application_id: selectedValue?.applicationID || '',
        description: selectedValue?.Description || '',
        product: selectedValue?.product || '',
        product_group: selectedValue?.productGroup || '',
        product_portfolio: selectedValue?.productPortfolio || '',
        platform: selectedValue?.businessPlatform || '',
        business_criticality: selectedValue?.businessCriticality || '',
        data_classification: selectedValue?.dataClassification || '',
        application_status: selectedValue?.applicationStatus || '',
      };
    });
  };

  const handleBusinessCriticalityChangeFromMal = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const selectedCriticality = event.target.value as string;
    setBusinessCriticalityFromMal(selectedCriticality); // Update state

    setFormData((prevData: any) => {
      onChange({ ...prevData, business_criticality: selectedCriticality }); // Update form data
      return { ...prevData, business_criticality: selectedCriticality };
    });
  };

  const handleDataClassificationChangeFromMal = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const selectedClassification = event.target.value as string;
    setDataClassificationFromMal(selectedClassification); // Update state

    setFormData((prevData: any) => {
      onChange({ ...prevData, data_classification: selectedClassification }); // Update form data
      return { ...prevData, data_classification: selectedClassification };
    });
  };

  const handleProductSelect = (selectedValue: ProductData | null) => {
    setSelectedPdt(selectedValue);
    setFormData((prevData: any) => {
      onChange({
        ...prevData,
        app_id_create: true,
        product: selectedValue?.productName || '',
        product_group: selectedValue?.productGroup || '',
        product_portfolio: selectedValue?.productPortfolio || '',
        platform: selectedValue?.businessPlatform || '',

        // Add other fields to update based on the selected application
      });
      return {
        ...prevData,
        app_id_create: true,
        product: selectedValue?.productName || '',
        product_group: selectedValue?.productGroup || '',
        product_portfolio: selectedValue?.productPortfolio || '',
        platform: selectedValue?.businessPlatform || '',
      };
    });
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOnboardValues(prevData => ({
      ...prevData,
      onboardingType: event.target.value,
    }));
  };

  const handleChange =
    (fieldName: keyof FormValues) =>
      (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData(prevData => {
          onChange({ ...prevData, [fieldName]: event.target.value });
          return { ...prevData, [fieldName]: event.target.value };
        });
      };

  const handleProdEnvChange = (_: any, newValue: string[]) => {
    setSelectedProdEnvironments(newValue);

    setFormData(prevData => {
      console.log(newValue, selectedNonProdEnvironments)
      const updatedData = {
        ...prevData,
        ...(newValue.length > 0 ? { prod_rg_env: newValue } : {}),
        ...(newValue === undefined && selectedNonProdEnvironments === undefined ? { resource_group_create: false } : {}),
      };

      // Remove `prod_rg_env` if no prod environments are selected
      if (newValue.length === 0) delete updatedData.prod_rg_env;

      onChange(updatedData);
      return updatedData;
    });
  };

  const handleNonProdEnvChange = (_: any, newValue: string[]) => {
    setSelectedNonProdEnvironments(newValue);

    setFormData(prevData => {
      const updatedData = {
        ...prevData,
        ...(newValue.length > 0 ? { nonprod_rg_env: newValue } : {}),
        ...(newValue.length === undefined && selectedProdEnvironments === undefined ? { resource_group_create: false } : {}),
      };

      // Remove `nonprod_rg_env` if no non-prod environments are selected
      if (newValue.length === 0) delete updatedData.nonprod_rg_env;

      onChange(updatedData);
      return updatedData;
    });
  };

  const handleCreateRgCheckboxChange = () => {
    const newRgCheckboxState = !rgCheckboxChecked;
    setRgCheckboxChecked(newRgCheckboxState);
    setOpenDialog(newRgCheckboxState);
    if (newRgCheckboxState) {
      setFormData(prevData => {
        // Remove prod_rg_env and nonprod_rg_env if checkbox is unchecked or if no environments are selected
        const { prod_rg_env, nonprod_rg_env, ...restData } = prevData as any;
        console.log(newRgCheckboxState);
        const updatedData = {
          ...restData,
          ...(newRgCheckboxState ? { resource_group_create: true } : { resource_group_create: false }),
          ...(newRgCheckboxState && selectedProdEnvironments.length > 0 ? { prod_rg_env: selectedProdEnvironments } : {}),
          ...(newRgCheckboxState && selectedNonProdEnvironments.length > 0 ? { nonprod_rg_env: selectedNonProdEnvironments } : {})
        };

        onChange(updatedData);
        return updatedData;
      });
    }
    else {
      setFormData(prevData => {
        onChange({ ...prevData, resource_group_create: false });
        return ({ ...prevData, resource_group_create: false });
      })
    }
  };

  const handleGithubInformationChange = () => {
    const newCheckedState = !githubRepositoryCreateChecked;
    setGithubRepositoryCreateChecked(newCheckedState);
    if (!newCheckedState) {
      // Reset relevant fields when the checkbox is unchecked
      setOverrideGithubRepoName('');
      setExistingGithubRepoName('');
    } else {
      setOpenGithubDialog(true);
    }
    setFormData((prevData: any) => {
      if (newCheckedState === false) {
        const { existing_github_repo_name, ...restData } = prevData as any;
        onChange({ ...restData, github_repository_create: newCheckedState });
        return { ...restData, github_repository_create: newCheckedState };
      } else {
        const { override_github_repo_name, ...restData } = prevData as any;
        onChange({ ...restData, github_repository_create: newCheckedState });
        return { ...restData, github_repository_create: newCheckedState };
      }
      // Exclude team_name from the data
    });
  };

  const handleOverrideGithubRepoName = async (event: any) => {
    const repoName = event.target.value;
    try {
      setOverrideGithubRepoName(repoName);
      setFormData((prevData: any) => {
        if (repoName === '') {
          const { override_github_repo_name, ...restData } = prevData;
          onChange({
            ...restData,
            github_repository_create: githubRepositoryCreateChecked,
          });
          return {
            ...restData,
            github_repository_create: githubRepositoryCreateChecked,
          };
        }
        onChange({
          ...prevData,
          override_github_repo_name: repoName,
          github_repository_create: githubRepositoryCreateChecked,
        });
        return {
          ...prevData,
          override_github_repo_name: repoName,
          github_repository_create: githubRepositoryCreateChecked,
        };
      });
    } catch (error) {
      console.error('Error checking repository existence:', error);
    }
  };

  const handleExistingGithubRepoName = async (event: {
    target: { value: any };
  }) => {
    const repoName = event.target.value;
    setExistingGithubRepoName(repoName);
    setFormData(prevData => {
      onChange({
        ...prevData,
        existing_github_repo_name: repoName,
        github_repository_create: githubRepositoryCreateChecked,
      });
      return {
        ...prevData,
        existing_github_repo_name: repoName,
        github_repository_create: githubRepositoryCreateChecked,
      };
    });
  };

  const handleGithubInformationClose = () => {
    if (existingGithubRepoName.trim() === '') {
      setGithubRepositoryCreateChecked(false);
    }
    setOpenGithubDialog(false);
  };

  const handleSelfHostedRunnersEnableChange = () => {
    setSelfHostedRunnersEnableChecked(!selfHostedRunnersEnableChecked);

    setFormData(prevData => {
      const { team_name, ...restData } = prevData as any;
      onChange({
        ...restData,
        self_hosted_runners_enable: !selfHostedRunnersEnableChecked,
      }); // Exclude team_name from the data
      return {
        ...restData,
        self_hosted_runners_enable: !selfHostedRunnersEnableChecked,
      };
    });

    if (!selfHostedRunnersEnableChecked) {
      onChange({
        ...formData,
        self_hosted_runners_enable: !selfHostedRunnersEnableChecked,
      });
    } else {
      setTeamName('');
    }
  };

  const handleSelfHostedRunnerTeamName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const team_value = event.target.value;
    setTeamName(event.target.value);

    onChange({ ...formData, team_name: team_value });
    setFormData(prevData => ({
      ...prevData,
      team_name: team_value,
    }));
  };

  const handleAppSecToolsChange = () => {
    setAppSecToolsChecked(!appSecToolsChecked);

    if (!appSecToolsChecked) {
      setAppSecDialogOpen(true);
    }
  };

  const handleAppSecDialogClose = () => {
    setAppSecDialogOpen(false);
  };

  const handleChipClick = () => {
    if (appSecToolsChecked) {
      setAppSecDialogOpen(true);
    }
  };

  const handleAppSecToolSelect = (tool: string) => {
    if (appSecToolsChecked) {
      if (selectedAppSecTools.includes(tool)) {
        setSelectedAppSecTools(prev =>
          prev.filter(selectedTool => selectedTool !== tool),
        );
      } else {
        setSelectedAppSecTools(prev => [...prev, tool]);
      }
    }
  };

  const handleAdGroupCheckedChange = () => {
    setAdGroupChecked(!adGroupChecked);
    setFormData(prevData => {
      const { team_members, ...restData } = prevData as any;
      onChange({ ...restData, ad_group_create: !adGroupChecked }); // Exclude team_name from the data
      return {
        ...restData,
        ad_group_create: !adGroupChecked,
      };
    });
    if (!adGroupChecked) {
      onChange({
        ...formData,
        ad_group_create: !adGroupChecked,
      });
    }
  };

  const handleGithubRbacChange = () => {
    setGithubRbacChecked(!githubRbacChecked);
    setGithubUsername('');
    setGithubValidationMessage('');
    setFormData((prevData) => {
      const { github_team_maintainer, ...restData } = prevData as any;

      if (!githubRbacChecked) {
        onChange({
          ...restData,
          github_team_rbac_create: !githubRbacChecked,
        });
      }
      onChange({ ...restData, github_team_rbac_create: !githubRbacChecked })// Exclude team_name from the data
      return {
        ...restData,
        github_team_rbac_create: !githubRbacChecked,
      };
    }
    );

    // onChange({ ...formData, github_team_rbac_create: !githubRbacChecked });
  };


  const handleGithubUsernameChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const username = event.target.value;
    setGithubUsername(username);
    setFormData((prevData: any) => {
      onChange({ ...prevData, github_team_maintainer: username });
      return { ...prevData, github_team_maintainer: username };
    });
  };
  const handleAddTeamMember = (teamMember: any) => {
    setTeamMembers([...teamMembers, teamMember]);
    setFormData((prevData: any) => {
      onChange({ ...prevData, team_members: [...teamMembers, teamMember] });
      return { ...prevData, team_members: [...teamMembers, teamMember] };
    });
  };

  const handleEditTeamMember = (index: any, updatedTeamMember: any) => {
    const updatedTeamMembers = [...teamMembers];
    updatedTeamMembers[index] = updatedTeamMember;
    setTeamMembers(updatedTeamMembers);
    setFormData((prevData: any) => {
      onChange({ ...prevData, team_members: updatedTeamMembers });
      return { ...prevData, team_members: updatedTeamMembers };
    });
  };

  const handleRemoveTeamMember = (index: number) => {
    const updatedTeamMembers = [...teamMembers];
    updatedTeamMembers.splice(index, 1);
    setTeamMembers(updatedTeamMembers);
    // remove team members from Onchange
    setFormData((prevData: any) => {
      onChange({ ...prevData, team_members: updatedTeamMembers });
      return { ...prevData, team_members: updatedTeamMembers };
    });
  };

  const handleAddExistingAdGroup = (existingAdGroup: any) => {
    setAdGroupList([...adGroupList, existingAdGroup]);
    setFormData((prevData: any) => {
      onChange({
        ...prevData,
        existing_ad_group_list: [...adGroupList, existingAdGroup],
      });
      return {
        ...prevData,
        existing_ad_group_list: [...adGroupList, existingAdGroup],
      };
    });
  };

  const handleEditExistingAdGroup = (
    index: any,
    updatedExistingAdGroup: any,
  ) => {
    const updatedExistingAdGroups = [...adGroupList];
    updatedExistingAdGroups[index] = updatedExistingAdGroup;
    setAdGroupList(updatedExistingAdGroups);
    setFormData((prevData: any) => {
      onChange({
        ...prevData,
        existing_ad_group_list: updatedExistingAdGroups,
      });
      return { ...prevData, existing_ad_group_list: updatedExistingAdGroups };
    });
  };

  const handleRemoveExistingAdGroup = (index: number) => {
    const updatedExistingAdGroups = [...adGroupList];
    updatedExistingAdGroups.splice(index, 1);
    setAdGroupList(updatedExistingAdGroups);
    setFormData((prevData: any) => {
      onChange({
        ...prevData,
        existing_ad_group_list: updatedExistingAdGroups,
      });
      return { ...prevData, existing_ad_group_list: updatedExistingAdGroups };
    });
  };

  const handleAliasChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const aliasNameChange = event.target.value;
    setAliasName(event.target.value);
    setFormData((prevData: any) => {
      onChange({ ...prevData, application_name_abbreviation: aliasNameChange });
      return { ...prevData, application_name_abbreviation: aliasNameChange };
    });
  };

  const handleResourceOwnerChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const resourceOwnerChange = event.target.value;
    setResourceOwner(event.target.value);
    setFormData((prevData: any) => {
      onChange({ ...prevData, request_owner: resourceOwnerChange });
      return { ...prevData, request_owner: resourceOwnerChange };
    });
  };

  const handleSupportedByChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const supportedByChange = event.target.value;
    setSupportedBy(event.target.value);
    setFormData((prevData: any) => {
      onChange({ ...prevData, supported_by: supportedByChange });
      return { ...prevData, supported_by: supportedByChange };
    });
  };

  // Appsec Tools
  useEffect(() => {
    // Update the form data when selectedAppSecTools change
    setFormData((prevData: any) => {
      onChange({
        ...prevData,
        snyk_appsec_create: selectedAppSecTools.includes('Snyk'),
        prisma_appsec_create: selectedAppSecTools.includes('Prisma'),
        sonarcloud_create: selectedAppSecTools.includes('SonarCloud'),
        // newrelic_create: selectedAppSecTools.includes('New Relic'),
        pagerduty_create: selectedAppSecTools.includes('PagerDuty'),
      });
      return {
        ...prevData,
        snyk_appsec_create: selectedAppSecTools.includes('Snyk'),
        prisma_appsec_create: selectedAppSecTools.includes('Prisma'),
        sonarcloud_create: selectedAppSecTools.includes('SonarCloud'),
        // newrelic_create: selectedAppSecTools.includes('New Relic'),
        pagerduty_create: selectedAppSecTools.includes('PagerDuty'),
      };
    });
  }, [selectedAppSecTools, setFormData]);

  useEffect(() => {
    // Update the selectedAppSecTools when appSecToolsChecked changes
    if (!appSecToolsChecked) {
      setSelectedAppSecTools([]);
    }
  }, [appSecToolsChecked]);

  const handleApplicationNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const applicationNameChange = event.target.value;
    setApplicationNameValue(event.target.value);
    setFormData((prevData: any) => {
      onChange({ ...prevData, application_name: applicationNameChange });
      return { ...prevData, application_name: applicationNameChange };
    });
  };
  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const descriptionChange = event.target.value;
    setDescriptionValue(event.target.value);
    setFormData((prevData: any) => {
      onChange({ ...prevData, description: descriptionChange });
      return { ...prevData, description: descriptionChange };
    });
  };
  const handleIoCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const ioCodeChange = event.target.value;
    setIoCodeValue(event.target.value);
    setFormData((prevData: any) => {
      onChange({ ...prevData, io_code: ioCodeChange });
      return { ...prevData, io_code: ioCodeChange };
    });
  };
  const handleBusinessCriticalityChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const businessCriticalityChange = event.target.value;
    setBusinessCriticalityValue(event.target.value);
    setFormData((prevData: any) => {
      onChange({
        ...prevData,
        business_criticality: businessCriticalityChange,
      });
      return { ...prevData, business_criticality: businessCriticalityChange };
    });
  };
  const handleDataClassificationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const dataClassificationChange = event.target.value;
    setDataClassificationValue(event.target.value);
    setFormData((prevData: any) => {
      onChange({ ...prevData, data_classification: dataClassificationChange });
      return { ...prevData, data_classification: dataClassificationChange };
    });
  };
  const handleLinkToDocumentationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const linkToDocumentationChange = event.target.value;
    setLinkToDocumentationValue(event.target.value);
    setFormData((prevData: any) => {
      onChange({ ...prevData, confluence_page: linkToDocumentationChange });
      return { ...prevData, confluence_page: linkToDocumentationChange };
    });
  };

  const handleEnableServiceAccount = () => {
    setEnableServiceAccount(!enableServiceAccount);
    if (!enableServiceAccount) {
      // Reset relevant fields when the checkbox is unchecked
      setYaccountId('');
      setYaccountSecretKey('');
    }

    setFormData((prevData: any) => {
      if (!enableServiceAccount === false) {
        const { yaccount_secret_key_name, existing_yaccount, ...restData } =
          prevData as any;
        onChange({ ...restData, yaccount_create: !enableServiceAccount });
        return { ...restData, yaccount_create: !enableServiceAccount };
      } else {
        onChange({ ...prevData, yaccount_create: enableServiceAccount });
        return { ...prevData, yaccount_create: enableServiceAccount };
      }
    });
  };

  const handleYaccountId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const yaccountIdChange = event.target.value;
    setYaccountId(event.target.value);
    setFormData((prevData: any) => {
      onChange({
        ...prevData,
        existing_yaccount: yaccountIdChange,
        yaccount_create: false,
      });
      return {
        ...prevData,
        existing_yaccount: yaccountIdChange,
        yaccount_create: false,
      };
    });
  };

  const handleYaccountSecretKeyName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const yaccountSecretKeyNameChange = event.target.value;
    setYaccountSecretKey(event.target.value);
    setFormData((prevData: any) => {
      onChange({
        ...prevData,
        yaccount_secret_key_name: yaccountSecretKeyNameChange,
        yaccount_create: false,
      });
      return {
        ...prevData,
        yaccount_secret_key_name: yaccountSecretKeyNameChange,
        yaccount_create: false,
      };
    });
  };

  const handleEnableMailBox = () => {
    setEnableMailBox(!enableMailBox);
    setFormData(prevData => {
      onChange({
        ...prevData,
        yaccount_enable_mailbox: !enableMailBox,
        yaccount_create: true,
      });
      return {
        ...prevData,
        yaccount_enable_mailbox: !enableMailBox,
        yaccount_create: true,
      };
    });
  };

  const handleDropdownChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const option = event.target.value;
    setSelectedOption(option);
    setFormData(prevData => {
      onChange({ ...prevData, tech_stack: option });
      return { ...prevData, tech_stack: option };
    });
  };

  const handleRgEnvironmentsOnEmpty = () => {
    if (selectedNonProdEnvironments.length === 0 && selectedProdEnvironments.length === 0) {
      setRgCheckboxChecked(false);
      setFormData(prevData => {
        onChange({ ...prevData, resource_group_create: false });

        return { ...prevData, resource_group_create: false };
      });
    }
  }
  const handleDialogClose = () => {
    setOpenDialog(false);
    handleRgEnvironmentsOnEmpty();
    // Assuming setOpenDialog is the state updater for openDialog
  };

  const handleGlobalError2 = (value: any) => {
    GithubRepositoryError = value;
  };

  const handleGitRBACError = (value: any) => {
    GitRBAC = value;
  };

  const handleGlobalResourceOwnerError = (value: any) => {
    ResourceOwnerError = value;
  };

  const handleSupportedByError = (value: any) => {
    SupportedByError = value;
  };

  const handleAliasNameError = (value: any) => {
    AliasNameError = value;
  };

  const businessCriticalitydropdown = [
    { businessCriticality: 'Day 1' },
    { businessCriticality: 'Day 3' },
    { businessCriticality: 'Day 7' },
    { businessCriticality: 'Month 1' },
  ];

  const dataClassificationDropdown = [
    { dataClassification: 'Public' },
    { dataClassification: 'Internal' },
    { dataClassification: 'Confidential - PCI and PII' },
    { dataClassification: 'Confidential - PCI' },
    { dataClassification: 'Confidential - PII' },
    { dataClassification: 'Confidential - Other' },
  ];

  return (
    <FormControl component="form">
      <div style={{ marginBottom: '16px' }}>
        <FormLabel component="legend">Onboard Type:</FormLabel>
        <RadioGroup
          name="onboardingType"
          value={onboardValues.onboardingType}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value="oboardNewApplication"
            control={<Radio />}
            label={
              <>
                Day 0 Onboarding / Green Field Application
                <Tooltip
                  title={
                    <span style={{ fontSize: '11px' }}>
                      Day 0 Onboarding is for new applications that are not yet
                      onboarded to the platform.
                    </span>
                  }
                >
                  <InfoOutlinedIcon
                    style={{
                      marginLeft: '8px',
                      cursor: 'pointer',
                      fontSize: '30px',
                    }}
                  />
                </Tooltip>
              </>
            }
          />
          <FormControlLabel
            value="existingApplications"
            control={<Radio />}
            label={
              <>
                Day 1 Onboarding / Brown Field Applications
                <Tooltip
                  title={
                    <span style={{ fontSize: '11px' }}>
                      Day 1 Onboarding is for existing applications that are
                      already created and wants to onboard to the platform
                    </span>
                  }
                >
                  <InfoOutlinedIcon
                    style={{
                      marginLeft: '8px',
                      cursor: 'pointer',
                      fontSize: '30px',
                    }}
                  />
                </Tooltip>
              </>
            }
          />
        </RadioGroup>

        {onboardValues.onboardingType === 'existingApplications' && (
          <div style={{ marginBottom: '16px' }}>
            <div>
              {loading && (
                <Alert
                  severity="info"
                  style={{ padding: '10px', marginBottom: '16px' }}
                >
                  Application information is being loaded...{' '}
                  <CircularProgress size="20px" />
                </Alert>
              )}
              {showFillDetailsAlert && (
                <Alert
                  severity="info"
                  onClose={() => setShowFillDetailsAlert(false)}
                  style={{ marginBottom: '16px' }}
                >
                  Please fill in the application details.
                </Alert>
              )}

              {/* Render Autocomplete for Application Name */}
              <div style={{ display: 'flex', gap: '16px' }}>
                {/* Autocomplete for Application Name */}
                <Autocomplete
                  options={applicationData}
                  getOptionLabel={option => option.applicationName || ''}
                  value={selectedApplication}
                  style={{ width: '50%' }}
                  onChange={(_, value) => handleApplicationSelect(value)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Application Name"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                    />
                  )}
                />

                {/* Autocomplete for Application ID */}
                <Autocomplete
                  options={applicationData}
                  getOptionLabel={option => option.applicationID || ''}
                  value={selectedApplication}
                  style={{ width: '50%' }}
                  onChange={(_, value) => handleApplicationSelect(value)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Application ID"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                    />
                  )}
                />
              </div>

              {/* Conditionally render additional text fields based on selection */}
              {selectedApplication && (
                <>
                  <TextField
                    label={constants.DESCRIPTION}
                    value={selectedApplication.Description || ''}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    InputProps={{
                      readOnly: true, // Make the field read-only
                    }}
                    helperText={constants.HELPER_TEXT_DESCRIPTION}
                  />

                  <TextField
                    label="Product"
                    variant="outlined"
                    value={selectedApplication.product || ''}
                    fullWidth
                    margin="dense"
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <TextField
                    select
                    value={businessCriticalityFromMal}
                    onChange={handleBusinessCriticalityChangeFromMal}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    label={constants.RECOVERY_TIME_OBJECTIVE}
                    helperText={constants.HELPER_TEXT_RECOVERY_TIME_OBJECTIVE}
                  >
                    {businessCriticalitydropdown.map(option => (
                      <MenuItem
                        key={option.businessCriticality}
                        value={option.businessCriticality}
                      >
                        {option.businessCriticality}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    select
                    value={dataClassificationFromMal}
                    onChange={handleDataClassificationChangeFromMal}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    label={constants.DATA_CLASSIFICATION}
                    helperText={constants.HELPER_TEXT_DATA_CLASSIFICATION}
                  >
                    {dataClassificationDropdown.map(option => (
                      <MenuItem
                        key={option.dataClassification}
                        value={option.dataClassification}
                      >
                        {option.dataClassification}
                      </MenuItem>
                    ))}
                  </TextField>
                </>
              )}
            </div>

            <AliasField
              handleAliasChange={handleAliasChange}
              aliasName={aliasName}
              AliasNameError={AliasNameError}
              handleAliasNameError={handleAliasNameError}
            />
            <TextField
              label={constants.IOCODE}
              value={formData.io_code}
              onChange={handleChange('io_code')}
              fullWidth
              margin="dense"
              variant="outlined"
              helperText={constants.HELPER_TEXT_IOCODE}
            />
          </div>
        )}
      </div>
      {/* // )} */}
      {onboardValues.onboardingType === 'oboardNewApplication' && (
        <>
          <div style={{ marginBottom: '16px' }}>
            <div>
              {pdtLoading && (
                <Alert
                  severity="info"
                  style={{ padding: '10px', marginBottom: '16px' }}
                >
                  Product Information information is being loaded...{' '}
                  <CircularProgress size="20px" />
                </Alert>
              )}
              {showFillPdtDetailsAlert && (
                <Alert
                  severity="info"
                  onClose={() => setShowFillPdtDetailsAlert(false)}
                  style={{ marginBottom: '16px' }}
                >
                  Fill in Product information
                </Alert>
              )}

              {/* Render Autocomplete for Application Name */}
              <div style={{ display: 'flex', gap: '16px' }}>
                {/* Autocomplete for Product */}
                <Autocomplete
                  options={pdtData}
                  getOptionLabel={option => option.productName || ''}
                  value={selectedPdt}
                  style={{ width: '100%' }}
                  onChange={(_, value) => handleProductSelect(value)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Product Name"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                    />
                  )}
                />
              </div>

              {/* Conditionally render additional text fields based on selection */}
              {selectedPdt && (
                <>
                  <TextField
                    label="Product Group"
                    value={selectedPdt.productGroup || ''}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    InputProps={{
                      readOnly: true, // Make the field read-only
                    }}
                  />
                  <TextField
                    label="Portfolio"
                    variant="outlined"
                    value={selectedPdt.productPortfolio || ''}
                    fullWidth
                    margin="dense"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </>
      )}
      {onboardValues.onboardingType === 'oboardNewApplication' && (
        <div style={{ marginBottom: '16px' }}>
          <CommonFields
            applicationNameValue={applicationNameValue}
            handleApplicationNameChange={handleApplicationNameChange}
            aliasName={aliasName}
            AliasNameError={AliasNameError}
            handleAliasNameError={handleAliasNameError}
            handleAliasChange={handleAliasChange}
            descriptionValue={descriptionValue}
            handleDescriptionChange={handleDescriptionChange}
            ioCodeValue={ioCodeValue}
            handleIoCodeChange={handleIoCodeChange}
            businessCriticalityValue={businessCriticalityValue}
            handleBusinessCriticalityChange={handleBusinessCriticalityChange}
            dataClassificationValue={dataClassificationValue}
            handleDataClassificationChange={handleDataClassificationChange}
            linkToDocumentationValue={linkToDocumentationValue}
            handleLinkToDocumentationChange={handleLinkToDocumentationChange}
          />
        </div>
      )}
      {(onboardValues.onboardingType === 'existingApplications' ||
        onboardValues.onboardingType === 'oboardNewApplication') && (
          <div style={{ marginBottom: '16px' }}>
            {/* Handle RG Env */}
            <div style={{ marginBottom: '16px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rgCheckboxChecked}
                    onChange={handleCreateRgCheckboxChange}
                  />
                }
                label={constants.CREATE_RG}
              />
              <CreateRgForm
                open={openDialog}
                onClose={handleDialogClose}
                selectedProdEnvironments={selectedProdEnvironments}
                selectedNonProdEnvironments={selectedNonProdEnvironments}
                handleProdEnvChange={handleProdEnvChange}
                handleNonProdEnvChange={handleNonProdEnvChange}
                setRgCheckboxChecked={setRgCheckboxChecked}
                setOpenDialog={setOpenDialog}
                rgCheckboxChecked={rgCheckboxChecked}
              />
            </div>
            {/* handle GH Repo */}
            <div style={{ marginBottom: '16px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={githubRepositoryCreateChecked}
                    onChange={handleGithubInformationChange}
                  />
                }
                label={
                  <>
                    Create Github Repository
                    <Tooltip
                      title={
                        <span style={{ fontSize: '11px' }}>
                          Deselect for adding existing GitHub repository
                        </span>
                      }
                    >
                      <InfoOutlinedIcon
                        style={{
                          marginLeft: '8px',
                          cursor: 'pointer',
                          fontSize: '30px',
                        }}
                      />
                    </Tooltip>
                  </>
                }
              />
              <GithubRepositoryForm
                open={openGithubDialog}
                onClose={handleGithubInformationClose}
                formdata={
                  onboardValues.onboardingType === 'existingApplications' ||
                  onboardValues.onboardingType === 'oboardNewApplication'
                }
                handleOverrideGithubRepoName={handleOverrideGithubRepoName}
                handleExistingGithubRepoName={handleExistingGithubRepoName}
                GithubRepositoryError={GithubRepositoryError}
                handleGlobalError2={handleGlobalError2}
                githubRepositoryCreateChecked={githubRepositoryCreateChecked}
                githubRepoName={
                  githubRepositoryCreateChecked
                    ? overrideGithubRepoName
                    : existingGithubRepoName
                }
                existingGithubRepoName={existingGithubRepoName}
                setOpenGithubDialog={setOpenGithubDialog}
                setGithubRepositoryCreateChecked={
                  setGithubRepositoryCreateChecked
                }
              />
            </div>
            {/* handle selfhosted runner */}
            <div style={{ marginBottom: '16px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selfHostedRunnersEnableChecked}
                    onChange={handleSelfHostedRunnersEnableChange}
                  />
                }
                label="Activate Github Runners"
              />
              {selfHostedRunnersEnableChecked && (
                <TextField
                  label={constants.RUNNER_NAME}
                  value={teamName}
                  onChange={handleSelfHostedRunnerTeamName}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  helperText={constants.HELPER_TEXT_RUNNER_NAME}
                />
              )}
            </div>
            {/* handle Appsec Tools */}
            <div style={{ marginBottom: '16px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={appSecToolsChecked}
                    onChange={handleAppSecToolsChange}
                  />
                }
                label={
                  <>
                    DevOps Stack
                    <Tooltip
                      title={
                        <span style={{ fontSize: '11px' }}>
                          The DevOps tools can be collectively enabled, Opt out
                          for any if not required.
                        </span>
                      }
                    >
                      <InfoOutlinedIcon
                        style={{
                          marginLeft: '4px',
                          cursor: 'pointer',
                          fontSize: '30px',
                        }}
                      />
                    </Tooltip>
                  </>
                }
              />

              <AppsecToolForm
                appSecDialogOpen={appSecDialogOpen}
                handleAppSecDialogClose={handleAppSecDialogClose}
                selectedAppSecTools={selectedAppSecTools}
                handleAppSecToolSelect={handleAppSecToolSelect}
                handleChipClick={handleChipClick}
              />
            </div>
            {/* Handle techStack */}
            <div style={{ marginBottom: '16px' }}>
              {/* give code for a textfield dropdown with a fixed menu and default value set */}
              <TextField
                select
                label={constants.STACK}
                value={selectedOption}
                onChange={handleDropdownChange}
                fullWidth
                margin="dense"
                variant="outlined"
              >
                <MenuItem value="JS/TS">JS/TS</MenuItem>
                <MenuItem value="GraphQL">GraphQL</MenuItem>
                <MenuItem value="Java">Java</MenuItem>
                <MenuItem value=".Net">.Net</MenuItem>
                <MenuItem value=".Net Core">.Net Core</MenuItem>
                <MenuItem value="Python">Python</MenuItem>
                <MenuItem value="Others">Others</MenuItem>
              </TextField>
            </div>
            {/* handle Ad group */}
            <div style={{ marginBottom: '16px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={adGroupChecked}
                    onChange={handleAdGroupCheckedChange}
                  />
                }
                label={constants.CREATE_AD_GROUP}
              />
              <div>
                {formData.ad_group_create ? (
                  <TeamMembersFields
                    teamMembers={teamMembers}
                    handleAddTeamMember={handleAddTeamMember}
                    handleEditTeamMember={handleEditTeamMember}
                    handleRemoveTeamMember={handleRemoveTeamMember}
                  />
                ) : (
                  <ExistingAdGroupsFields
                    existingAdGroups={adGroupList}
                    handleAddExistingAdGroup={handleAddExistingAdGroup}
                    handleEditExistingAdGroup={handleEditExistingAdGroup}
                    handleRemoveExistingAdGroup={handleRemoveExistingAdGroup}
                  />
                )}
              </div>
            </div>
            {/* handle Github Rbac*/}
            <div style={{ marginBottom: '16px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={githubRbacChecked}
                    onChange={handleGithubRbacChange}
                  />
                }
                label={constants.ENABLE_GITHUB_MAINTAINER}
              />
              {githubRbacChecked && (
                <GithubRbacTeamMaintainerForm
                  githubUsername={githubUsername}
                  handleGitRBACError={handleGitRBACError}
                  handleGithubUsernameChange={handleGithubUsernameChange}
                  githubValidationMessage={githubValidationMessage}
                  rbacError={rbacError}
                  setGithubValidationMessage={setGithubValidationMessage}
                  setRbacError={setRbacError}
                />
              )}
            </div>
            {/* handle Yaccount */}
            <div style={{ marginBottom: '16px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={enableServiceAccount}
                    onChange={handleEnableServiceAccount}
                  />
                }
                label={constants.CREATE_YACCOUNT_TITLE}
              />
              <ServiceAccount
                handleEnableMailBox={handleEnableMailBox}
                enableMailBox={enableMailBox}
                handleYaccountId={handleYaccountId}
                yaccountId={yaccountId}
                handleYaccountSecretKeyName={handleYaccountSecretKeyName}
                yaccountSecretKey={yaccountSecretKey}
                enableServiceAccount={enableServiceAccount}
                handleEnableServiceAccount={handleEnableServiceAccount}
                setEnableServiceAccount={setEnableServiceAccount}
              />
            </div>
            {/* handle Resource owner and Supported By */}
            <div>
              <ResourceOwnerAndSupportedBy
                handleResourceOwnerChange={handleResourceOwnerChange}
                handleSupportedByChange={handleSupportedByChange}
                resourceOwner={resourceOwner ? resourceOwner : ''}
                supportedBy={supportedBy ? supportedBy : ''}
                ResourceOwnerError={ResourceOwnerError}
                handleGlobalResourceOwnerError={handleGlobalResourceOwnerError}
                SupportedByError={SupportedByError}
                handleSupportedByError={handleSupportedByError}
              />
            </div>
          </div>
        )}
    </FormControl>
  );
};

export const validateEcosystemBuilderV2 = (
  value: string,
  validation: FieldValidation,
) => {
  if (
    GithubRepositoryError === 'exists' &&
    JSON.parse(JSON.stringify(value)).github_repository_create === true
  ) {
    validation.addError(
      `Please select different Repository Name for override github repo`,
    );
  }
  if (
    GithubRepositoryError === "doNotExists" &&
    JSON.parse(JSON.stringify(value)).github_repository_create === false
  ) {
    validation.addError(
      `Please select different Repository Name for existing github repo`,
    );
  }
  if (ResourceOwnerError === "doNotExists") {
    validation.addError(`Please provide valid Email for ResourceOwner.`);
  }
  if (SupportedByError === "doNotExists") {
    validation.addError(`Please provide valid Group Email for Supported By.`);
  }
  if (GitRBAC === "doNotExists") {
    validation.addError(`Please provide differnet Github ID`);
  }
  if (AliasNameError === "exists" || !validateEmail(JSON.parse(JSON.stringify(value)).application_name_abbreviation, /^[a-zA-Z0-9]{3,8}$/)) {
    validation.addError(`Please provide differnet App Friendly Name. Please ensure the App Friendly Name should have a minimum length of 3 characters and maximum of 8 characters\n Supported characters are alphanumeric(a-z and 0-9) with no special characters or whitespaces`);
  }
  if (JSON.parse(JSON.stringify(value)).app_id_create === true) {
    if (JSON.parse(JSON.stringify(value)).application_name === '' || !validateEmail(JSON.parse(JSON.stringify(value)).application_name, /^[a-z0-9\-]{3,16}$/)) {
      validation.addError(
        `Please fill in the application Name before submitting.\n Please ensure the Application name should have a minimum length of 3 characters and maximum of 16 characters\n Supported characters are alphanumeric(a-z and 0-9) separated by hyphens`,
      );
    }
  }
  if (JSON.parse(JSON.stringify(value)).app_id_create === false) {
    if (JSON.parse(JSON.stringify(value)).application_name === '') {
      validation.addError(
        `Please fill in the application Name before submitting.`,
      );
    }
  }
  if (JSON.parse(JSON.stringify(value)).description === '') {
    validation.addError(
      `Please fill in the description before submitting.`,
    );
  }
  if (JSON.parse(JSON.stringify(value)).io_code === '') {
    validation.addError(
      `Please fill in the iocode before submitting.`,
    );
  }
  if (JSON.parse(JSON.stringify(value)).business_criticality === '') {
    validation.addError(
      `Please fill the Business Criticality before submitting.`,
    );
  }
  if (JSON.parse(JSON.stringify(value)).data_classification === '') {
    validation.addError(
      `Please fill the Data Classification before submitting.`,
    );
  }
  if (JSON.parse(JSON.stringify(value)).application_name_abbreviation === '') {
    validation.addError(
      `Please fill the Alias before submitting.`,
    );
  }
  if (JSON.parse(JSON.stringify(value)).product === '') {
    validation.addError(
      `Please fill the Product before submitting.`,
    );
  }
  if (
    JSON.parse(JSON.stringify(value)).github_repository_create === false &&
    (JSON.parse(JSON.stringify(value)).existing_github_repo_name === '' ||
      JSON.parse(JSON.stringify(value)).existing_github_repo_name ===
      undefined ||
      JSON.parse(JSON.stringify(value)).existing_github_repo_name === null)
  ) {
    validation.addError(
      `Please fill the Github Repository Name before submitting.`,
    );
  }
  if (JSON.parse(JSON.stringify(value)).resource_group_create === true && (JSON.parse(JSON.stringify(value)).prod_rg_env === null || JSON.parse(JSON.stringify(value)).nonprod_rg_env === null || JSON.parse(JSON.stringify(value)).prod_rg_env === '[]' || JSON.parse(JSON.stringify(value)).nonprod_rg_env === '[]')) {
    validation.addError(
      `Please fill the Resource Group Environment before submitting.`,
    );
  }
  if (JSON.parse(JSON.stringify(value)).self_hosted_runners_enable === true && JSON.parse(JSON.stringify(value)).team_name === '') {
    validation.addError(
      `Please fill the Self Hosted Runner Name before submitting.`,
    );
  }
  if (JSON.parse(JSON.stringify(value)).ad_group_create === true && (JSON.parse(JSON.stringify(value)).team_members === null || JSON.parse(JSON.stringify(value)).team_members === undefined || JSON.parse(JSON.stringify(value)).team_members === '[]')) {
    validation.addError(
      `Please fill the Team Members before submitting.`,
    );
  }
  if ((JSON.parse(JSON.stringify(value)).github_team_rbac_create === true) && (JSON.parse(JSON.stringify(value)).github_team_maintainer === '' || JSON.parse(JSON.stringify(value)).github_team_maintainer === null || JSON.parse(JSON.stringify(value)).github_team_maintainer === undefined)) {
    validation.addError(
      `Please fill the Github Team Maintainer before submitting.`,
    );
  }

  if (JSON.parse(JSON.stringify(value)).ad_group_create === false && (JSON.parse(JSON.stringify(value)).existing_ad_group_list === null || JSON.parse(JSON.stringify(value)).existing_ad_group_list === undefined || JSON.parse(JSON.stringify(value)).existing_ad_group_list === '[]')) {
    validation.addError(
      `Please fill the Existing AD Group List before submitting.`,
    );
  }

  if (JSON.parse(JSON.stringify(value)).supported_by === '') {
    validation.addError(
      `Please fill the Supported By before submitting.`,
    );
  }
  if (JSON.parse(JSON.stringify(value)).request_owner === '') {
    validation.addError(
      `Please fill the Resource Owner before submitting.`,
    );
  }
  if (JSON.stringify(value) === '{}') {
    validation.addError(
      `Please fill in the form before submitting.`,
    );
  }
  if (JSON.parse(JSON.stringify(value)).onboardingType === '') {
    validation.addError(
      `Please select onboarding type.`,
    );
  }
};
