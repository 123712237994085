import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { CardMedia, Grid } from "@material-ui/core";
import { InfoCard } from "@backstage/core-components";
import { AnnouncementsCard } from "@procore-oss/backstage-plugin-announcements";

const useStyles = makeStyles({
  composableSection: {
    height: "100%",
    "& > div": {
      paddingBottom: "10",
    },
  },
  cardBorder: {
    borderRadius: "25px",
    overflow: "hidden",
  },
  row: {
    padding: "0 30px 10px",
  },
  homepageReport: {
    height: "40vh",
    margin: "0% auto",
    width: "100%",
  },
});


export const InfoShelf = () => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.row}
      container
      spacing={2}
      id='tour-personalization'
    >
      <Grid item xs={6}>
        <InfoCard
          noPadding
          className={classes.cardBorder}
        >
          <a href="/docs/default/Component/Cloud9/cloud9_overview/#cloud9-by-numbers" >
            <CardMedia
              component="img"
              style={{ maxWidth: '100%', height: 'auto' }}
              image="/img/Cloud9 Brochure for MZ1.png"
              alt="Metrics"
            />
          </a>
        </InfoCard>
      </Grid>
      <Grid item md={6}>
        <InfoCard noPadding className={classes.cardBorder}>
          <AnnouncementsCard max={5} title="What's New in Muziris!" />
        </InfoCard>
      </Grid>
    </Grid >
  );
};


