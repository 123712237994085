import React from 'react';
import type { FieldValidation } from '@rjsf/utils';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';
import { useAsync } from 'react-use';
import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { makeFieldSchemaFromZod } from '@internal/plugin-scaffolder';
import { z } from 'zod';

let uiSchemaOptions: any;

type ApplicationPickerOptions = {
  listId: string;
  uniqueId: string;
  contentTypeId: string;
  appId: string;
  appName: string;
  alias: string;
  description: string;
  applicationStatus: string;
  primaryProduct: string;
  primaryProductProd: string;
  primaryProductProd0: string;
  primaryProductBusi: string;
  primaryProductOwne: string;
  businessCriticality: string;
  portfolio: string;
  otherRelatedProduc: Array<JSON>;
  otherRelatedProduc0: string;
  otherRelatedProduc1: string;
  otherRelatedProduc2: string;
  developedBy: string;
  hostedBy: string;
  hostingType: string;
  supportedBy: string;
  dataClassification: string;
  roadmapsConfluence: string;
  DRType: string;
  DRRemarksComments: string;
  InfosecKeyApp: string;
  DRTiering: string;
  createdDateTimeFriendlyDisplay: string;
  createdDateTime: string;
  createdDateFriendlyDisplay: string;
};

type ApplicationPickerOptionsKeys = Exclude<
  keyof ApplicationPickerOptions,
  'otherRelatedProduc'
>;

function compareStrings(a: string, b: string) {
  a = a.toLowerCase();
  b = b.toLowerCase();
  return a < b ? -1 : a > b ? 1 : 0;
}

function capitalizeField(a: string) {
  a = a.replace(/([A-Z])/g, ' $1').trim();
  return a.charAt(0).toUpperCase() + a.slice(1);
}

export const ApplicationPicker = ({
  onChange,
  schema,
  rawErrors,
  formData,
  required,
  uiSchema,
  idSchema,
}: any) => {
  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);

  const { value, loading, error } = useAsync(async (): Promise<any> => {
    const baseUrl = await discoveryApi.getBaseUrl('sharepoint');
    return await fetchApi
      .fetch(`${baseUrl}/v1/appids`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
      .then(res => res.json());
  });

  if (error)
    return (
      <Alert severity="error">Error could not fetch Application ID List.</Alert>
    );

  const appsList: ApplicationPickerOptions[] = [];
  // todo: Primary Prod is a json array mapped to lookupValue hence converted to string by reading the first array value
  try {
    value?.ListData?.Row?.map((data: any) => {
      appsList.push({
        listId: data.ID,
        uniqueId: data.UniqueId,
        contentTypeId: data.ContentTypeId,
        appId: data.Title,
        appName: data.Name1,
        alias: data.Alias,
        description: data.Description,
        applicationStatus: data.Application_x0020_Status,
        primaryProduct: data?.Primary_x0020_Product_x003a__x004,
        // product : primaryProductProd : data.Primary_x0020_Product_x003a__x00,
        primaryProductProd: data.Primary_x0020_Product_x003a__x00,
        // product_group : primaryProductProd0 : data.Primary_x0020_Product_x003a__x000
        primaryProductProd0: data.Primary_x0020_Product_x003a__x000,
        // platform : primaryProductBusi : data.Primary_x0020_Product_x003a__x001,
        primaryProductBusi: data.Primary_x0020_Product_x003a__x001,
        // owner email : primaryProductOwne : data.Primary_x0020_Product_x003a__x002,
        primaryProductOwne: data.Primary_x0020_Product_x003a__x002,
        businessCriticality: data.Business_x0020_Criticality_x0020,
        portfolio: data.Portfolio,
        otherRelatedProduc: data.Other_x0020_Related_x0020_Produc,
        otherRelatedProduc0: data.Other_x0020_Related_x0020_Produc0,
        otherRelatedProduc1: data.Other_x0020_Related_x0020_Produc1,
        otherRelatedProduc2: data.Other_x0020_Related_x0020_Produc2,
        developedBy: data.Developed_x0020_by,
        hostedBy: data.Hosted_x0020_by,
        hostingType: data.Hosting_x0020_type,
        supportedBy: data.Supported_x0020_by,
        dataClassification: data.Data_x0020_Classification,
        roadmapsConfluence: data.Roadmaps_x0020__x0028_Confluence,
        DRType: data.DR_x0020_Type,
        DRRemarksComments: data.DR_x0020_Remarks_x002f_Comments,
        InfosecKeyApp: data.Infosec_x0020_Key_x0020_App,
        DRTiering: data.DR_x0020_Tiering,
        createdDateTimeFriendlyDisplay: data.Created,
        createdDateTime: data['Created.'],
        createdDateFriendlyDisplay: data['Created.FriendlyDisplay'],
      });
    });
  } catch (err: any) {
    console.error(err);
    <Alert severity="error">Error could not fetch Application ID List.</Alert>;
  }

  const appIdSortList: ApplicationPickerOptions[] = [...appsList];
  const appNameSortList: ApplicationPickerOptions[] = [...appsList];

  const appIdProps = {
    options: appIdSortList.sort(
      (a: ApplicationPickerOptions, b: ApplicationPickerOptions) => {
        return compareStrings(a.appId, b.appId);
      },
    ),
    getOptionLabel: (option: ApplicationPickerOptions) => option.appId,
    getOptionSelected: (
      option: ApplicationPickerOptions,
      value: ApplicationPickerOptions,
    ) => option.appId === value.appId,
  };

  const appNameProps = {
    options: appNameSortList.sort(
      (a: ApplicationPickerOptions, b: ApplicationPickerOptions) => {
        return compareStrings(a.appName, b.appName);
      },
    ),
    groupBy: (option: ApplicationPickerOptions) =>
      /[A-Za-z]/.test(option.appName.charAt(0).toUpperCase())
        ? option.appName.charAt(0).toUpperCase()
        : '#',
    getOptionLabel: (option: ApplicationPickerOptions) => option.appName,
    getOptionSelected: (
      option: ApplicationPickerOptions,
      value: ApplicationPickerOptions,
    ) => option.appName === value.appName,
  };

  const data = formData
    ? formData.hasOwnProperty('appId')
      ? formData
      : null
    : null;

  const [selected, setSelected] =
    React.useState<ApplicationPickerOptions | null>(data);

  const onSelect = (_: any, value: ApplicationPickerOptions | null) => {
    onChange(value || null);
    if (mandateFields) {
      setVisibleMandateFields(
        mandateFields.filter(
          (field: ApplicationPickerOptionsKeys) => value && !value[field],
        ),
      );
    }
    setSelected(value);
  };

  const onChangeTextHandle = (
    field: ApplicationPickerOptionsKeys,
    value: string,
  ) => {
    if (selected) {
      let overrideSelected = selected;
      overrideSelected[field] = value;
      setSelected(overrideSelected);
      onChange(overrideSelected);
    }
  };

  // todo: throw error if fields not within keys
  let overrideFields: ApplicationPickerOptionsKeys[] = uiSchema['ui:options']
    ?.override
    ? (uiSchema['ui:options'].override as ApplicationPickerOptionsKeys[])
    : [];
  let showFields: ApplicationPickerOptionsKeys[] = uiSchema['ui:options']?.show
    ? (uiSchema['ui:options'].show as ApplicationPickerOptionsKeys[])
    : [];
  let mandateFields: ApplicationPickerOptionsKeys[] = uiSchema['ui:options']
    ?.mandate
    ? (uiSchema['ui:options'].mandate as ApplicationPickerOptionsKeys[])
    : [];
  let selectorFields: ApplicationPickerOptionsKeys[] = uiSchema['ui:options']
    ?.selector
    ? (uiSchema['ui:options'].selector as ApplicationPickerOptionsKeys[])
    : ['appId', 'appName'];

  if (
    !selectorFields.includes('appId') &&
    !selectorFields.includes('appName')
  ) {
    selectorFields = ['appId', 'appName'];
  }
  uiSchemaOptions = uiSchema['ui:options'];
  if (showFields && mandateFields) {
    const showMandateFields: ApplicationPickerOptionsKeys[] = showFields.filter(
      (field: ApplicationPickerOptionsKeys) => mandateFields.includes(field),
    );
    if (overrideFields) {
      overrideFields = overrideFields.concat(showMandateFields);
    } else {
      overrideFields = [...showMandateFields];
    }
  }

  if (overrideFields && showFields) {
    showFields = showFields.filter(
      (field: ApplicationPickerOptionsKeys) => !overrideFields.includes(field),
    );
  }

  if (overrideFields && mandateFields) {
    mandateFields = mandateFields.filter(
      (field: ApplicationPickerOptionsKeys) => !overrideFields.includes(field),
    );
  }

  const [visibleMandateFields, setVisibleMandateFields] = React.useState<
    ApplicationPickerOptionsKeys[]
  >(
    mandateFields
      ? mandateFields.filter(
          (field: ApplicationPickerOptionsKeys) => data && !data[field],
        )
      : [],
  );

  let widthAppName;
  let widthAppId;
  if (
    selectorFields &&
    selectorFields.includes('appId') &&
    selectorFields.includes('appName')
  ) {
    widthAppId = '35%';
    widthAppName = '65%';
  } else if (selectorFields && selectorFields.includes('appName')) {
    widthAppId = '0%';
    widthAppName = '100%';
  } else if (selectorFields && selectorFields.includes('appId')) {
    widthAppId = '100%';
    widthAppName = '0%';
  }

  // todo: fix required
  return (
    <>
      <FormControl
        margin="normal"
        style={{ flexDirection: 'row' }}
        required={required}
        error={rawErrors?.length > 0}
      >
        {selectorFields && selectorFields.includes('appName') && (
          <Autocomplete
            id={idSchema?.$id}
            onChange={onSelect}
            loading={loading}
            {...appNameProps}
            clearOnEscape
            value={selected ? selected : null}
            style={{ width: widthAppName }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label={schema.title ? schema.title : 'Application Name'}
                variant="standard"
                helperText={schema.description ? schema.description : ''}
              />
            )}
          />
        )}
        {selectorFields && selectorFields.includes('appId') && (
          <Autocomplete
            id={idSchema?.$id}
            onChange={onSelect}
            loading={loading}
            {...appIdProps}
            clearOnEscape
            value={selected ? selected : null}
            style={{
              width: widthAppId,
              paddingLeft: widthAppId == '100%' ? '0px' : '10px',
            }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label={'Application ID'}
                variant="standard"
              />
            )}
          />
        )}
        {schema.description && (
          <FormHelperText>{schema.description}</FormHelperText>
        )}
      </FormControl>

      {overrideFields &&
        overrideFields.length > 0 &&
        overrideFields.map(field => (
          <FormControl margin="normal" required key={field}>
            <TextField
              margin="normal"
              label={capitalizeField(field)}
              value={selected ? selected[field] : ''}
              required
              onChange={({ target: { value } }) =>
                onChangeTextHandle(field, value)
              }
            />
          </FormControl>
        ))}

      {visibleMandateFields &&
        visibleMandateFields.length > 0 &&
        visibleMandateFields.map(field => (
          <FormControl
            margin="normal"
            required
            key={field}
            error={rawErrors?.length > 0}
          >
            <TextField
              margin="normal"
              label={capitalizeField(field)}
              required
              onChange={({ target: { value } }) =>
                onChangeTextHandle(field, value)
              }
            />
          </FormControl>
        ))}

      {showFields &&
        showFields.length > 0 &&
        showFields.map(field => (
          <FormControl margin="normal" key={field}>
            <TextField
              margin="normal"
              label={capitalizeField(field)}
              disabled
              InputProps={{
                readOnly: true,
              }}
              value={selected ? selected[field] : ''}
            />
          </FormControl>
        ))}
    </>
  );
};

export const MALValidation = (value: string, validation: FieldValidation) => {
  if (
    uiSchemaOptions?.validate &&
    JSON.parse(JSON.stringify(value))?.appName == null
  ) {
    validation.addError(
      `Application ID is required, Please select a application ID.`,
    );
  }
};

export const ApplicationIDExtensionWithOptionsSchema = makeFieldSchemaFromZod(
  z.object({}),
  z.object({
    override: z
      .array(
        z.enum([
          'alias',
          'description',
          'applicationStatus',
          'primaryProduct',
          'primaryProductProd',
          'primaryProductProd0',
          'primaryProductBusi',
          'primaryProductOwne',
          'businessCriticality',
          'portfolio',
          'supportedBy',
          'dataClassification',
        ]),
      )
      .optional()
      .describe(
        'Allow user override Selected Field in the form. Defaults to []',
      ),
    show: z
      .array(
        z.enum([
          'alias',
          'description',
          'applicationStatus',
          'primaryProduct',
          'primaryProductProd',
          'primaryProductProd0',
          'primaryProductBusi',
          'primaryProductOwne',
          'businessCriticality',
          'portfolio',
          'supportedBy',
          'dataClassification',
        ]),
      )
      .optional()
      .describe('Allow user to show Field in the form. Defaults to []'),
    mandate: z
      .array(
        z.enum([
          'alias',
          'description',
          'applicationStatus',
          'primaryProduct',
          'primaryProductProd',
          'primaryProductProd0',
          'primaryProductBusi',
          'primaryProductOwne',
          'businessCriticality',
          'portfolio',
          'supportedBy',
          'dataClassification',
        ]),
      )
      .optional()
      .describe(
        'Allow user to mandate Selected Field in the form. Defaults to []',
      ),
    validate: z
      .boolean()
      .optional()
      .describe(
        'Whether to validate application id is passed as a parameter in the form. Defaults to false',
      ),
  }),
).schema;
