import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const serviceStatusPlugin = createPlugin({
  id: 'service-status',
  routes: {
    root: rootRouteRef,
  },
});

export const ServiceStatusPage = serviceStatusPlugin.provide(
  createRoutableExtension({
    name: 'ServiceStatusPage',
    component: () =>
      import('./components/ServiceStatusComponent').then(
        m => m.ServiceStatusComponent,
      ),
    mountPoint: rootRouteRef,
  }),
);
