import React from 'react';
import { RepositoryNamePicker } from '@internal/backstage-plugin-catalog-import-alpha';
import { FormHelperText } from '@material-ui/core';

export const GitHubRepositoryField = ({ onChange }: any) => {
  const handleGithubRepositoryChange = (newValue: any) => {
    onChange(newValue.github_repository_url)
  }

  return (
    <>
      <RepositoryNamePicker handleGithubRepositoryChange={handleGithubRepositoryChange} setBorderColor='rgba(0, 0, 0, 0.87)' setOnHoverColor='rgba(0, 0, 0, 0.87)' variant="standard" />
      <FormHelperText>Start typing for Live Search...</FormHelperText>
    </>
  )
};
