/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Grid, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import React from 'react';
import { BackButton } from '../Buttons';
import { EntityListComponent } from '../EntityListComponent';
import { PrepareResult } from '../useImportState';
import partition from 'lodash/partition';
import { useApi, configApiRef } from '@backstage/core-plugin-api';


type Props = {
  prepareResult: PrepareResult;
  onReset: () => void;
};

export const StepFinishImportLocation = ({ prepareResult, onReset }: Props) => {

  const [RepoNewLocation] = partition(
    prepareResult.locations,
    l => l.target,
  );

  let hrefForSeeInCatalog = window.location.origin + "/catalogue?filters%5Bkind%5D=component&filters%5Buser%5D=all";
  let customNavigationButton = "See In Product Catalog";
  if (RepoNewLocation.length == 1 && RepoNewLocation[0]?.entities.length <= 2) {
    RepoNewLocation[0]?.entities.map((eachEntity: any) => {
      switch (eachEntity?.kind) {
        case "Component":
          hrefForSeeInCatalog = window.location.origin + "/catalog/default/component/" + eachEntity?.name;
          break;
        case "Template":
          hrefForSeeInCatalog = window.location.origin + "/create/templates/default/" + eachEntity?.name;
          customNavigationButton = "Use the Software Template";
          break;
        case "Domain":
          hrefForSeeInCatalog = window.location.origin + "/explore/domains"
          customNavigationButton = "See In Product Domains";
          break;
        case "System":
          hrefForSeeInCatalog = window.location.origin + "/catalogue?filters%5Bkind%5D=system&filters%5Buser%5D=all"
          customNavigationButton = "See In Product Systems";
          break;
        case "Group":
          hrefForSeeInCatalog = window.location.origin + "/explore/groups"
          customNavigationButton = "See In Product Groups";
          break;
      }
    });
  }

  const continueButton = (
    <Grid container spacing={0}>
      <BackButton onClick={onReset}>Register another</BackButton>
      {(RepoNewLocation.length == 1 && RepoNewLocation[0]?.entities.length <= 2) ? (<BackButton href={hrefForSeeInCatalog} >{customNavigationButton}</BackButton>) : (<></>)}
    </Grid>
  );


  const configApi = useApi(configApiRef);
  const appTitle = configApi.getOptional('app.title') || 'Muziris';

  if (prepareResult.type === 'repository') {
    return (
      <>
        {/* <Typography paragraph>
           {appTitle}{' '}

           
          <Link
          to={prepareResult.pullRequest.url}
          target="_blank"
          rel="noreferrer"
          variant="body2"
          >
          {prepareResult.pullRequest.url} 
                    </Link>
        </Typography> */}

        <Typography paragraph>
          You have successfully onboarded to {appTitle as string}!
        </Typography>
        <>
          <Typography>
            Click to view the component in catalog:
          </Typography>
          {/* TODO: Convert the Links we get from EntityListComponent as button to view the catalog */}
          <EntityListComponent
            locations={RepoNewLocation}
            locationListItemIcon={() => <LocationOnIcon />}
            withLinks
          />
        </>
        {continueButton}
      </>
    );
  }

  const [existingLocations, newLocations] = partition(
    prepareResult.locations,
    l => l.exists,
  );

  return (
    <>
      {newLocations.length > 0 && (
        <>
          <Typography>
            The following Components have been added to the catalog:
          </Typography>
          <EntityListComponent
            locations={newLocations}
            locationListItemIcon={() => <LocationOnIcon />}
            withLinks
          />
        </>
      )}
      {existingLocations.length > 0 && (
        <>
          <Typography>
            A refresh was triggered for the following locations:
          </Typography>

          <EntityListComponent
            locations={existingLocations}
            locationListItemIcon={() => <LocationOnIcon />}
            withLinks
          />
        </>
      )}
      {continueButton}
    </>
  );
};
