import React from 'react';
import { TextField, FormControl, FormHelperText } from '@material-ui/core';
import { fetchSubnets } from '../utils/apiCalls';
import Autocomplete from '@mui/material/Autocomplete';
import { ListOfSubnet } from '../utils/types';


interface SubnetFieldProps {
    id: string;
    required: boolean;
    rawErrors: any;
    subnetLoader: boolean;
    userToken: string | undefined;
    selectedVnet: any;
    onChange: (value: any) => void;
    setSubnet: (value: any) => void;
    setSubnetLoader: (loading: boolean) => void;
    setSubnetList: (list: ListOfSubnet[]) => void;
    subnetList: ListOfSubnet[];
    subscriptionDetails: any;
    selectedResourceGroup: any;
}

export const SubnetField: React.FC<SubnetFieldProps> = ({
    id,
    required,
    rawErrors,
    subnetLoader,
    userToken,
    selectedVnet,
    onChange,
    setSubnet,
    setSubnetLoader,
    setSubnetList,
    subnetList,
    subscriptionDetails,
    selectedResourceGroup,
}) => {
    React.useEffect(() => {
        const fetchData = async () => {
            setSubnetLoader(true);
            const subnetResponse = await fetchSubnets(selectedVnet.vnet_id, userToken || '');
            setSubnetList(subnetResponse);
            setSubnetLoader(false);
        };

        if (selectedVnet) {
            fetchData();
        }
    }, [selectedVnet, userToken, setSubnetLoader, setSubnetList]);

    const subnetProps = {
        options: subnetList.sort((a: ListOfSubnet, b: ListOfSubnet) => a.subnet_name.localeCompare(b.subnet_name)),
        getOptionLabel: (option: ListOfSubnet) => option.subnet_name,
        getOptionSelected: (option: ListOfSubnet, value: ListOfSubnet) => option.subnet_name === value.subnet_name,
    };

    return (
        <FormControl margin="normal" required={required} error={rawErrors?.length > 0}>
            <Autocomplete
                id={id}
                onChange={(_, value) => {
                    onChange({ subscriptionDetails, selectedResourceGroup, selectedVnet, subnetDetails: value });
                    setSubnet(value);
                }}
                loading={subnetLoader}
                {...subnetProps}
                clearOnEscape
                renderInput={(params: any) => (
                    <TextField {...params} label="List of Subnets" variant="standard" required={required} />
                )}
            />
            <FormHelperText>Choose the required Subnet</FormHelperText>
        </FormControl>
    );
};