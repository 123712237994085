import goodMorning from "./locales/goodMorning.locales.json";
import goodAfternoon from "./locales/goodAfternoon.locales.json";
import goodEvening from "./locales/goodEvening.locales.json";
// Select a large random integer at startup, to prevent the greetings to change
// every time the user navigates.
const crypto = window.crypto;
let array = new Uint32Array(1);
crypto.getRandomValues(array);
const FileId = array[0];
const greetingRandomSeed = Math.floor(FileId * 1000000);
export function getTimeBasedGreeting(): { language: string; greeting: string } {
  const random = (arr: string[]) => arr[greetingRandomSeed % arr.length];
  const currentHour = new Date(Date.now()).getHours();
  if (currentHour >= 23) {
    return {
      language: "Seriously",
      greeting: "Get some rest",
    };
  }
  const timeOfDay = (hour: number): { [language: string]: string } => {
    if (hour < 12) return goodMorning;
    if (hour < 17) return goodAfternoon;
    return goodEvening;
  };
  const greetings = timeOfDay(currentHour);
  const greetingsKey = random(Object.keys(greetings));
  return {
    language: greetingsKey,
    greeting: greetings[greetingsKey],
  };
}
