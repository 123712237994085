import React, { useState } from 'react';
import type { FieldValidation } from '@rjsf/utils';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';
import { useAsync } from 'react-use';
import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';

export const ComponentHopper = ({
  onChange,
  schema,
  rawErrors,
  required,
  idSchema,
}: any) => {
  const [selected, setSelected] = useState();
  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);
  const { value, loading, error } = useAsync(async (): Promise<any> => {
    const baseUrl = await discoveryApi.getBaseUrl('catalog');
    return await fetchApi
      .fetch(`${baseUrl}/entities?filter=kind=Component`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
      .then(res => res.json());
  });
  if (error)
    return (
      <Alert severity="error">Error could not fetch Component Name List.</Alert>
    );
  const appsList: any[] = [];
  try {
    value?.map((data: any) => {
      const path = data.metadata.annotations['backstage.io/view-url']
        .replace('/tree/', '/blob/')
        .replace("/catalog-info.yaml", "")
        .replace("/catalog-info.yml", "")
        .replace('https://github.com/DigitalInnovation/', '')
        .split("/blob/")[1].replace(`${data.metadata.annotations['backstage.io/view-url'].replace(
          '/tree/',
          '/blob/',
        ).split("blob/")[1].split("/")[0]}`, "").replace(/^\/+/, '')
      appsList.push({
        catalogUrl: data.metadata.annotations['backstage.io/view-url'].replace(
          '/tree/',
          '/blob/',
        ),
        componentName: data.metadata.name,
        componentURL:
          `${window.location.protocol
          }//${window.location.host
          }/catalog/default/component/${data.metadata.name}`,

        repoName: data.metadata.annotations['backstage.io/view-url']
          .replace('https://github.com/DigitalInnovation/', '')
          .split('/')[0],

        Branch: data.metadata.annotations['backstage.io/view-url'].replace(
          '/tree/',
          '/blob/',
        ).split("blob/")[1].split("/")[0],
        TechDocsRef: data?.metadata?.annotations['backstage.io/techdocs-ref'],
        TechDocPath: path.length > 1 ? path : "NOPATH"
      })
    }

    );
  } catch (err: any) {

    <Alert severity="error">Error could not fetch Component Name List.</Alert>;
  }

  const onSelect = (_: any, values: any | null) => {
    onChange(values || null);
    setSelected(values);
  };

  return (
    <>
      <FormControl
        margin="normal"
        style={{ flexDirection: 'row' }}
        required={required}
        error={rawErrors?.length > 0}
      >
        <Autocomplete
          disablePortal
          id={idSchema?.$id}
          options={appsList}
          onChange={onSelect}
          loading={loading}
          style={{ width: '100%' }}
          value={selected ? selected : null}
          getOptionLabel={option => option.componentName.toString()}
          renderInput={params => (
            <TextField {...params} label={schema.title} variant="standard" />
          )}
        />
        {schema.description && (
          <FormHelperText>{schema.description}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export const ComponentHopperValidation = (
  value: string,
  validation: FieldValidation,
) => {
  if (!value.hasOwnProperty('catalogUrl')) {
    validation.addError(
      'Selecting Name is mandatory to fetch Repository Url & Product Catalog-info.ymls file',
    );
  }
};
