import React from 'react';
import type { FieldValidation } from '@rjsf/utils';
import { microsoftAuthApiRef, useApi } from '@backstage/core-plugin-api';
import Alert, { Color } from '@material-ui/lab/Alert';
import { makeFieldSchemaFromZod } from '@internal/plugin-scaffolder';
import { z } from 'zod';
import { findValueByKey } from '../../../../../utils';
/*
 This is the actual component that will get rendered in the form
*/

let UISchema: { [x: string]: { errorMessage: string } };
let isValid: boolean;

export const Azureaadgrpemail = ({ formContext, uiSchema }: any) => {
  UISchema = uiSchema;
  const [previousValue, setPreviousValue] = React.useState('');

  /** state to store microsoft token   */
  const [token, setToken] = React.useState<string>('');

  /** state to store email error status  */
  const [emailError, setEmailError] = React.useState<string | undefined>(
    undefined,
  );

  /** Get microsoft token from identityApiRef  */
  const microsoftAuthApi = useApi(microsoftAuthApiRef);

  /** Get Access token by microsoftAuthApiRef   */
  React.useEffect(() => {
    /** Async function to get access tokem from the user  */
    async function getAccessToken() {
      const response = await microsoftAuthApi.getAccessToken();
      setToken(response);
    }

    getAccessToken();
  }, []);

  /** Get Grp email from the formcontext using parameter entered under UI:Options  */
  const userEmail = findValueByKey(
    formContext.formData,
    uiSchema['ui:options']?.formField
      ?.toString()
      .substring(3, uiSchema['ui:options']?.formField?.toString().length - 2)
      .split('.')[
      uiSchema['ui:options']?.formField
        ?.toString()
        .substring(3, uiSchema['ui:options']?.formField?.toString().length - 2)
        .split('.').length - 1
    ] as string,
  );

  if (
    userEmail != undefined &&
    token != undefined &&
    userEmail != previousValue
  ) {
    setPreviousValue(userEmail);

    if (userEmail.length > 0) {
      getGrpEmailBySearchUsingUserEmailValue(token, userEmail).then(data => {
        setEmailError(data.value.length === 0 ? 'error' : 'success');
      });
    } else {
      setEmailError('error');
    }
  }

  const getSeverity = (error: string): Color => {
    switch (error) {
      case 'error':
        isValid = false;
        return 'error';
      case 'success':
        isValid = true;
        return 'success';
      default:
        isValid = false;
        return undefined as any;
    }
  };

  return emailError == undefined ? (
    <></>
  ) : (
    <Alert style={{ marginTop: '-2%' }} severity={getSeverity(emailError)}>
      {emailError === 'success'
        ? uiSchema['ui:options']?.SuccessMessage?.toString()
        : uiSchema['ui:options']?.errorMessage?.toString()}
    </Alert>
  );
};

/*
 This is a validation function that will run when the form is submitted.
  You will get the value from the `onChange` handler before as the value here to make sure that the types are aligned\
*/
export const validateKebabCaseValidation = (
  value: string,
  validation: FieldValidation,
) => {
  const kebabCase = /^[a-z0-9-]+(?:_[a-z0-9-]+)*$/g.test(value);

  if (kebabCase === false) {
    validation.addError(
      `Only use letters, numbers, hyphen ("-") and underscore ("_").`,
    );
  }
};

export const AzureaadgrpemailValidation = (
  _value: string,
  validation: FieldValidation,
) => {
  if (isValid === false) {
    validation.addError(UISchema['ui:options']?.errorMessage);
  }
};

async function getGrpEmailBySearchUsingUserEmailValue(
  token: string,
  userEmail: string,
) {
  const response = await fetch(
    `https://graph.microsoft.com/v1.0/groups?$filter=mail eq 	%27${userEmail}%27`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ConsistencyLevel: 'eventual',
      },
    },
  );
  return await response.json();
}

export const AzureaadgrpemailValidationSchema = makeFieldSchemaFromZod(
  z.string(),
  z.object({
    formField: z
      .string()
      .describe(
        'Should be passed in the format of ${{ parameter.key }} or direct value as string surrounded by double quotes.',
      ),
    errorMessage: z
      .string()
      .describe(
        'The default entity kind. Options of this kind will not be prefixed.',
      ),
    SuccessMessage: z
      .string()
      .describe(
        'The default entity kind. Options of this kind will not be prefixed.',
      ),
  }),
).schema;
