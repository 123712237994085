import {
  EntityConsumedApisCard,
  EntityProvidedApisCard,
} from '@backstage/plugin-api-docs';
import {
  EntitySwitch,
  EntityDependsOnResourcesCard,
  EntityDependsOnComponentsCard,
  EntityHasSubcomponentsCard,
} from '@backstage/plugin-catalog';
import { Grid } from '@material-ui/core';
import React from 'react';

export const IntegrationTabContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs={12} md={6}>
            <EntityProvidedApisCard />
          </Grid>

          <Grid item xs={12} md={6}>
            <EntityConsumedApisCard />
          </Grid>
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <Grid container spacing={3} alignItems="stretch">
      <Grid item xs={12} md={6}>
        <EntityDependsOnResourcesCard variant="gridItem" />
      </Grid>

      <Grid item md={6}>
        <EntityDependsOnComponentsCard variant="gridItem" />
      </Grid>

      <Grid item md={6}>
        <EntityHasSubcomponentsCard variant="gridItem" />
      </Grid>
    </Grid>
  </>
);
