
import { getCookie, isTokenValid, setCookie } from '../../../../../utils';

export async function fetchAzureSubscriptions(microsoft: any, setUserToken: (token: string) => void) {
    let token = getCookie('azureAccessToken') as string;
    if (!isTokenValid(token)) {
        token = await microsoft.getAccessToken([
            "https://management.azure.com/user_impersonation",
            "profile",
            "offline_access",
            "email",
            "openid",
        ]);
        setCookie('azureAccessToken', token, 60);
    }
    setUserToken(token);

    const response = await fetch('https://management.azure.com/subscriptions/?api-version=2020-01-01', {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return await response.json();
}

export async function fetchResourceGroupList(subscriptionId: string, userToken: string) {
    const response = await fetch(
        `https://management.azure.com/subscriptions/${subscriptionId}/resourcegroups?api-version=2021-04-01`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        },
    );
    const data = await response.json();
    return data.value.map((eachObj: any) => ({
        id: eachObj.id,
        resourceGroupName: eachObj.name,
        location: eachObj.location,
        tags: eachObj.tags,
    }));
}

export async function fetchVnets(rgId: string, userToken: string) {
    const response = await fetch(
        `https://management.azure.com${rgId}/providers/Microsoft.Network/virtualNetworks?api-version=2022-11-01`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        },
    );
    const data = await response.json();
    return data.value.map((eachObj: any) => ({
        vnet_id: eachObj.id,
        vnet_name: eachObj.name,
    }));
}

export async function fetchSubnets(vnetId: string, userToken: string) {
    const response = await fetch(
        `https://management.azure.com${vnetId}/subnets?api-version=2022-11-01`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        },
    );
    const data = await response.json();
    return data.value.map((eachObj: any) => ({
        subnet_id: eachObj.id,
        subnet_name: eachObj.name,
    }));
}